import { ToInformation } from '../../Models/la-Team-messages/LATeamMessagingDashboard';
import { MessageQueueDTO } from '../../Models/incident.modal';
import { IncidentMessageSendService } from './incident-message-send.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDeliveryChannelEnum, MessageGroupEnum } from 'src/app/Models/la-Team-messages/LATeamMessagingDashboard';
import { ToastrService } from 'ngx-toastr';
import { MemberProfile } from 'src/app/Models/Members/MemberProfile';
import { Member } from 'src/app/Models/Members/Member';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { AppStartService } from 'src/app/core/services/app-start.service';
import { MemberForMessage } from 'src/app/Models/Messages/MessageTemplateDTO';

@Component({
  selector: 'app-incident-message-send',
  templateUrl: './incident-message-send.component.html',
  styleUrls: ['./incident-message-send.component.css']
})
export class IncidentMessageSendComponent implements OnInit {

  isSubmitted= false;
  sendMessageForm : FormGroup;
  messagedata : MessageQueueDTO;

  messsageToGroup : MemberForMessage[] = [];
  messsageToIndividuals : MemberForMessage[] = [];
  messsageTofinal: MemberForMessage[] = [];
  indivualMemeberList: MemberForMessage[] = [];

  overlayData: { messageType : string, incidentId : string }
  currentlySignedInUser : any;
  currentlySignedInUserName : any;
  
  toGroupOptions: {name: string; id: string}[] = [
    {id : '0', name : 'All team members'},
    {id : '1', name : 'All incident team members'}
  ];

  incidentTeamMembers : MemberProfile[];
  allTeamMembers : Member[];

  isIncidentTeamLoaded = false;
  isOrgenizationTeamLoaded = false;

  constructor(
    private _dialogRef: MatDialogRef<IncidentMessageSendComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fb : FormBuilder,
    private _incidentMessageSendService : IncidentMessageSendService,
    private _toastr : ToastrService,
    private _apiErrorService: ApiErrorService,
    private _appStartService: AppStartService
    ) { }

  ngOnInit(): void {
    this.overlayData = this._data.messageData;

      this._incidentMessageSendService.GetIncidentTeam(this.overlayData.incidentId)
      .subscribe(response => {
        this.incidentTeamMembers = response.data;
        this.isIncidentTeamLoaded = true;
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      });
  
      this._incidentMessageSendService.GetAllTeamMembers()
      .subscribe(response => {
        console.log(response);
        this.allTeamMembers = response.data;
        for (const teamMember of this.allTeamMembers) {
          this.indivualMemeberList.push(this._incidentMessageSendService.CreateMemberForMessageInstance(
            `${teamMember.firstName} ${teamMember.familyName}`, teamMember.id, teamMember.avatar, teamMember.jobTitle
          ))
        }
        this.isOrgenizationTeamLoaded = true;
      }, 
      (error) => {
        this._apiErrorService.ShowError(error);
      });

    this.InitializeForm();

    // this.sendMessageForm.controls["subSelector"].setValue('0');
    // this.sendMessageForm.controls["primeSelector"].setValue('0');

    if (this.overlayData.messageType == "SMS"){
      this.sendMessageForm.controls.subject.clearValidators();
      this.sendMessageForm.controls.subject.updateValueAndValidity();
    }

    this.currentlySignedInUser = this._appStartService.currentCognitoUser;
    this.currentlySignedInUserName = `${this._appStartService.currentCognitoUser.attributes.given_name} ${this._appStartService.currentCognitoUser.attributes.family_name}`;

    this.sendMessageForm.controls["toGroup"].valueChanges.subscribe(x => {
        if (x == '0'){
          this.sendMessageForm.controls["toGroup"].setValue('');

          for (const incidentTeamMeber of this.allTeamMembers) {
            if (this.messsageToGroup.findIndex(x => x.memberId == incidentTeamMeber.id) == -1){
              this.messsageToGroup.push(
                this._incidentMessageSendService.CreateMemberForMessageInstance(
                  `${incidentTeamMeber.firstName} ${incidentTeamMeber.familyName}`, incidentTeamMeber.id, incidentTeamMeber.avatar, incidentTeamMeber.jobTitle
              ));

              this.indivualMemeberList = this.indivualMemeberList.filter((data) => {
                return data.memberId !== incidentTeamMeber.id;
              });
            }
          }

        }
        else if (x == '1'){
          this.sendMessageForm.controls["toGroup"].setValue('');

          for (const incidentTeamMeber of this.incidentTeamMembers) {
            if (this.messsageToGroup.findIndex(x => x.memberId == incidentTeamMeber.id) == -1){
              this.messsageToGroup.push(
                this._incidentMessageSendService.CreateMemberForMessageInstance(
                  `${incidentTeamMeber.firstName} ${incidentTeamMeber.familyName}`, incidentTeamMeber.id, incidentTeamMeber.avatar, incidentTeamMeber.jobTitle
              ));

              this.indivualMemeberList = this.indivualMemeberList.filter((data) => {
                return data.memberId !== incidentTeamMeber.id;
              });
            }
          }

        }
      
    });

    
  }

  InitializeForm(){
    this.sendMessageForm = this._fb.group({
      'subject' : [null, [Validators.required]],
      'messageContent' : [null, [Validators.required]],
      'toGroup' : [null],
    });
  }


  RemoveAllFromToGroupListClicked(){
    this.messsageToGroup = [];
    this.InitializeIndivualMemeberList();
  }

  IsAvailableInIndiavidualListOrMesssageToFromIndividuals(member: MemberForMessage){
    return this.messsageToIndividuals.findIndex(x => x.memberId === member.memberId) === -1
      && this.indivualMemeberList.findIndex(x => x.memberId === member.memberId) == -1
  }


  RemoveFromToGroupListClicked(obj: MemberForMessage){
    this.messsageToGroup = this.messsageToGroup.filter(x => !(x.memberId === obj.memberId));
    // this.messsageToIndividuals = this.messsageToIndividuals.filter(x => !(x.memberId === obj.memberId));
    if (this.IsAvailableInIndiavidualListOrMesssageToFromIndividuals(obj)){
      this.indivualMemeberList.push(obj);
    }
  }

  RemoveAllFromToIndividualListClicked(){
    this.messsageToIndividuals = [];
    this.InitializeIndivualMemeberList();
  }


  RemoveFromToIndividualListClicked(obj: MemberForMessage){
    this.messsageToIndividuals = this.messsageToIndividuals.filter(x => !(x.memberId === obj.memberId));
    this.indivualMemeberList.push(obj);
  }

  InitializeIndivualMemeberList(){
    for (const teamMember of this.allTeamMembers) {
      if (this.IsAvailableInIndiavidualListOrMesssageToFromIndividuals(this._incidentMessageSendService.CreateMemberForMessageInstance(
        `${teamMember.firstName} ${teamMember.familyName}`, teamMember.id, teamMember.avatar, teamMember.jobTitle
        ))){
        this.indivualMemeberList.push(this._incidentMessageSendService.CreateMemberForMessageInstance(
          `${teamMember.firstName} ${teamMember.familyName}`, teamMember.id, teamMember.avatar, teamMember.jobTitle
        ));
      }
    }
  }

  IndividualMemeberSelected(memeber: MemberForMessage){
    if (!memeber){
      return;
    }

    const member = this.indivualMemeberList.find(data => data.memberId == memeber.memberId);
    if (!member){
      return;
    }

    if (this.messsageToIndividuals.findIndex(x => x.memberId == member.memberId) == -1){
      this.messsageToIndividuals.push(member);

      this.indivualMemeberList = this.indivualMemeberList.filter((data) => {
        return data.memberId !== memeber.memberId;
      });
    }
  }

  onSubmit(){
    this.isSubmitted = true;
    if (this.sendMessageForm.invalid){
      return;
    }
    
    if ((this.messsageToGroup.length + this.messsageToIndividuals.length) <= 0){
      return;
    }

    if (this.messsageToGroup.length > 0){
      for (const member of this.messsageToGroup) {
        if (this.messsageTofinal.findIndex(x => x.memberId === member.memberId) == -1){
          this.messsageTofinal.push(member);
        }
      } 
    }

    if (this.messsageToIndividuals.length > 0){
      for (const member of this.messsageToIndividuals) {
        if (this.messsageTofinal.findIndex(x => x.memberId === member.memberId) == -1){
          this.messsageTofinal.push(member);
        }
      }
    }

    if (this.messsageTofinal.length  <= 0){
      return;
    }

    const sendMessageSpinner = document.getElementById('send-message-spinner');
    if (sendMessageSpinner){
      sendMessageSpinner.style.display = 'block'
    }
    const btnSend = document.getElementById('btn-send');
    if (btnSend){
      btnSend.style.display = 'none'
    }

    this.messagedata = new MessageQueueDTO();
    // this.messagedata.to = this.messsageTo;
    
    // this.messagedata.to = ['3fa85f64-5717-4562-b3fc-2c963f66afa6'];
    
    this.messagedata.to = this.messsageTofinal.map(x => x.memberId);
    this.messagedata.message = this.sendMessageForm?.controls?.messageContent.value;
    this.messagedata.subject = this.sendMessageForm?.controls?.subject.value;
    if(this.overlayData.messageType == 'Mail'){
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.Email
    }
    else if (this.overlayData.messageType == 'SMS'){
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.SMS
    }
    else{
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.None
    }
    this.messagedata.from = this.currentlySignedInUser.username;
    this.messagedata.messageGroup = MessageGroupEnum.Team;
    this.messagedata.relatedIncidentId = this.overlayData.incidentId;

    // this.messagedata.groupName = 'asd';
    // this.messagedata.logoUrl = 'asdad';
    // this.messagedata.toDeliveryAddresses = ['asd', 'xcv'];
    // this.messagedata.activeDateTime = new Date();
    // this.messagedata.relatedIncidentId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";

      this._incidentMessageSendService.getMessageQueueLogs(this.messagedata, 1)
      .subscribe((response) => {

        const sendMessageSpinner = document.getElementById('send-message-spinner');
        if (sendMessageSpinner){
          sendMessageSpinner.style.display = 'none'
        }
        const btnSend = document.getElementById('btn-send');
        if (btnSend){
          btnSend.style.display = 'block'
        }

        if (response.data == true){

          let toInfo : ToInformation[] = [];
          for (const messageTo of this.messsageTofinal) {
            const to = new ToInformation();
            to.avatar = messageTo.avatar;
            if (this.overlayData.messageType === "SMS"){
              to.to = this.allTeamMembers.find(x => x.id == messageTo.memberId)?.phoneNo
            }
            else{
              to.to = this.allTeamMembers.find(x => x.id == messageTo.memberId)?.email
            }
            toInfo.push(to);
          }

          const fromInfo = this.currentlySignedInUserName;

         // = this.messsageTo.map(x => { to: x.memberId; avatar: x.avatar});
          const result = {...this.messagedata, toInfo:toInfo, fromInfo: fromInfo }
          this._dialogRef.close(result);
        }
        else{
          this._toastr.error("Something went wrong!");
          console.log(response);
        }
      }, 
      (error) => {
        const sendMessageSpinner = document.getElementById('send-message-spinner');
        if (sendMessageSpinner){
          sendMessageSpinner.style.display = 'none'
        }
        const btnSend = document.getElementById('btn-send');
        if (btnSend){
          btnSend.style.display = 'block'
        }

        this._apiErrorService.ShowError(error);
      });
    
  }

}
