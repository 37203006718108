import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { ProtocolTemplateDTO } from 'src/app/Models/cst-template/ProtocolTemplateDTO';
import { SaveProtocolLaunchDTO } from 'src/app/Models/LAIncedenceDashboard/LAIncidentDashboardModel';

@Injectable({
  providedIn: 'root'
})
export class CstProtocolLaunchService {

  constructor(
    private _apiService: ApiService
  ) { }

  GetCSTTemplates(){
    const url = `/LA/CSTProtocol/Templates`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetCSTTemplatesActions(incidentId: string, templateId :string){
    const url = `/la/CSTProtocolLaunch/${incidentId}/${templateId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  SaveCSTProtocolLaunchAssignments(saveProtocolLaunchDTO: SaveProtocolLaunchDTO){
    const url = `/la/CSTProtocolLaunch/save`;
    return this._apiService.post(url, saveProtocolLaunchDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetTimeFram(index: number): string {
    let result = "";
    switch (index) {
      case 1:
        result = "24 hours";
        break;
      case 2:
        result = "1 week";
        break;
      case 3:
        result = "1 month";
        break;
      case 4:
        result = "On Going";
        break;
      default:
        result = "";
    }
    return result;
  }

  GetEditTemplate(templateId){
    const url = `/La/CSTProtocol/Templates/Edit/${templateId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  EditTemplateTemplate(templateId: string, protocolTemplateDTO: ProtocolTemplateDTO){
    const url = `/La/CSTProtocol/Templates/Edit/${templateId}`;
    return this._apiService.put(url, protocolTemplateDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }

  CreateNewTemplate(protocolTemplateDTO: ProtocolTemplateDTO){
    const url = `/La/CSTProtocol/Templates/New`;
    return this._apiService.post(url, protocolTemplateDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetNewTemplate(){
    const url = `/LA/CSTProtocol/Templates/New`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetTeamMembers(){
    const url = "/La/TeamMembers";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
