import { ToastrService } from 'ngx-toastr';
import { MemberType } from '../../Enum/MemberType.enum';
import { TeamMember } from '../../Models/Team-member/TeamMemberDTO';
import { AddTeamMemberService } from './add-team-member.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRole } from 'src/app/Enum/UserRole.enum';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';

@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrls: ['./add-team-member.component.css']
})
export class AddTeamMemberComponent implements OnInit {

  teamMemberForm: FormGroup;
  isSubmitted: boolean;
  isEdit: boolean = false;
  //options = Object.keys(UserRole).filter(f => {isNaN(Number(f)) && (UserRole.SuperAdmin || UserRole.SuperAdmin)});

  constructor(
    private _dialogRef: MatDialogRef<AddTeamMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: FormBuilder,
    private _addTeamMemberService: AddTeamMemberService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _userProfileService: UserProfileService
  ) { }

  ngOnInit(): void {
    // console.log(this._data)
    // this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    if (this._data.memberProfile == null){
      this.isEdit = false;
    }
    else{
      this.isEdit = true;
    }

    this.InitForm();

    if (this.isEdit){
      let isIncluded = false;
      if (this._data.memberProfile.role){
        isIncluded = [UserRole[UserRole.GroupAdmin], UserRole[UserRole.GroupMember], UserRole[UserRole.GroupCommunicator]].includes(this._data.memberProfile.role);
      }
      
      this.teamMemberForm.patchValue({
        firstName: this._data.memberProfile.firstName,
        givenName: this._data.memberProfile.givenName,
        role: isIncluded ? this._data.memberProfile.role : null,
        employer: this._data.memberProfile.employer,
        jobTitle: this._data.memberProfile.jobTitle,
        phone: this._data.memberProfile.phone,
        email: this._data.memberProfile.email,
        policeIIR: this._data.memberProfile.incidentAlert,
        location: this._data.memberProfile.locationAlert,
        partnerMessage: this._data.memberProfile.partnerMessage,
        section60: this._data.memberProfile.section60Alert,
        section35: this._data.memberProfile.section35Alert,
        protocolLaunch: this._data.memberProfile.incidentLaunchIfNotTeam,
        dashboardContact: this._data.memberProfile.dashboardContact,
      });
    }
  }

  InitForm() {
    this.teamMemberForm = this._fb.group({
      firstName: [null, Validators.required],
      givenName: [null, Validators.required],
      role: [null, Validators.required],
      employer: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern(/^\+[1-9]\d{1,14}$/)]],
      email: [null, [Validators.required, Validators.email]],
      policeIIR: [null],
      location: [null],
      partnerMessage: [null],
      section60: [null],
      section35: [null],
      dashboardContact: [null],
      protocolLaunch: [null],
      jobTitle: [null],
    });
  }

  //#region form-getters

  public get firstName(){
    return this.teamMemberForm.controls.firstName as FormControl;
  }

  public get givenName(){
    return this.teamMemberForm.controls.givenName as FormControl;
  }

  public get role(){
    return this.teamMemberForm.controls.role as FormControl;
  }

  public get employer(){
    return this.teamMemberForm.controls.employer as FormControl;
  }

  public get phone(){
    return this.teamMemberForm.controls.phone as FormControl;
  }

  public get email(){
    return this.teamMemberForm.controls.email as FormControl;
  }

  public get policeIIR(){
    return this.teamMemberForm.controls.policeIIR as FormControl;
  }

  public get location(){
    return this.teamMemberForm.controls.location as FormControl;
  }

  public get partnerMessage(){
    return this.teamMemberForm.controls.partnerMessage as FormControl;
  }

  public get section60(){
    return this.teamMemberForm.controls.section60 as FormControl;
  }
  
  public get section35(){
    return this.teamMemberForm.controls.section35 as FormControl;
  }

  public get dashboardContact(){
    return this.teamMemberForm.controls.dashboardContact as FormControl;
  }

  public get protocolLaunch(){
    return this.teamMemberForm.controls.protocolLaunch as FormControl;
  }

  public get jobTitle(){
    return this.teamMemberForm.controls.jobTitle as FormControl;
  }

  //#endregion

  onSubmit(){
    this.isSubmitted = true;
    if (this.teamMemberForm.invalid){
      return;
    }

    let teamMember = new TeamMember();
    teamMember.familyName = this.givenName.value;
    teamMember.firstName = this.firstName.value;
    teamMember.email = this.email.value;
    teamMember.phoneNo = this.phone.value;
    teamMember.employer = this.employer.value;
    teamMember.memberType = MemberType.Member;
    teamMember.roles = +UserRole[this.role.value];
    teamMember.incidentAlert = this.policeIIR.value == true ? true : false;
    teamMember.locationAlert = this.location.value == true ? true : false;
    teamMember.partnerMessage = this.partnerMessage.value == true ? true : false;
    teamMember.section60Alert = this.section60.value == true ? true : false;
    teamMember.section35Alert = this.section35.value == true ? true : false;
    teamMember.dashboardContact = this.dashboardContact.value == true ? true : false;
    teamMember.jobTitle = this.jobTitle.value;
    teamMember.incidentLaunchIfNotTeam = this.protocolLaunch.value == true ? true : false;
    teamMember.avatar = this._data?.memberProfile?.avatar;
    //teamMember.groups = 4;
    teamMember.MemberCategoryId = 26;

    teamMember.groups = this._userProfileService.GetAuthenticatedUserGroup()?.organizationGroupId;

    if (this.isEdit){
      teamMember.id = this._data.memberProfile.id;
    }
    teamMember.sendJoiningEmail = true;
    //console.log(JSON.stringify(teamMember));
    if (this.isEdit){
      this._addTeamMemberService.EditTeamMember(teamMember).subscribe(response => {
        //console.log(response);
    
        this._toastr.success("Member is Updated Successfully");
        this._addTeamMemberService.teamMemberSubject.next([teamMember, true]);
        this._dialogRef.close();
      }, 
      (error) => {
        this._apiErrorService.ShowError(error);
      });
    }
    else{
      this._addTeamMemberService.AddTeamMember(teamMember).subscribe(response => {
        //console.log(response);
        
        this._toastr.success("Member is added Successfully");
        this._addTeamMemberService.teamMemberSubject.next([teamMember, false]);
        this._dialogRef.close();
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      });
    }

  }

}