export class PostProtocolActionsModel {
  id: string;
  location: string;
  title: string;
  tasks: PostProtocolActionsTaskModel[] = [];
}

export class PostProtocolActionsTaskModel{
  actionName: string;
  avatar: string;
  date : Date;
  id: number;
  issue: boolean;
  incidentMemberTaskId: number;
  incidentTaskId: number;
  personName: string;
  status: number;
  checked: boolean; 
  category: string;
  timeFrame: number;
  hasNotes: boolean
}
