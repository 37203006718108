import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoadScriptService } from '../load-script.service';
import { CstTemplateService } from 'src/app/cst-template/cst-template/cst-template.service';
import { Template, TemplateWithTasks } from 'src/app/Models/Templates/Template';
import { ApiErrorService } from 'src/app/core/services/api-error.service';

@Component({
  selector: 'app-finalise-protocol-actions-post-protocol-launch',
  templateUrl: './finalise-protocol-actions-post-protocol-launch.component.html',
  styleUrls: ['./finalise-protocol-actions-post-protocol-launch.component.css']
})
export class FinaliseProtocolActionsPostProtocolLaunchComponent implements OnInit, OnDestroy {

  templates: Template[] = [];
  templateWithTaskDetaiil: TemplateWithTasks ;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _loadScriptService: LoadScriptService,
    private _cstTemplateService: CstTemplateService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService
    ) { }
  
    selectedTemplate: string;
    selectedCategory: string;
    selectedPerson: string;
    selectedTime: string;

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    this._loadScriptService.LoadDropdownScript('finalise-protocol-actionsScriptTag');
    this.selectedCategory = "All";
    this.selectedPerson = "All";
    this.selectedTime = "All";

    this._cstTemplateService.GetTemplates().subscribe(response => {
      this.templates = response.data;
    },
    (error) => {
      this._apiErrorService.ShowError(error);
    });
  }


  TemplatSelectedClicked(template: Template){
    this.selectedTemplate = template.templateTitle;
    document.getElementById("ddlTemplate")
      .classList.remove("shown");

    this._cstTemplateService.GetTemplateDetail(template.id).subscribe(response => {
      this.templateWithTaskDetaiil = response.data;
    }, 
    (error) => {
      this._apiErrorService.ShowError(error);
    });

  }

  CategorySelectedClicked(category: string){
    this.selectedCategory = category;
    document.getElementById("ddlCategory")
      .classList.remove("shown");
  }

  PersonSelectedClicked(person: string){
    this.selectedPerson = person;
    document.getElementById("ddlPerson")
      .classList.remove("shown");
  }

  TimeSelectedClicked(time: string){
    this.selectedTime = time;
    document.getElementById("ddlTime")
      .classList.remove("shown");
  }

  ngOnDestroy(): void {
    this._loadScriptService.RemoveScript('finalise-protocol-actionsScriptTag');
  }

}
