<app-home-nav-menu></app-home-nav-menu>
<!-- <app-confirmation-modal 
  [isOpen]="isConfirmationPopupOpen" 
  [message]="confirmationMessage" 
  (onConfirm)="handleConfirm()" 
  (onCancel)="handleCancel()">
</app-confirmation-modal> -->
<div class="team-protocol-section pt-15 local-auth-3">
  <div class="container cst-protocol Team_protocol_action">
    <h2 class="sec-title">CST protocol actions</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>
    <div class="w-100" *ngIf="!isDataLoading && !isTeamMemebrLoading">
      <div class="accordion tableaccordian" id="contactaccordian">
        <div class="teamprotocol-heading">
          <div class="t_h2 main-dropdown-js">
            <h1>
              <span class="rel each-dropdown-js"
                rel="t_dropdownIncedentStatuses">
                {{selectedincedenceStatus === 'All' ? ' All status’s' : (' ' +
                selectedincedenceStatus)}}
              </span>
              actions
              <span class="rel each-dropdown-js" rel="t_dropdownIssues">
                {{selectedIssue === 'All' ? ' all' : (' ' +
                selectedIssue | lowercase)}}
              </span>
              issues for
              <span class="rel each-dropdown-js" rel="t_dropdownTeamMembers">
                {{selectedTeamMember === 'All' ? ' all team members' : (' ' +
                selectedTeamMember)}}
              </span>
              and within
              <span class="rel each-dropdown-js" rel="t_dropdownTimeFrames">
                {{selectedTimeFrame === 'All time frames' ? ' all times frames' : (' ' +
                selectedTimeFrame)}}
              </span>
              for
              <span class="rel each-dropdown-js" rel="t_dropdownCategory">
                {{selectedCategory === 'All categories' ? ' all categories' : (' ' +
                selectedCategory | lowercase)}}
              </span>
              for
              <span class="rel each-dropdown-js" rel="t_dropdownIncedent">
                {{selectedincedence === 'All' ? ' all incidents' : (' ' +
                selectedincedence)}}
              </span>

              <!-- CST protocol actions for
              <span class="rel each-dropdown-js" rel="t_dropdownTeamMembers">
                {{selectedTeamMember === 'All' ? ' all team members' : (' ' +
                selectedTeamMember)}}
              </span>
              within
              <span class="rel each-dropdown-js" rel="t_dropdownTimeFrames">
                {{selectedTimeFrame === 'All' ? ' all time frame' : (' ' +
                selectedTimeFrame)}}
              </span>
              which are
              <span class="rel each-dropdown-js"
                rel="t_dropdownIncedentStatuses">
                {{selectedincedenceStatus === 'All' ? ' all status’s' : (' ' +
                selectedincedenceStatus)}}
              </span>
              in the
              <span class="rel each-dropdown-js" rel="t_dropdownCategory">
                {{selectedCategory === 'All' ? ' all Category' : (' ' +
                selectedCategory)}}
              </span>
              for
              <span class="rel each-dropdown-js" rel="t_dropdownIncedent">
                {{selectedincedence === 'All' ? ' all incidents' : (' ' +
                selectedincedence)}}
              </span> -->
            </h1>

            <ul class="t-dropdown each-dropdown-content-js"
              id="t_dropdownTeamMembers">
              <li *ngFor="let teamMember of teamMembers">
                <button type="button" class="link"
                  (click)="teamMemberClicked(teamMember)">{{teamMember}}</button>
              </li>
            </ul>

            <ul class="t-dropdown each-dropdown-content-js"
              id="t_dropdownIssues">
              <li *ngFor="let issue of issues">
                <button type="button" class="link"
                  (click)="IssuesClicked(issue)">{{issue}}</button>
              </li>
            </ul>

            <ul class="t-dropdown each-dropdown-content-js"
              id="t_dropdownTimeFrames">
              <li *ngFor="let timeFrame of timeFrames">
                <button type="button" class="link"
                  (click)="timeFrameClicked(timeFrame)">{{timeFrame}}</button>
              </li>
            </ul>
            
            <ul class="t-dropdown each-dropdown-content-js"
              id="t_dropdownIncedentStatuses">
              <li *ngFor="let incedenceStatus of incedenceStatuses">
                <button type="button" class="link"
                  (click)="incedenceStatusClicked(incedenceStatus)">{{incedenceStatus}}</button></li>
            </ul>


            <ul class="t-dropdown each-dropdown-content-js"
              id="t_dropdownCategory">
              <li *ngFor="let category of categories">
                <button type="button" class="link"
                  (click)="categoryClicked(category)">{{category}}</button>
              </li>
            </ul>

            <ul class="t-dropdown each-dropdown-content-js text-nowrap"
              id="t_dropdownIncedent">
              <li *ngFor="let incedent of incedences">
                <button type="button" class="link"
                  (click)="incedenceClicked(incedent)">{{incedent}}</button></li>
            </ul>
          </div>
        </div>

        
        <div class="accordion-item" *ngFor="let incidentItem of teamActions;
          trackBy: trackByFn; index as i">
          <h2 class="accordion-header m-0" [attr.id]="'contactheading' + i">
            <button class="accordion-button bg-gradient-lightblue-tb" id="fff"
              type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#contactaccord'+i" [attr.aria-expanded]="i
              === 0 ? 'true' : 'false'"
              [attr.aria-controls]="'contactaccord'+i">
              {{incidentItem.title}} <span>{{incidentItem.location}}</span>
            </button>
          </h2>
          <div [attr.id]="'contactaccord' + i" class="accordion-collapse
            collapse"
            [class]="i === 0 ? 'show' : ''"
            [attr.aria-labelledby]="'contactheading'+ i"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Person</th>
                      <th>Action</th>
                      <th>Category</th>
                      <th>Timeframe</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Issue</th>
                      <th>Status</th>
                      <th><input [id]="'taskCheckbox' + i" class="table-checkbox
                          border-check
                          form-check-input" [class]="'chkSame' + i"
                          type="checkbox" value=""
                          (change)="allCheckChanged($event, i,
                          teamActions[i].tasks)">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let task of incidentItem.tasks; index as ii">
                      <td class="text-wrap">
                        <div class="dropdown">

                          <div class="d-flex align-items-center justify-content-center" >
                            <mat-spinner [id]="i + 'update-assigned-member-spinner' + ii" style="display: none;" diameter="20"></mat-spinner>
                          </div>

                          <div [id]="i + 'assigned-member-div' + ii" style="display: block;">
                            <div  type="button" id="incidentdropdown" (click)="personClicked(task, i, ii)"> 
                              <img [src]="(task.avatar != null && task.avatar != undefined) ? task.avatar : 'assets/images/profile-blank-icon.png'"
                                alt="user-profile" class="user-profile">
                              <span>{{task.personName}}</span>
                            </div>

                            <!-- <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                              <li class="showHand" (click)="AssignTeamMember(teamMember, task, i, ii)"
                              *ngFor="let teamMember of teamMembersToAssign">
                                <span>
                                  {{teamMember.firstName + ' ' + teamMember.familyName}}
                                </span>
                              </li>
                            </ul> -->
                          </div>
                        </div>
                      </td>
                      <td class="text-wrap">{{task.actionName}}</td>
                      <td class="text-wrap text-left">{{task.category}}</td>
                      <td class="text-wrap">
                        <span 
                          class="status-btn timeline text-nowrap">{{GetTimeFrame(task.timeFrame)}}</span></td>
                      <td class="text-nowrap">{{task.date | date:'MMM d'}}</td>
                      <td class="msg-icon">
                          <img *ngIf="task.hasNotes" (click)="NotesClicked(task, incidentItem, i, ii)" src="../assets/images/Local_Authority_message_unread.png"
                          alt="">
                          <img *ngIf="!task.hasNotes" (click)="NotesClicked(task, incidentItem, i, ii)" src="../assets/images/Local_Authority_message_grey_dot.png"
                          alt="">
                      </td>
                      <td (click)="IssueClicked(task, incidentItem, i, ii)">
                        <span [ngClass]="task.issue === false ? 'border-circle'
                          : 'red-circle'"></span>
                      </td>
                      <td [id]="i + 'status-td' + ii" class="p-0" 
                        [style.background-color]="GetBackgroundColorForTd(task.status)" 
                        [style.border]="'1px solid #fff'">
                        <div class="dropdown local-action-btn_no_arrow action-final
                          r-btn">

                          <div class="d-flex align-items-center justify-content-center" >
                            <mat-spinner [id]="i + 'update-status-spinner' + ii" style="display: none;" diameter="20"></mat-spinner>
                          </div>

                          <button style="display: block;" [id]="i + 'btnTaskStatus' + ii" type="button"
                            [ngClass]="getStatusData(task.status).classname"
                            class="table-btn" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{getStatusData(task.status).statusName}}</button>
                          <ul class="dropdown-menu"
                            [attr.aria-labelledby]="i + 'btnTaskStatus' + ii">

                            <!-- <li><button type="button" class="table-btn
                              ddmdarkGreen"
                              (click)="IndividualTaskStatusClicked('Done', i,
                              ii, incidentItem, task)">Done</button></li>
                          <li><button type="button" class="table-btn
                              ddmGreen"
                              (click)="IndividualTaskStatusClicked('Ongoing',
                              i, ii, incidentItem, task)">Ongoing</button></li>
                          <li><button type="button" class="table-btn
                              ddmorange"
                              (click)="IndividualTaskStatusClicked('Accepted',
                              i, ii, incidentItem, task)">Accepted</button></li>
                          <li><button type="button" class="table-btn
                              ddmGrey"
                              (click)="IndividualTaskStatusClicked('Pending',
                              i, ii, incidentItem, task)">Pending</button></li> -->
                             
                            <li><button type="button" class="table-btn
                                ddmdarkGreen"
                                (click)="IndividualTaskStatusClicked('Complete', i,
                                ii, incidentItem, task)">Complete</button></li>
                            <li><button type="button" class="table-btn
                                ddmGreen"
                                (click)="IndividualTaskStatusClicked('Ongoing',
                                i, ii, incidentItem, task)">Ongoing</button></li>
                            <li><button type="button" class="table-btn
                                ddmorange"
                                (click)="IndividualTaskStatusClicked('Accepted',
                                i, ii, incidentItem, task)">Accepted</button></li>
                            <li><button type="button" class="table-btn
                              ddmLightGrey"
                                (click)="IndividualTaskStatusClicked('Not Required',
                                i, ii, incidentItem, task)">Not Required</button></li>
                            <li><button type="button" class="table-btn
                              ddmPink"
                              (click)="IndividualTaskStatusClicked('Declined',
                              i, ii, incidentItem, task)">Declined</button></li>
                          </ul>
                        </div>
                      </td>
                      <td>
                        <input class="table-checkbox border-check
                          form-check-input" [class]="'taskCheckbox' + i"
                          type="checkbox" (change)="checkboxChanged($event,
                          task)">
                      </td>
                    </tr>

                    <tr class="last-active">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>

                        <div class="dropdown local-action-btn action-final
                          r-btn">
                          <button class=" btn dropdown-toggle primary-btn"
                            type="button" id="launchprotocol2"
                            data-bs-toggle="dropdown" aria-expanded="false">Update</button>
                          <ul class="dropdown-menu"
                            aria-labelledby="launchprotocol2">
                            
                            <li><button type="button" class="table-btn
                              ddmdarkGreen" (click)="UpdateBulkStatus('Complete',
                              i, incidentItem)">Complete</button></li>
                          <li><button type="button" class="table-btn
                              ddmGreen" (click)="UpdateBulkStatus('Ongoing',
                              i, incidentItem)">Ongoing</button></li>
                          <li><button type="button" class="table-btn
                              ddmorange" (click)="UpdateBulkStatus('Accepted',
                              i, incidentItem)">Accepted</button></li>
                          <li><button type="button" class="table-btn
                            ddmLightGrey" (click)="UpdateBulkStatus('Not Required', i,
                              incidentItem)">Not Required</button></li>
                          <li><button type="button" class="table-btn
                            ddmPink" (click)="UpdateBulkStatus('Declined', i,
                            incidentItem)">Declined</button></li>

                            <!-- <li><button type="button" class="table-btn
                                ddmdarkGreen" (click)="UpdateBulkStatus('Done',
                                i, incidentItem)">Done</button></li>
                            <li><button type="button" class="table-btn
                                ddmGreen" (click)="UpdateBulkStatus('Ongoing',
                                i, incidentItem)">Ongoing</button></li>
                            <li><button type="button" class="table-btn
                                ddmorange" (click)="UpdateBulkStatus('Accepted',
                                i, incidentItem)">Accepted</button></li>
                            <li><button type="button" class="table-btn
                                ddmGrey" (click)="UpdateBulkStatus('Pending', i,
                                incidentItem)">Pending</button></li> -->
                          </ul>
                        </div>

                      </td>
                      <!-- <td>

                        bottom checkbox for select all-->
                        <!-- <input [id]="'taskCheckbox' + i" class="table-checkbox border-check
                          form-check-input" [class]="'chkSame' + i" type="checkbox" value="" 
                          (change)="allCheckChanged($event, i, teamActions[i].tasks)"> 
                      </td> -->
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>

<div class="divloader" *ngIf="isDataLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>
