import { MemberType } from "src/app/Enum/MemberType.enum";
import { UserRole } from "src/app/Enum/UserRole.enum";

export class Member {
  id : string;
  firstName : string;
  familyName : string;
  email : string;
  jobTitle : string;
  employer : string;
  phoneNo : string;
  avatar : string;
  isEmailVerified : boolean;
  isPhoneNoVerified : boolean;
  tickedTerms : boolean;
  memberType : MemberType;
  //roles : UserRole;
  roles : number;
}
