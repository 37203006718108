import { AssignMemberPopupComponent } from '../../overlays/assign-member-popup/assign-member-popup.component';
import { ConfirmationDialogServiceService } from "../../overlays/confirmation-dialog/confirmation-dialog-service.service";
import { NotesOverlayComponent } from "../../overlays/notes-overlay/notes-overlay.component";
import { CstProtocolService } from "./cst-protocol.service";
import { LoadScriptService } from "../load-script.service";
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NavbarService } from "../../nav-menu/navbar.service";
import {
  PostProtocolActionsModel,
  PostProtocolActionsTaskModel,
} from "src/app/Models/PostProtocolActionsModel";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ConfirmationModelService } from "src/app/overlays/confirmation-modal/confirmation-model.service";
import { ToastrService } from 'ngx-toastr';
import { Member } from "src/app/Models/Members/Member";
import { IncidentNoteDTO } from 'src/app/Models/Notes/IncidentNoteDTO';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { IncidentMessageSendService } from 'src/app/team/incident-message-send/incident-message-send.service';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';

@Component({
  selector: "app-cst-protocol",
  templateUrl: "./cst-protocol.component.html",
  styleUrls: ["./cst-protocol.component.css"],
})
export class CstProtocolComponent
  implements OnInit, OnDestroy
{
  data: PostProtocolActionsModel[] = [];
  checkbox: { checked: boolean; id: any }[];
  // tempDataForFilter: PostProtocolActionsModel[];
  teamActions: PostProtocolActionsModel[] = [];
  isDataLoading = true;
  isTeamMemebrLoading = true;
  selectedCheckbox: PostProtocolActionsTaskModel[] = [];

  teamMembers = new Set();
  incedences = new Set();
  timeFrames : string[] = ["All time frames"];
  issues : string[] = ["All", "With", "Without"];
  categories = new Set();
  incedenceStatuses: string[] = ["All"];
  selectedTeamMember: string;
  selectedTimeFrame: string;
  selectedIssue: string;
  selectedincedence: string;
  selectedincedenceId: string;
  selectedincedenceStatus: string;
  selectedCategory: string;
  allTeamMembers: Member[] = [];
  loggedInUserId: string = '';
  // isConfirmationPopupOpen = false;
  // isConfirm = false;
  // confirmationMessage = "";

  teamMembersToAssign: Member[];

  updateStatusSubscription: Subscription;
  updateIndividualStatusSubscription: Subscription;

  @ViewChild("btnIncedenceStatus") btnIncedenceStatus: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private NavbarService: NavbarService,
    private loadScriptService: LoadScriptService,
    private cstProtocolService: CstProtocolService,
    public dialog: MatDialog,
    public confirmationDialogServiceService: ConfirmationDialogServiceService,
    public confirmationModelService: ConfirmationModelService,
    private toastr: ToastrService,
    private apiErrorService: ApiErrorService,
    private incidentMessageSendService: IncidentMessageSendService,
    public _urlHelperService: UrlHelperService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data.title);
    this.NavbarService.setPageTitle(this.activatedRoute.snapshot.data.title);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.category != null && params.category != undefined) {
        if (params.category == "Issue"){
          this.selectedincedenceStatus = "All";
          this.selectedIssue = "With";
        }
        else{
          this.selectedincedenceStatus = params.category;
          this.selectedIssue = "All";
        }
      } 
      else {
        this.selectedincedenceStatus = "All";
        this.selectedIssue = "All";
      }
      if (params.member != null && params.member != undefined){
        this.loggedInUserId = params.member;
      }
      // if (params.tital != null && params.tital != undefined) {
      //   this.selectedincedence = params.tital;
      // } else {
      //   this.selectedincedence = "All";
      // }
    });

    this.activatedRoute.params.subscribe((param) => {
      this.selectedincedenceId = param["id?"];
    });

    this.incidentMessageSendService.GetAllTeamMembers()
      .subscribe(response => {
         //console.log(response);
        this.allTeamMembers = response.data;
      }, 
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this.apiErrorService.ShowErrorModal("Error", error.error.message);
          //this.toastr.error(error.error.message, "Error");
        }
        else{
          this.apiErrorService.ShowErrorModal();
          //this.toastr.error("Something went wrong!");
        }
      });

    this.selectedTimeFrame = "All time frames";

    this.cstProtocolService.getTeamMembers().subscribe(response => {
      if (
        response != null &&
        response != undefined &&
        response.statusCode == 200
      ){
        this.teamMembersToAssign = response.data;
        //console.log(response.data);
        this.teamMembersToAssign.sort((a, b) => a.firstName.localeCompare(b.firstName));
        this.isTeamMemebrLoading = false;
      }
    },
    (error) => {
      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this.toastr.error(error.error.message, "Error");
      }
      else{
        this.toastr.error("Something went wrong!");
      }
    })

    this.cstProtocolService
      .getPostProtocolActions()
      .subscribe((response) => {
        if (
          response != null &&
          response != undefined &&
          response.statusCode == 200
        ) {
           console.log(response.data);
          if (response.data != null && response.data != undefined) {
            this.data = response.data;
            this.teamActions = this.data;

            const title = this.teamActions.find(
              (x) => x.id === this.selectedincedenceId
            )?.title;
            if (title == undefined || title == null) {
              this.selectedincedence = "All";
            } else {
              this.selectedincedence = title;
            }

            this.teamMembers.add("All");
            this.incedences.add("All");
            this.categories.add("All categories");

            for (const actions of this.teamActions) {
              this.incedences.add(actions.title);
            }

            let teamMembers = [];
            for (const actions of this.teamActions) {
              for (const task of actions.tasks) {
                teamMembers.push(task.personName);
              }
            }
            teamMembers.sort();

            for (const teamMember of teamMembers) {
              this.teamMembers.add(teamMember);
            }
            
            for (const actions of this.teamActions) {
              for (const task of actions.tasks) {
                this.categories.add(task.category);
              }
            }

            const staticDataIncedenceStatuses = [
              // "Pending",
              // "Accepted",
              // "Ongoing",
              // "Done",

              "Accepted",
              "Declined",
              "Pending",
              "Complete",
              "Not Required",
              "Ongoing",
              "Late"
            ];
            this.incedenceStatuses.push(...staticDataIncedenceStatuses);

            const staticDataTimeFrames = [
              "24 hours",
              "1 week",
              "1 month"
            ];
            this.timeFrames.push(...staticDataTimeFrames);

            //this.selectedIssue = [...this.issues][0] as string;

            if (this.allTeamMembers && this.allTeamMembers.length > 0 && this,this.loggedInUserId){
              const teamMember = this.allTeamMembers.find(x => x.id == this.loggedInUserId);
  
              const teamMembers = [...this.teamMembers] as string[];
  
              if (teamMember && teamMembers.find(x => x == `${teamMember.firstName} ${teamMember.familyName}`)){
                this.selectedTeamMember = `${teamMember.firstName} ${teamMember.familyName}`;
              }
              else{
                this.selectedTeamMember = [...this.teamMembers][0] as string;
              }
            }
            else{
              this.selectedTeamMember = [...this.teamMembers][0] as string;
            }

            this.selectedCategory = [...this.categories][0] as string;
            this.filterData();
            // this.selectedincedence = [...this.incedences][0] as string;
            // this.selectedincedenceStatus = this.incedenceStatuses[0];

            

            this.isDataLoading = false;

            this.loadScriptService.LoadScript(
              "protocol-actions-post-protocol-launchScriptTag"
            );
           

            // var firstAccordian = document.getElementById('contactaccord0');

            // if (firstAccordian != null && firstAccordian != undefined){
            //   firstAccordian.classList.add("show");
            // }
          }
        }
      },
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this.apiErrorService.ShowErrorModal("Error", error.error.message);
          //this.toastr.error(error.error.message, "Error");
        }
        else{
          this.apiErrorService.ShowErrorModal();
          //this.toastr.error("Something went wrong!");
        }
      });

    // this.confirmationModelService.onCancelEvent.subscribe((x) => {
    //   this.isConfirm = false;
    //   this.isConfirmationPopupOpen = false;
    // });
    // this.confirmationModelService.onConfirmEvent.subscribe((x) => {
    //   this.isConfirm = true;
    //   this.isConfirmationPopupOpen = false;
    // });
  }

  teamMemberClicked(selectedValue: string) {
    this.selectedTeamMember = selectedValue;
    this.filterData();
    document.getElementById("t_dropdownTeamMembers").classList.remove("shown");
  }

  incedenceStatusClicked(selectedValue: string) {
    this.selectedincedenceStatus = selectedValue;
    this.filterData();
    document
      .getElementById("t_dropdownIncedentStatuses")
      .classList.remove("shown");

    // console.log(this.btnIncedenceStatus);

    // $(this).parents('.each-dropdown-content-js').removeClass('shown');

    // this.btnIncedenceStatus.nativeElement.parentNode.classList.remove('shown');
  }

  categoryClicked(selectedValue: string) {
    this.selectedCategory = selectedValue;
    this.filterData();
    document
      .getElementById("t_dropdownCategory")
      .classList.remove("shown");
  }

  IssuesClicked(selectedValue: string){
    this.selectedIssue = selectedValue;
    this.filterData();
    document
      .getElementById("t_dropdownIssues")
      .classList.remove("shown");
  }


  timeFrameClicked(selectedValue: string) {
    this.selectedTimeFrame = selectedValue;
    this.filterData();
    document
      .getElementById("t_dropdownTimeFrames")
      .classList.remove("shown");

  }

  incedenceClicked(selectedValue: string) {
    this.selectedincedence = selectedValue;
    this.filterData();
    document.getElementById("t_dropdownIncedent").classList.remove("shown");
  }

  filterData() {
    let temp: PostProtocolActionsModel = new PostProtocolActionsModel();
    let tempActions: PostProtocolActionsModel[] = [];

    const tempDataForFilter = JSON.parse(JSON.stringify(this.data)); // creating deep copy

    if (this.selectedincedence == "All") {
      tempActions = tempDataForFilter;
    } else {
      tempActions = tempDataForFilter.filter(
        (x) => x.title === this.selectedincedence
      );
    }

    for (const teamActions of tempActions) {
      const action = tempActions.find((x) => x === teamActions);
      if (action != null && action != undefined) {
        temp.id = teamActions.id;
        temp.location = teamActions.location;
        temp.title = teamActions.title;

        if (action.tasks != null && action.tasks != undefined && action.tasks.length > 0) {
          let tempTasks : PostProtocolActionsTaskModel[] = [];
          tempTasks = teamActions.tasks;
          // if (this.selectedTeamMember === "All" && this.selectedincedenceStatus == "All") {

          //   tempTasks = teamActions.tasks;
          // } 
          // else if (this.selectedTeamMember != "All" && this.selectedincedenceStatus != "All") {

          //   tempTasks = teamActions.tasks.filter((x) => 
          //     x.status === this.protocolActionsPostProtocolLaunchService.getStatusNumber(this.selectedincedenceStatus) && x.personName === this.selectedTeamMember);
          // }
          // else if (this.selectedTeamMember == "All" && this.selectedincedenceStatus != "All") {

          //   tempTasks = teamActions.tasks.filter((x) =>
          //       x.status === this.protocolActionsPostProtocolLaunchService.getStatusNumber(this.selectedincedenceStatus));
          // } 
          // else {
          //   tempTasks = teamActions.tasks.filter((x) => x.personName === this.selectedTeamMember);
          // }

          // if (this.selectedincedenceStatus !== "All"){
          //   const statusToBeFilter = this.protocolActionsPostProtocolLaunchService.getStatusNumber(this.selectedincedenceStatus);
          //   for (let i = tempTasks.length - 1; i >= 0; i--) {
          //     if (tempTasks[i].status !== statusToBeFilter) {
          //       tempTasks.splice(i, 1);
          //     }
          //   }
          // }

          // if (this.selectedTeamMember !== "All"){
          //   for (let i = tempTasks.length - 1; i >= 0; i--) {
          //     if (tempTasks[i].personName !== this.selectedTeamMember) {
          //       tempTasks.splice(i, 1);
          //     }
          //   }
          // }

          // if (this.selectedTimeFrame !== "All"){
          //   const selectedTimeFrame = this.protocolActionsPostProtocolLaunchService.GetTimeFrameNumber(this.selectedTimeFrame);
          //   for (let i = tempTasks.length - 1; i >= 0; i--) {
          //     if (tempTasks[i].timeFrame !== selectedTimeFrame) {
          //       tempTasks.splice(i, 1);
          //     }
          //   }
          // }

          // if (this.selectedCategory !== "All"){
          //   for (let i = tempTasks.length - 1; i >= 0; i--) {
          //     if (tempTasks[i].category !== this.selectedCategory) {
          //       tempTasks.splice(i, 1);
          //     }
          //   }
          // }

          if (this.selectedincedenceStatus !== "All"){
            if (this.selectedincedenceStatus === "Late"){
              tempTasks = tempTasks.filter(x => (new Date(x.date)).getTime() < (new Date).getTime());
            }
            else{
              const statusToBeFilter = this.cstProtocolService.getStatusNumber(this.selectedincedenceStatus);
              tempTasks = tempTasks.filter(x => x.status == statusToBeFilter);
            }
          }

          if (this.selectedIssue !== "All"){
            const statusToBeFilter = this.cstProtocolService.GetIssueBoolean(this.selectedIssue);
            if (statusToBeFilter == null){ }
            else{
              tempTasks = tempTasks.filter(x => x.issue == statusToBeFilter);
            }
          }

          if (this.selectedTeamMember !== "All"){
            tempTasks = tempTasks.filter(x => x.personName == this.selectedTeamMember);
          }

          if (this.selectedTimeFrame !== "All time frames"){
            const selectedTimeFrame = this.cstProtocolService.GetTimeFrameNumber(this.selectedTimeFrame);
            tempTasks = tempTasks.filter(x => x.timeFrame == selectedTimeFrame);
          }

          if (this.selectedCategory !== "All categories"){
            tempTasks = tempTasks.filter(x => x.category == this.selectedCategory);
          }



          // let tempTasksForTimeFrame : PostProtocolActionsTaskModel[] = [];
          // if (this.selectedTimeFrame !== "All"){
          //   const selectedTimeFrame = this.protocolActionsPostProtocolLaunchService.GetTimeFrameNumber(this.selectedTimeFrame);
          //   tempTasks.forEach((x, index) => {
          //     if (x.timeFrame === selectedTimeFrame){
          //       tempTasksForTimeFrame.push(x);
          //       // const indexToBERemove = tempTasks.findIndex(i => i.incidentTaskId === x.incidentTaskId);
          //       // tempTasks.splice(indexToBERemove, 1);
          //     };
          //   })
          // }
          // else{
          //   tempTasksForTimeFrame = tempTasks;
          // }

          // let tempTasksForCategory : PostProtocolActionsTaskModel[] = [];
          // if (this.selectedCategory !== "All"){
          //   tempTasksForTimeFrame.forEach((x) => {
          //     if (x.category === this.selectedCategory){
          //       tempTasksForCategory.push(x);
          //       // const indexToBERemove = tempTasks.findIndex(i => i.incidentTaskId === x.incidentTaskId);
          //       // tempTasks.splice(indexToBERemove, 1);
          //     };
          //   })
          // }
          // else{
          //   tempTasksForCategory = tempTasksForTimeFrame;
          // }

          temp.tasks = [...tempTasks];
          action.tasks = [...temp.tasks];
        }

        tempActions.map((item, index) => {
          if (item === action) {
            tempActions.splice(index, 1, action);
          }
        });
      }
    }
    this.teamActions = [...tempActions];
  }

  trackByFn(index: number, item: PostProtocolActionsModel) {
    return index;
  }

  allCheckChanged(
    event: Event,
    index: number,
    tasks: PostProtocolActionsTaskModel[]
  ) {
    const tagClass = (event.target as HTMLElement).id;
    if (tagClass != null && tagClass != undefined) {
      if (tasks != null && tasks != undefined && tasks.length > 0) {
        const chk = event.target as HTMLInputElement;
        if (chk.checked) {
          for (const task of tasks) {
            if (!this.selectedCheckbox.some((item) => item === task)) {
              this.selectedCheckbox.push(task);
            }
          }
        } else {
          this.selectedCheckbox = this.selectedCheckbox.filter(
            (item1) => !tasks.some((item2) => item1.id === item2.id)
          );
        }
      }

      const checkboxes = document.querySelectorAll(
        "." + tagClass
      ) as NodeListOf<HTMLInputElement>;
      if (
        checkboxes != null &&
        checkboxes != undefined &&
        checkboxes.length > 0
      ) {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = (event.target as HTMLInputElement).checked;
        });
      }
      //#region common checkbox logic
      // const commonCheckbox = document.querySelectorAll(
      //   "." + "chkSame" + index
      // ) as NodeListOf<HTMLInputElement>;
      // if (
      //   commonCheckbox != null &&
      //   commonCheckbox != undefined &&
      //   commonCheckbox.length > 0
      // ) {
      //   commonCheckbox.forEach((checkbox) => {
      //     checkbox.checked = (event.target as HTMLInputElement).checked;
      //   });
      // }
      //#endregion
    }
  }

  IndividualTaskStatusClicked(
    status: string = null,
    OutterIndex: number,
    InnerIndex: number,
    incident: PostProtocolActionsModel,
    task: PostProtocolActionsTaskModel
  ) {
    if (
      OutterIndex == undefined ||
      InnerIndex == undefined ||
      status == undefined ||
      status == null ||
      task == undefined ||
      incident == undefined
    ) {
      return;
    }

    const taskToBeSend = this.teamActions[OutterIndex]?.tasks[InnerIndex];
    // console.log(taskToBeSend.incidentMemberTaskId);

    if (taskToBeSend == undefined || taskToBeSend == null) {
      return;
    }

    // this.confirmationMessage = "Message";
    // this.isConfirmationPopupOpen = true;

    this.updateIndividualStatusSubscription =
      this.confirmationDialogServiceService
        .confirmDialog(
          "Update Confirmation",
          "Are you sure you want to update status"
        )
        .subscribe((isConfirm) => {
          if (!isConfirm) {
            return;
          }

          document.getElementById(OutterIndex + 'update-status-spinner' + InnerIndex).style.display = 'block';
          document.getElementById(OutterIndex + 'btnTaskStatus' + InnerIndex).style.display = 'none';          
          document.getElementById(OutterIndex + 'status-td' + InnerIndex).style.backgroundColor = '#fff';

          const statusId =
            this.cstProtocolService.getStatusNumber(
              status
            );

          this.cstProtocolService
            .UpdateBulkStatuses(
              taskToBeSend.incidentMemberTaskId?.toString(),
              statusId
            )
            .subscribe(() => {
              let tempActions: PostProtocolActionsModel[] = [];
              let tempDataForFilter = JSON.parse(
                JSON.stringify(this.teamActions)
              ); // creating deep copy

              if (this.selectedincedence == "All") {
                tempActions = tempDataForFilter;
              } else {
                tempActions = tempDataForFilter.filter(
                  (x) => x.title === this.selectedincedence
                );
              }

              tempActions[OutterIndex].tasks[InnerIndex].status = statusId;

              this.teamActions = [...tempActions];

              const incidentIndex = this.data.findIndex(
                (x) => x.id === incident.id
              );
              if (incidentIndex != -1) {
                const taskIndex = this.data[incidentIndex].tasks.findIndex(
                  (x) =>
                    x.incidentMemberTaskId === taskToBeSend.incidentMemberTaskId
                );

                if (taskIndex != -1) {
                  this.data[incidentIndex].tasks[taskIndex].status = statusId;

                  document.getElementById(OutterIndex + 'update-status-spinner' + InnerIndex).style.display = 'none';
                  document.getElementById(OutterIndex + 'btnTaskStatus' + InnerIndex).style.display = 'block';

                  this.filterData();
                }
              }
            },
            (error) => {
              document.getElementById(OutterIndex + 'update-status-spinner' + InnerIndex).style.display = 'none';
              document.getElementById(OutterIndex + 'btnTaskStatus' + InnerIndex).style.display = 'block';

              if (error && error.error && error.error.message){
                console.log("Error:" + JSON.stringify(error));
                this.apiErrorService.ShowErrorModal("Error", error.error.message);
                //this.toastr.error(error.error.message, "Error");
              }
              else{
                this.apiErrorService.ShowErrorModal();
                //this.toastr.error("Something went wrong!");
              }
            });
        });
  }

  UpdateBulkStatus(status: string = null, index: number, Incident: PostProtocolActionsModel) {
    if (index == undefined || status == undefined || status == null || Incident == undefined) {
      return;
    }

    const incidentRow = this.teamActions[index];
    if (incidentRow == null || incidentRow == undefined) {
      return;
    }

    const tasksToBeSend = this.selectedCheckbox.filter((item1) =>
      this.teamActions[index].tasks.some((item2) => item1 === item2)
    );

    if (
      tasksToBeSend == undefined ||
      tasksToBeSend == null ||
      tasksToBeSend.length <= 0
    ) {
      return;
    }


    this.updateStatusSubscription = this.confirmationDialogServiceService
      .confirmDialog(
        "Update Confirmation",
        "Are you sure you want to update status"
      )
      .subscribe((isConfirm) => {
        if (!isConfirm) {
          return;
        }

        for (const taskToBeSend of tasksToBeSend) {
          const innerIndex = this.teamActions[index].tasks.findIndex(x => x.incidentTaskId === taskToBeSend.incidentTaskId);
          if (innerIndex != -1){
            document.getElementById(index + 'update-status-spinner' + innerIndex).style.display = 'block';
            document.getElementById(index + 'btnTaskStatus' + innerIndex).style.display = 'none';
            document.getElementById(index + 'status-td' + innerIndex).style.backgroundColor = '#fff';
          }
        }

        let taskMemberId: string = "";

        for (const task of tasksToBeSend) {
          if (
            task != null &&
            task != undefined &&
            task.incidentMemberTaskId != 0
          ) {
            taskMemberId += task.incidentMemberTaskId + ",";
          }
        }
        taskMemberId = taskMemberId.slice(0, -1);

        if (
          taskMemberId == null ||
          taskMemberId == undefined ||
          taskMemberId.trim() === "" ||
          status == undefined ||
          status == null ||
          status.trim() === ""
        ) {
          return;
        }

        const statusId =
          this.cstProtocolService.getStatusNumber(status);
        this.cstProtocolService
          .UpdateBulkStatuses(taskMemberId, statusId)
          .subscribe(
            () => {
              let tempActions: PostProtocolActionsModel[] = [];
              let tempDataForFilter = JSON.parse(
                JSON.stringify(this.teamActions)
              ); // creating deep copy

              if (this.selectedincedence == "All") {
                tempActions = tempDataForFilter;
              } else {
                tempActions = tempDataForFilter.filter(
                  (x) => x.title === this.selectedincedence
                );
              }

              tempActions[index].tasks.map((task) => {
                const tytt = tasksToBeSend.findIndex(
                  (x) => x.incidentMemberTaskId == task.incidentMemberTaskId
                );
                if (tytt !== -1) {
                  task.status = statusId;
                }
                return task;
              });

              this.teamActions = [...tempActions];

              const incidentIndex = this.data.findIndex(x => x.id == Incident.id);
              if (incidentIndex == -1){
                return;
              }

              for (const task of tasksToBeSend) {
                const taskIndex = this.data[incidentIndex].tasks.findIndex(x => x.incidentMemberTaskId == task.incidentMemberTaskId);
                if (taskIndex == -1){
                  return;
                }
                
                const innerIndex = this.teamActions[index].tasks.findIndex(x => x.incidentTaskId === task.incidentTaskId);
                if (innerIndex != -1){
                  document.getElementById(index + 'update-status-spinner' + innerIndex).style.display = 'none';
                  document.getElementById(index + 'btnTaskStatus' + innerIndex).style.display = 'block';
                }

                this.data[incidentIndex].tasks[taskIndex].status = statusId;
              }

              this.filterData();

              // console.log(result);
            },
            (error) => {
              for (const task of tasksToBeSend) {
                const innerIndex = this.teamActions[index].tasks.findIndex(x => x.incidentTaskId === task.incidentTaskId);
                if (innerIndex != -1){
                  document.getElementById(index + 'update-status-spinner' + innerIndex).style.display = 'none';
                  document.getElementById(index + 'btnTaskStatus' + innerIndex).style.display = 'block';
                }
              }

              if (error && error.error && error.error.message){
                console.log("Error:" + JSON.stringify(error));
                this.apiErrorService.ShowErrorModal("Error", error.error.message);
                //this.toastr.error(error.error.message, "Error");
              }
              else{
                this.apiErrorService.ShowErrorModal();
                //this.toastr.error("Something went wrong!");
              }

            }
          );
      });
  }

  checkboxChanged(event, task: PostProtocolActionsTaskModel) {
    if (event.target.checked) {
      if (!this.selectedCheckbox.some((item) => item === task)) {
        this.selectedCheckbox.push(task);
      }
    } else {
      this.selectedCheckbox = this.selectedCheckbox.filter(
        (item) => item !== task
      );
    }
  }

  getStatusData(status: number): { classname: string; statusName: string } {
    return this.cstProtocolService.getStatusData(status);
  }

  IssueClicked(task: PostProtocolActionsTaskModel, action: PostProtocolActionsModel, i: number, ii: number) {

    this.NotesClicked(task, action, i, ii);

    this.teamActions[i].tasks[ii].issue = !this.teamActions[i].tasks[ii].issue;
  }

  NotesClicked(task: PostProtocolActionsTaskModel, action: PostProtocolActionsModel, i: number, ii: number){

    // if (!task.hasNotes){
    //   return;
    // }

    this.cstProtocolService.GetCSTPlanningNotes(task.incidentTaskId).subscribe(response => {
      if (!response || !response.data || response.statusCode != 200){
        this.toastr.error("Something went wrong", "Error");
      }

      const incidentNoteDTO = response.data as IncidentNoteDTO[];
      const dataToBeSend = { incidentId: action.id, taskId: task.incidentTaskId, noteType: "CST" ,incidentTitle: task.actionName, noteData: incidentNoteDTO };

      this.dialog.open(NotesOverlayComponent, {
        data: dataToBeSend
      }).afterClosed().subscribe(isNoteAdded => {
        if (isNoteAdded !== true){
           return;
        }

        const incidentIndexForData = this.data.findIndex(x => x.id === action.id);
        if (incidentIndexForData != -1){
          const actionIndex = this.data[incidentIndexForData].tasks.findIndex(x => x.incidentTaskId === task.incidentTaskId);
          if (actionIndex != -1){
            this.data[incidentIndexForData].tasks[actionIndex].hasNotes = true;
          }
        }

        const incidentIndexForTeamActions = this.teamActions.findIndex(x => x.id === action.id);
        if (incidentIndexForTeamActions != -1){
          const actionIndex = this.teamActions[incidentIndexForTeamActions].tasks.findIndex(x => x.incidentTaskId === task.incidentTaskId);
          if (actionIndex != -1){
            this.teamActions[incidentIndexForTeamActions].tasks[actionIndex].hasNotes = true;
          }
        }
        
      });;
  
    },
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this.apiErrorService.ShowErrorModal("Error", error.error.message);
          //this.toastr.error(error.error.message, "Error");
        }
        else{
          this.apiErrorService.ShowErrorModal();
          //this.toastr.error("Something went wrong!");
        }
      });


  }

  GetTimeFrame(index: number): string {
    return this.cstProtocolService.GetTimeFrame(index);
  }

  personClicked(task: PostProtocolActionsTaskModel, outerIndex: number, innerIndex: number){
    let memberArray : {name: string, avatar: string}[] = [];
    memberArray = this.teamMembersToAssign.map(x => { 
      return {name: `${x.firstName} ${x.familyName}`, avatar: x.avatar}
    });
    //const memberStringArray = this.teamMembersToAssign.map(x => `${x.firstName} ${x.familyName}`).sort();

    this.dialog.open(AssignMemberPopupComponent, {
      height: '80vh',
      data: {members: memberArray}
    }).afterClosed().subscribe(selectedMember => {
      if (selectedMember == null || selectedMember == undefined || selectedMember == ''){
        return;
      }

      const selectedTeamMember = this.teamMembersToAssign.find(x => `${x.firstName} ${x.familyName}` === selectedMember);
      if (!selectedTeamMember){
        return;
      }

      this.AssignTeamMember(selectedTeamMember, task, outerIndex, innerIndex);
    });
  }


  AssignTeamMember(teamMember: Member, task: PostProtocolActionsTaskModel, outerIndex: number, innerIndex: number){

    document.getElementById(outerIndex + 'update-assigned-member-spinner' + innerIndex).style.display = 'block';
    document.getElementById(outerIndex + 'assigned-member-div' + innerIndex).style.display = 'none';

    this.cstProtocolService
    .AssignTaskToTeamMember(this.selectedincedenceId, task.incidentTaskId, teamMember.id).subscribe(response => {
      if (
        response != null &&
        response != undefined &&
        response.statusCode == 200
      ){
        

        this.teamActions[outerIndex].tasks[innerIndex].personName = `${teamMember.firstName} ${teamMember.familyName}`;
        this.teamActions[outerIndex].tasks[innerIndex].avatar = teamMember.avatar;

        const outterIndexForMaindata = this.data.findIndex(x => x.id == this.teamActions[outerIndex].id);
        if (outterIndexForMaindata == -1){
          return;
        }
        const innerIndexForMaindata = this.data[outterIndexForMaindata].tasks.findIndex(x => x.incidentTaskId == task.incidentTaskId);
        if (innerIndexForMaindata == -1){
          return;
        }

        this.data[outterIndexForMaindata].tasks[innerIndexForMaindata].personName = `${teamMember.firstName} ${teamMember.familyName}`;
        this.data[outterIndexForMaindata].tasks[innerIndexForMaindata].avatar = teamMember.avatar;

        document.getElementById(outerIndex + 'update-assigned-member-spinner' + innerIndex).style.display = 'none';
        document.getElementById(outerIndex + 'assigned-member-div' + innerIndex).style.display = 'block';
      }
    },
    (error) => {

      document.getElementById(outerIndex + 'update-assigned-member-spinner' + innerIndex).style.display = 'none';
      document.getElementById(outerIndex + 'assigned-member-div' + innerIndex).style.display = 'block';

      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this.apiErrorService.ShowErrorModal("Error", error.error.message);
        //this.toastr.error(error.error.message, "Error");
      }
      else{
        this.apiErrorService.ShowErrorModal();
        //this.toastr.error("Something went wrong!");
      }
      
    });
    
  }

  GetBackgroundColorForTd(status: number): string{
    return this.cstProtocolService.GetBackgroundColorForTd(status);
  }

  // handleConfirm() {
  //   // handle confirm logic
  //   this.isConfirmationPopupOpen = true;
  // }

  // handleCancel() {
  //   this.isConfirmationPopupOpen = false;
  // }

  ngOnDestroy(): void {
    this.loadScriptService.RemoveScript(
      "protocol-actions-post-protocol-launchScriptTag"
    );

    if (
      this.updateStatusSubscription == undefined ||
      this.updateStatusSubscription == null
    ) {
      return;
    }
    this.updateStatusSubscription.unsubscribe();

    if (
      this.updateIndividualStatusSubscription == undefined ||
      this.updateIndividualStatusSubscription == null
    ) {
      return;
    }
    this.updateIndividualStatusSubscription.unsubscribe();
  }
}
