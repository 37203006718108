import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-authority-cst-protocol',
  templateUrl: './local-authority-cst-protocol.component.html',
  styleUrls: ['./local-authority-cst-protocol.component.css']
})
export class LocalAuthorityCstProtocolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
