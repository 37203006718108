import { SIMUser } from './../SIMUser';
import { OrganizationGroupMember } from './OrganizationGroupMember';
export class MemberProfile {

  public id: string;
  public avatar: string;
  public role: string;
  public firstName: string;
  public givenName: string;
  public familyName: string;
  public jobTitle: string;
  public employer: string;
  public phone: string;
  public IsPhoneNoVerified: boolean;
  public IsEmailVerified: boolean;
  public email: string;
  public memberType: number;
  public User: SIMUser;
  public MemberCategoryId: number;
  public Groups: OrganizationGroupMember[];
  public Statutory: boolean;
  public incidentAlert: boolean;
  public locationAlert: boolean;
  public section60Alert: boolean;
  public section35Alert: boolean;
  public partnerMessage: boolean;
  public incidentLaunchIfNotTeam: boolean;
  public dashboardContact: boolean;
  public organisationGroupid: number;
}
