import { SimpleIncidentDTO } from './../Models/incident.modal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiErrorService } from '../core/services/api-error.service';
import { UrlHelperService } from '../core/services/url-helper.service';
import { LoadScriptService } from '../home-dashboard/load-script.service';
import { IncidentsListService } from './incidents-list.service';
import { IncidentStatusEnum } from '../Enum/IncidentStatus.enum';

@Component({
  selector: 'app-incidents-list',
  templateUrl: './incidents-list.component.html',
  styleUrls: ['./incidents-list.component.css']
})
export class IncidentsListComponent implements OnInit, OnDestroy {

  incidents: SimpleIncidentDTO[];
  incidentsDeepCopy: SimpleIncidentDTO[];

  incidentStatusEnum = IncidentStatusEnum;

  isIncidentsLoaded: boolean = false;

  selectedincedenceStatus: string = "All";

  incedenceStatuses: string[] = ["All"];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _loadScriptService: LoadScriptService,
    private _incidentsListService: IncidentsListService,
    private _apiErrorService: ApiErrorService,
    public _urlHelperService: UrlHelperService
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    this._incidentsListService.GetAllIncident()
    .subscribe(response => {
      if (!response || !response.data || response.statusCode != 200){
        return;
      }

      this.incidents = this._incidentsListService.SortIncidentsBasedOnDateTime(response.data);
      this.incidentsDeepCopy = this.incidents;
      
      this.isIncidentsLoaded = true;
      this._loadScriptService.LoadDropdownScript("la-incident-listScriptTag");
    },
    (error) => {
        this._apiErrorService.ShowError(error);
    });

    this.incedenceStatuses.push(...Object.keys(IncidentStatusEnum)
      .filter(key => (isNaN(Number(key)) && key != IncidentStatusEnum[IncidentStatusEnum.Open]))
      .map(key => key.trim()));
  }

  incedenceStatusClicked(selectedValue: string) {
    this.selectedincedenceStatus = selectedValue;
    this.filterData();
    document.getElementById("t_dropdown2").classList.remove("shown");
  }


  filterData() {
    this.incidentsDeepCopy = JSON.parse(JSON.stringify(this.incidents)); // creating deep copy

    if (this.selectedincedenceStatus !== "All"){
      if (this.selectedincedenceStatus == IncidentStatusEnum[IncidentStatusEnum.Active]){
        this.incidentsDeepCopy = this.incidents.filter(x => x.status == IncidentStatusEnum.Active || x.status == IncidentStatusEnum.Open);
      }
      else{
        this.incidentsDeepCopy = this.incidents.filter(x => x.status == IncidentStatusEnum[this.selectedincedenceStatus])// tempTasks.filter(x => x.EmergencyTaskStatus == this._immediateResponseService.getStatusNumber(this.selectedincedenceStatus));
      }
    }
  }

  GetIncidentStatusString(status: IncidentStatusEnum){
    return this._incidentsListService.GetIncidentStatusString(status);
  }


  trackByFn(index: number, item: SimpleIncidentDTO) {
    return index;
  }

  ngOnDestroy(): void {
    this._loadScriptService.RemoveScript(
      "la-incident-listScriptTag"
    );
  }
}
