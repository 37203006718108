import { LocationDTO } from "./incident.modal";

export class IncidentFormLocationEntity {
  id: string;
  name: string;
  description : string
  lat: number;
  lng: number;
  label: string;
  draggable: boolean;
  visible: boolean;
  opacity: number;
  icon: string;
  pathList: any[];
  closed: boolean;
  locationDTO: LocationDTO;
  modifyDate : Date | null
}
