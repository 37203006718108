import { MessageQueueDTO } from './../../Models/incident.modal';
import { MessageGroupEnum, ToInformation } from './../../Models/la-Team-messages/LATeamMessagingDashboard';
import { MessageDeliveryChannelEnum } from './../../Enum/MessageDeliveryChannelEnum.enum';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StakeholderCategoryList, StakeholderProfileCreateDTO } from 'src/app/Models/Stakeholder/Stakeholder';
import { IncidentMessageSendService } from '../../team/incident-message-send/incident-message-send.service';
import { StakeholderMembersService } from 'src/app/Stakeholder/stakeholder-members/stakeholder-members.service';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-stakeholder-message-send',
  templateUrl: './stakeholder-message-send.component.html',
  styleUrls: ['./stakeholder-message-send.component.css']
})
export class StakeholderMessageSendComponent implements OnInit {

  isSubmitted= false;
  sendMessageForm : FormGroup;
  messagedata : MessageQueueDTO;
  overlayData: {messageType : string, incidentId : string}
  currentlySignedInUser : any;
  currentlySignedInUserName : any;
  
  stakeHoldersCategory: StakeholderCategoryList[] = [];
  stakeHoldersMembers: StakeholderProfileCreateDTO[] = [];

  messsageToFromIndividuals : {name: string; memberId: string, avatar: string, jobTitle: string, organisationName: string}[] = [];
  messsageToFromGroup : {name: string; memberId: string, avatar: string, jobTitle: string, organisationName: string}[] = [];
  messsageTofinal: {name: string; memberId: string, avatar: string, jobTitle: string, organisationName: string}[] = [];

  indivualMemeberList: {name: string; memberId: string, avatar: string, jobTitle: string, organisationName: string}[] = [];

  isCategoryLoaded = false;
  isStackholdersLoaded = false;

  constructor(
    private _dialogRef: MatDialogRef<StakeholderMessageSendComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fb : FormBuilder,
    private _incidentMessageSendService : IncidentMessageSendService,
    private _toastr : ToastrService,
    private _stakeholderMembersService: StakeholderMembersService,
    private _apiErrorService: ApiErrorService,
    private _appStartService: AppStartService
    ) { }

  ngOnInit(): void {
    this.overlayData = this._data.messageData;

      this._incidentMessageSendService.GetStakeHolderCategories().subscribe((response => {
        if (response && response.data && response.statusCode == 200){
          //console.log(response.data);

          this.stakeHoldersCategory = response.data;

          this.isCategoryLoaded = true;
        }
      }));

      this._stakeholderMembersService.GetStakeHolderMembers().subscribe((response => {
        if (response && response.data && response.statusCode == 200){

          //console.log(response.data);
          this.stakeHoldersMembers = response.data;

          if (this.stakeHoldersMembers && this.stakeHoldersMembers.length > 0){
            for (const stakeHolder of this.stakeHoldersMembers) {
              if (!this.indivualMemeberList.find(x => x.memberId === stakeHolder.id)){
                this.indivualMemeberList.push({avatar: stakeHolder.avatar, memberId: stakeHolder.id, name: `${stakeHolder.firstName} ${stakeHolder.familyName}`, jobTitle: stakeHolder.jobTitle, organisationName: stakeHolder.organisationName});
              }
            }
          }

          
          this.isStackholdersLoaded = true;
        }
      }));

    this.initializForm();

    if (this.overlayData.messageType == "SMS"){
      this.sendMessageForm.controls.subject.clearValidators();
      this.sendMessageForm.controls.subject.updateValueAndValidity();
    }

    this.currentlySignedInUser = this._appStartService.currentCognitoUser;
    this.currentlySignedInUserName = `${this._appStartService.currentCognitoUser.attributes.given_name} ${this._appStartService.currentCognitoUser.attributes.family_name}`;

    // this._cognitoService.GetUser()
    // .then(user => {
    //   if (user){
    //     this.currentlySignedInUser = user;
    //     this.currentlySignedInUserName = user.attributes.given_name + " " + user.attributes.family_name;
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    this.sendMessageForm.controls["toGroup"].valueChanges.subscribe(x => {
      if (x == '' || x == null || x == undefined){
        return;
      }

      this.sendMessageForm.controls["toGroup"].setValue('');
      
      const selectedCategory = this.stakeHoldersCategory.find(category => category.id === +x);
      if (!selectedCategory){
        return;
      }

      if (selectedCategory.stakeholders && selectedCategory.stakeholders.length > 0){
        for (const member of selectedCategory.stakeholders) {

          const memberToBeAddInGroup = {name: `${member.firstName} ${member.familyName}`, memberId: member.id, avatar: member.avatar, jobTitle: member.jobTitle, organisationName: member.organisationName};
          if (!this.messsageToFromGroup.find(item => item.memberId == memberToBeAddInGroup.memberId)){
            this.messsageToFromGroup.push(memberToBeAddInGroup);
          }

          if (this.indivualMemeberList.findIndex(x => x.memberId === member.id) !== -1){
            this.indivualMemeberList = this.indivualMemeberList.filter((x) => {
              return x.memberId !== member.id;
            });
          }

          // if (this.messsageToFromIndividuals.findIndex(x => x.memberId === member.id) !== -1){
          //   this.messsageToFromIndividuals = this.messsageToFromIndividuals.filter((x) => {
          //     return x.memberId !== member.id;
          //   });
          // }
        }
      }

    });
  }

  initializForm(){
    this.sendMessageForm = this._fb.group({
      'subject' : [null, [Validators.required]],
      'messageContent' : [null, [Validators.required]],
      'toGroup' : [null],
    });
  }

  removeAllFromToGroupListClicked(){
    this.messsageToFromGroup = [];
    this.initializeIndivualMemeberList();
  }


  removeFromToListClicked(obj: {name: string, memberId: string, avatar: string, jobTitle: string, organisationName: string}){
    if (obj){
      this.messsageToFromGroup = this.messsageToFromGroup.filter(x => !(x.memberId === obj.memberId));

      if (!(this.stakeHoldersMembers.findIndex(x => x.id === obj.memberId) === -1) && this.IsAvailableInIndiavidualListOrMesssageToFromIndividuals(obj)){
        this.indivualMemeberList.push(obj);
      }
    }
  }

  removeAllFromToIndividualListClicked(){
    this.messsageToFromIndividuals = [];
    this.initializeIndivualMemeberList();
  }

  removeFromToIndividualListClicked(obj: {name: string; memberId: string, avatar: string, jobTitle: string, organisationName: string}){
    this.messsageToFromIndividuals = this.messsageToFromIndividuals.filter(x => !(x.memberId === obj.memberId));
    this.indivualMemeberList.push(obj);
  }


  IndividualMemeberSelected(memeber: {name: string; memberId: string, avatar: string, jobTitle: string, organisationName: string}){
    if (!memeber){
      return;
    }

    const member = this.indivualMemeberList.find(data => data.memberId == memeber.memberId);
    if (!member){
      return;
    }

    if (this.messsageToFromIndividuals.findIndex(x => x.memberId == member.memberId) == -1){
      this.messsageToFromIndividuals.push(member);

      this.indivualMemeberList = this.indivualMemeberList.filter((data) => {
        return data.memberId !== memeber.memberId;
      });
    }
  }

  initializeIndivualMemeberList(){
    for (const teamMember of this.stakeHoldersMembers) {
      if (this.IsAvailableInIndiavidualListOrMesssageToFromIndividuals(
        {
          name: `${teamMember.firstName} ${teamMember.familyName}`, 
          memberId: teamMember.id, 
          avatar: teamMember.avatar, 
          jobTitle: teamMember.jobTitle,
          organisationName: teamMember.organisationName
        })){
        this.indivualMemeberList.push(
          {
            name: `${teamMember.firstName} ${teamMember.familyName}`, 
            memberId: teamMember.id, 
            avatar: teamMember.avatar,
            jobTitle: teamMember.jobTitle,
            organisationName: ''
          });
      }
    }
  }

  IsAvailableInIndiavidualListOrMesssageToFromIndividuals(member: {name: string; memberId: string, avatar: string, jobTitle: string, organisationName: string}){
    return this.messsageToFromIndividuals.findIndex(x => x.memberId === member.memberId) === -1
      && this.indivualMemeberList.findIndex(x => x.memberId === member.memberId) == -1
  }

  onSubmit(){
    this.isSubmitted = true;

    if (this.sendMessageForm.invalid){
      return;
    }

    if ((this.messsageToFromGroup.length + this.messsageToFromIndividuals.length) <= 0){
      return;
    }

    if (this.messsageToFromGroup.length > 0){
      for (const member of this.messsageToFromGroup) {
        if (this.messsageTofinal.findIndex(x => x.memberId === member.memberId) == -1){
          this.messsageTofinal.push(member);
        }
      } 
    }

    if (this.messsageToFromIndividuals.length > 0){
      for (const member of this.messsageToFromIndividuals) {
        if (this.messsageTofinal.findIndex(x => x.memberId === member.memberId) == -1){
          this.messsageTofinal.push(member);
        }
      } 
    }

    if (this.messsageTofinal.length  <= 0){
      return;
    }


    const sendMessageSpinner = document.getElementById('send-message-spinner');
    if (sendMessageSpinner){
      sendMessageSpinner.style.display = 'block'
    }
    const btnSend = document.getElementById('btn-send');
    if (btnSend){
      btnSend.style.display = 'none'
    }

    this.messagedata = new MessageQueueDTO();
    
    this.messagedata.to = this.messsageTofinal.map(x => x.memberId);
    this.messagedata.message = this.sendMessageForm?.controls?.messageContent.value;
    this.messagedata.subject = this.sendMessageForm?.controls?.subject.value;
    if(this.overlayData.messageType == 'Mail'){
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.Email
    }
    else if (this.overlayData.messageType == 'SMS'){
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.SMS
    }
    else{
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.None
    }
    this.messagedata.from = this.currentlySignedInUser.username;
    this.messagedata.messageGroup = MessageGroupEnum.Stakeholder;
    this.messagedata.relatedIncidentId = this.overlayData.incidentId;
    

    this._incidentMessageSendService.getMessageQueueLogs(this.messagedata, 2)
    .subscribe((response) => {

      const sendMessageSpinner = document.getElementById('send-message-spinner');
      if (sendMessageSpinner){
        sendMessageSpinner.style.display = 'none'
      }
      const btnSend = document.getElementById('btn-send');
      if (btnSend){
        btnSend.style.display = 'block'
      }

      if (response.data == true){

        let toInfo : ToInformation[] = [];
        for (const messageTo of this.messsageTofinal) {
          const to = new ToInformation();
          to.avatar = messageTo.avatar;
          if (this.overlayData.messageType === "SMS"){
            to.to = this.stakeHoldersMembers.find(x => x.id == messageTo.memberId)?.phoneNo
          }
          else{
            to.to = this.stakeHoldersMembers.find(x => x.id == messageTo.memberId)?.email
          }
          toInfo.push(to);
        }

        const fromInfo = `${this.currentlySignedInUser.attributes?.given_name} ${this.currentlySignedInUser.attributes?.family_name}`;

       // = this.messsageTo.map(x => { to: x.memberId; avatar: x.avatar});
        const result = {...this.messagedata, toInfo:toInfo, fromInfo: fromInfo }

        this._dialogRef.close(result);
      }
      else{
        this._toastr.error("Something went wrong!");
        console.log(response);
      }
    }, 
    (error) => {
      
     const sendMessageSpinner = document.getElementById('send-message-spinner');
      if (sendMessageSpinner){
        sendMessageSpinner.style.display = 'none'
      }
      const btnSend = document.getElementById('btn-send');
      if (btnSend){
        btnSend.style.display = 'block'
      }

      this._apiErrorService.ShowError(error);
    });
    
  }

}