//// This file can be replaced during build by using the `fileReplacements` array.
//// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
//// The list of file replacements can be found in `angular.json`.

//export const environment = {
//  production: false,
//  api_url: "https://localhost:44319/api/1",

//  cognito: {
//    userPoolId: "eu-west-2_DdR68mbed",
//    userPoolWebClientId: "4hinoifq4mrs73k46j5tfhvqqd",
//    AWSCognitoSignInURL: "http://localhost:4200/account/login",
//    AWSCognitoSignOut: "http://localhost:4200/account/logout",
//    AWSDomain: "authv2.seriousincidentmanager.com",
//    AWSProjectRegion: "eu-west-2",
//    AWSCognitoRegion: "eu-west-2",
//    AWSAppsyncAuthenticationType: "AMAZON_COGNITO_USER_POOLS",
//    Scope: [
//      "phone",
//      "email",
//      "openid",
//      "profile",
//      "aws.cognito.signin.user.admin"
//    ],
//    ResponseType: "code",
//    Options: {
//      AdvancedSecurityDataCollectionFlag: false
//    },
//    awsLoginUrl:
//    "https://authv2.seriousincidentmanager.com/login?client_id=4hinoifq4mrs73k46j5tfhvqqd&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http://localhost:4200/account/login"
//  },

//  PCR_help_URL: "https://help.seriousincidentmanager.com/police-control-room",
//  PCR_help_BCU_dashboard_URL: "https://help.seriousincidentmanager.com/police-control-room/dashboard/",
//  PCR_help_BCU_IIR_URL: "https://help.seriousincidentmanager.com/police-control-room/initial-incident-form/",
//  PCR_help_BCU_Contacts_URL: "https://help.seriousincidentmanager.com/police-control-room/contacts/",
//  PCR_help_LA_dashboard_URL: "https://help.seriousincidentmanager.com/local-authorities/",
//};

///*
// * In development mode, to ignore zone related error stack frames such as
// * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
// * import the following file, but please comment it out in production mode
// * because it will have performance impact when throw error
// */
//// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

////production
var production = true;
var api = 'https://api.seriousincidentmanager.com/api/1'
var userpool = 'eu-west-2_DdR68mbed'
var id = '4hinoifq4mrs73k46j5tfhvqqd'
var returnurl = 'https://app.seriousincidentmanager.com/'
var awsdomain = 'authv2.seriousincidentmanager.com'

//production - prod 2
//var production = false;
//var api = 'https://api.prod-2.simuk-dev.uk/api/1'
//var userpool = 'eu-west-2_DdR68mbed'
//var id = '4hinoifq4mrs73k46j5tfhvqqd'
//var returnurl = 'https://app.prod-2.simuk-dev.uk/'
//var awsdomain = 'authv2.seriousincidentmanager.com'

////////local
//var production = false;
//var api = 'https://localhost:44319/api/1'
//var userpool = 'eu-west-2_DdR68mbed'
//var id = '4hinoifq4mrs73k46j5tfhvqqd'
//var returnurl = 'http://localhost:4200/'
//var awsdomain = 'authv2.seriousincidentmanager.com'

////stage
//var production = false;
//var api = 'https://devapi.simuk-dev.uk/api/1'
//var userpool = 'eu-west-2_DdR68mbed'
//var id = '4hinoifq4mrs73k46j5tfhvqqd'
//var returnurl = 'https://devapp.simuk-dev.uk/'
//var awsdomain = 'authv2.seriousincidentmanager.com'

//dev
//var production = false;
//var api = 'https://localhost:44319/api/1'
//var userpool = 'eu-west-2_c5C05AGi0'
//var id = '34rvlp75u8gebfere0p99u6ij2'
//var returnurl = 'http://localhost:4200/'
//var awsdomain = 'authv2dev.seriousincidentmanager.com'

var auth = 'https://' + awsdomain + '/login?client_id=' + id + '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=' + returnurl + 'account/login'

export const environment = {
  production: production,
  api_url: api, 

  cognito: {
    userPoolId: userpool,
    userPoolWebClientId: id,
    AWSCognitoSignInURL: returnurl + "account/login",
    AWSCognitoSignOut: returnurl + "account/logout",
    AWSDomain: awsdomain,
    AWSProjectRegion: "eu-west-2",
    AWSCognitoRegion: "eu-west-2",
    AWSAppsyncAuthenticationType: "AMAZON_COGNITO_USER_POOLS",
    Scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    ResponseType: "code",
    Options: {
      AdvancedSecurityDataCollectionFlag: false
    },
    awsLoginUrl:
      auth
  },

  PCR_help_URL: "https://help.seriousincidentmanager.com/police-control-room",
  PCR_help_BCU_dashboard_URL: "https://help.seriousincidentmanager.com/police-control-room/dashboard/",
  PCR_help_BCU_IIR_URL: "https://help.seriousincidentmanager.com/police-control-room/initial-incident-form/",
  PCR_help_BCU_Contacts_URL: "https://help.seriousincidentmanager.com/police-control-room/contacts/",
  PCR_help_LA_dashboard_URL: "https://help.seriousincidentmanager.com/local-authorities/",
};
