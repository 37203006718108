 <div class="team-protocol-section py-50 local-auth-17">
  <div class="container">
    <div class="addnote-section min-width-for-overlay" *ngIf="isNotesLoaded">
      <div class="t_h2">
        <!-- <h2>Notes for <strong>Gang related violence <br> Share partner
            information (police, VRP etc)</strong></h2> -->
            <h2>Notes for <strong>{{incidentTitle}}</strong></h2>
      </div>
      <div class="contains">
        <form [formGroup]="notesForm" (ngSubmit)="onSubmit()">
          <div class="hs-field">
            <textarea class="form-control" formControlName="noteToBeAdd" placeholder="Add your action note"></textarea>
            <span class="text-danger"
              *ngIf="(noteToBeAdd.touched || notesForm.submitted) &&
              noteToBeAdd.hasError('required')">
              Note is required
            </span>
          </div>
          <div class="hs-submit text-end">
            <input type="submit" name="addNote" [disabled]="notesForm.invalid" value="Add note">
          </div>
        </form>
        <h3 *ngIf="noteData.length > 0">Log</h3>
        <div class="not-box" *ngFor="let note of noteData">
          <p class="date">Sent on {{note.date | date:'HH:mm dd/MM/yy'}}</p>
          <div class="para">
            <p>{{note.message}}</p>
          </div>
        </div>
        <!-- <div class="not-box">
          <p class="date">Sent on 22:30 28/07/22</p>
          <div class="para">
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
              diam nonummy nibh euismod tincidunt ut laoreet dolore magna
              aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              nostrud exerci tation ullamcorper suscipit lobortis nisl ut
              aliquip ex ea commodo consequat. </p>
          </div>
        </div>
        <div class="not-box">
          <p class="date">Sent on 22:30 28/07/22</p>
          <div class="para">
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
              diam nonummy nibh euismod tincidunt ut laoreet dolore magna
              aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              nostrud exerci tation ullamcorper suscipit lobortis nisl ut
              aliquip ex ea commodo consequat. </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>