import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { TeamMember } from 'src/app/Models/Team-member/TeamMemberDTO';

@Injectable({
  providedIn: 'root'
})
export class AddTeamMemberService {

  teamMemberSubject = new Subject<[TeamMember, boolean]>();

  constructor(
    private _apiService: ApiService
  ) { }

  AddTeamMember(teamember : TeamMember) {
    const url = `/la/TeamMember/Save`;
    return this._apiService.post(url, teamember).pipe(
      map((data) => {
        return data;
      })
    );
  }

  EditTeamMember(teamember : TeamMember) {
    const url = `/la/TeamMember/update`;
    return this._apiService.put(url, teamember).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetCategories() {
    const url = `/la/TeamMember/Categories`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
