import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class CstProtocolService {

  constructor(private _apiService: ApiService) { }

  getPostProtocolActions(){
    const url = "/La/Incident/Tasks";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getTeamMembers(){
    const url = "/La/TeamMembers";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  UpdateBulkStatuses(taskMemberId: string, statusId: number){
    const url = `/bcu/UpdateMemberTaskStatus/${taskMemberId}/${statusId}`;
    return this._apiService.post(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AssignTaskToTeamMember(incidentId: string, taskId: number, teamMemberProfileId: string){
    // const payLoad = {
    //   incidentId: incidentId,
    //   taskId: taskId,
    //   teamMemberProfileId: teamMemberProfileId
    // };

    const url = `/Manager/Incidents/AssignTaskToTeamMember?incidentId=${incidentId}&taskId=${taskId}&teamMemberProfileId=${teamMemberProfileId}`;
    return this._apiService.post(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetCSTPlanningNotes(taskId: number){
    const url = `/La/Notes/${taskId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // getStatusData(status: number): {classname: string, statusName: string}{
  //   let result: {classname: string, statusName: string};
  //   switch(status) { 
  //     case 1: {                                 // Active
  //       result = {classname : "orange", statusName : "Accepted"};
  //       // result = {classname : "dark-green", statusName : "Done"};
  //       break; 
  //     }     
  //     case 2: {                                  // Ongoing
  //       result = {classname : "green", statusName : "Ongoing"};     
  //       break; 
  //     } 
  //     case 3: {                                  // Pending
  //       result = {classname : "", statusName : "Pending"};
  //       break; 
  //     } 
  //     case 4: {                                  // Done
  //       result = {classname : "dark-green", statusName : "Done"};
  //       // result = {classname : "orange", statusName : "Active"};
  //       break; 
  //     } 
  //     default: { 
  //       result = {classname : "", statusName : "Pending"};
  //       break; 
  //     } 
  //  } 
  //  return result;
  // }

  getStatusData(status: number): {classname: string, statusName: string}{
    let result: {classname: string, statusName: string};
    switch(status) { 
      case 1: {                                 // Accepted
        result = {classname : "orange", statusName : "Accepted"};
        // result = {classname : "dark-green", statusName : "Done"};
        break; 
      }     
      case 2: {                                  // Declined
        result = {classname : "dark-pink", statusName : "Declined"};
        break; 
      } 
      case 3: {                                  // Pending
        result = {classname : "", statusName : "Pending"};
        break; 
      } 
      case 4: {                                  // Complete
        result = {classname : "dark-green", statusName : "Complete"};
        // result = {classname : "orange", statusName : "Active"};
        break; 
      } 
      case 5: {                                  // Not Required
        result = {classname : "notRequiredCST", statusName : "Not Required"};
        break; 
      } 
      case 6: {                                  // Not Required
        result = {classname : "ongoing", statusName : "Ongoing"};
        break; 
      }
      default: { 
        result = {classname : "", statusName : "Pending"};
        break; 
      } 
   } 
   return result;
  }


  // getStatusNumber(status: string): number{
  //   let result: number;
  //   switch(status) { 
  //     case "Accepted": {                                 // Done
  //       result = 1;
  //       break; 
  //     }     
  //     case "Ongoing": {                                  // Ongoing
  //       result = 2;
  //       break; 
  //     } 
  //     case "Pending": {                                  // Pending
  //       result = 3;
  //       break; 
  //     } 
  //     case "Done": {                                  // Active
  //       result = 4;
  //       break; 
  //     } 
  //     default: { 
  //       result = 3;
  //       break; 
  //     } 
  //  } 
  //  return result;
  // }

  // GetBackgroundColorForTd(status: number): string{
  //   let result: string;
  //   switch(status) { 
  //     case 1: {                                 // Done
  //       result = '#ef7b33';
  //       break; 
  //     }     
  //     case 2: {                                  // Ongoing
  //       result = '#a0bd72';
  //       break; 
  //     } 
  //     case 3: {                                  // Pending
  //       result = '#80778c';
  //       break; 
  //     } 
  //     case 4: {                                  // Active
  //       result = '#21b14b';
  //       break; 
  //     } 
  //     default: { 
  //       result = '#fff';
  //       break; 
  //     } 
  //  } 
  //  return result;
  // }

  
        // Accepted = 1,
        // Declined = 2,
        // Pending = 3,
        // Complete = 4,
        // Not Required = 5,
        // OnGoing = 6


  getStatusNumber(status: string): number{
    let result: number;
    switch(status) { 
      case "Accepted": {                                 // Accepted
        result = 1;
        break; 
      }     
      case "Declined": {                                  // Declined
        result = 2;
        break; 
      } 
      case "Pending": {                                  // Pending
        result = 3;
        break; 
      } 
      case "Complete": {                                  // Complete
        result = 4;
        break; 
      } 
      case "Not Required": {                             // Not Required
        result = 5;
        break; 
      } 
      case "Ongoing": {                                  // OnGoing
        result = 6;
        break; 
      }
      default: { 
        result = 3;
        break; 
      } 
   } 
   return result;
  }

  GetBackgroundColorForTd(status: number): string{
    let result: string;
    switch(status) { 
      case 1: {                                 // Accepted
        result = '#ef7b33';
        break; 
      }     
      case 2: {                                  // Declined  e91f5f
        result = '#e91f5f';
        break; 
      } 
      case 3: {                                  // Pending a0bd72
        result = '#80778d';
        break; 
      } 
      case 4: {                                  // Complete
        result = '#21b14b';
        break; 
      } 
      case 5: {                                  // Not Required  80778d
        result = '#A7A7A7';
        break; 
      } 
      case 6: {                                  // Not Required  80778d
        result = '#a0bd72';
        break; 
      }
      default: { 
        result = '#fff';
        break; 
      } 
   } 
   return result;
  }

  GetTimeFrame(index: number): string {
    let result = "";
    switch (index) {
      case 1:
        result = "24 hours";
        break;
      case 2:
        result = "1 week";
        break;
      case 3:
        result = "1 month";
        break;
      case 4:
        result = "On Going";
        break;
      default:
        result = "";
    }
    return result;
  }

  GetTimeFrameNumber(timeFrame: string): number{
    let result;
    switch (timeFrame) {
      case "24 hours":
        result = 1;
        break;
      case "1 week":
        result = 2;
        break;
      case "1 month":
        result = 3;
        break;
      case "On Going":
        result = 4;
        break;
      default:
        result = 1;
    }
    return result;
  }

  GetIssueBoolean(selectedIssue: string): boolean | null{
    let result;
    switch (selectedIssue) {
      case "All":
        result = null;
        break;
      case "With":
        result = true;
        break;
      case "Without":
        result = false;
        break;
      default:
        result = null;
    }
    return result;
  }
}
