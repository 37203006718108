import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UrlHelperService } from './url-helper.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { CognitoService } from 'src/app/Authentication/cognito.service';
import { UserProfileService } from './user-profile.service';
import { MemberProfileDetailsDTO } from 'src/app/Models/Messages/MessageTemplateDTO';
import { StringHelperService } from './string-helper.service';
import { ApiErrorService } from './api-error.service';

@Injectable({
  providedIn: 'root'
})
export class AppStartService {

  currentUrlChanged = new BehaviorSubject<string>(this.currentUrl);
  currentUserChanged = new Subject<MemberProfileDetailsDTO>();
  
  //#region Getter and Setters
  private _currentUrl: string;

  get currentUrl(): string {
    return this._currentUrl;
  }

  set currentUrl(value: string) {
    this._currentUrl = value;
    this.currentUrlChanged.next(value);
  }

  private _currentUser: MemberProfileDetailsDTO;

  get currentUser(): MemberProfileDetailsDTO {
    return this._currentUser;
  }

  set currentUser(value: MemberProfileDetailsDTO) {
    this._currentUser = value;
    this.currentUserChanged.next(value);
  }
  //#endregion

  currentCognitoUser: any;

  shouldNavShow = true;
  shouldFooterShow = true;
  IsUserLoaded = false;
  IsCognitoUserLoaded = false;
  isPreloaderShow = true;
  isErrorWhileLoadingUser = false;

  constructor(
    private _router: Router,
    private _urlHelperService: UrlHelperService,
    private _cognitoService: CognitoService,
    private _userProfileService: UserProfileService,
    private _stringHelperService: StringHelperService,
    private _apiErrorService: ApiErrorService,
    ) { }



  async StartApp(){
    await this.ListenRoute();
  }

  async ListenRoute(){
    this._router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
        this.ShouldNavShow();
        this.ShouldFooterShow();
        if (this.ShouldLoadUser()){
          await this.LoadUser();
        }
        else{
          this.isPreloaderShow = false;
        }
      }
    });
  }

  ShouldLoadUser(){
    return !this._urlHelperService.IsSignupUrl(this.currentUrl) && (!this.IsCognitoUserLoaded || !this.IsUserLoaded);
  }

  async LoadUser(){
    // if (this.shouldNavShow && this.shouldFooterShow){
    this._cognitoService.GetUser()
    .then(async (user) => {
      if (!user || this._stringHelperService.IsNullOrWhiteSpace(user.username)){
        await this._cognitoService.SignOut();
      }

      const roleId = user.attributes['custom:roles'];
      if (!roleId){
        await this._cognitoService.SignOut();
      }
      this.IsCognitoUserLoaded = true;
      this.currentCognitoUser = user;

      this._userProfileService.GetCurrentUserProfileDetails().subscribe((response) => {
        if (response && response.data && response.statusCode == 200){
          this.currentUser = response.data as  MemberProfileDetailsDTO;
          this._userProfileService.SetAuthenticatedUserGroups(this._userProfileService.CreateAuthenticatedUserGroupsInstance(this.currentUser));
          this._userProfileService.UserLoadedSubject.next(true);
          this.IsUserLoaded = true;
          this.isPreloaderShow = false;
        }
      }, 
      (error) => {
        this.isErrorWhileLoadingUser = true;
        this._apiErrorService.ShowError(error);
        setTimeout(async () => {
          await this._cognitoService.SignOut();
        }, 3000);
      })
    })
    .catch(async (err) => {
      await this._cognitoService.SignOut();
    });
  }

  

  ShouldNavShow(){
    if (this._urlHelperService.IsSignupUrl(this.currentUrl) || this._urlHelperService.IsChangePasswordUrl(this.currentUrl)){
      this.shouldNavShow = false;
    }
    else{
      this.shouldNavShow = true;
    }
  }

  ShouldFooterShow(){
    if (this._urlHelperService.IsSignupUrl(this.currentUrl) || this._urlHelperService.IsChangePasswordUrl(this.currentUrl)){
      this.shouldFooterShow = false;
    }
    else{
      this.shouldFooterShow = true;
    }
  }

  async NavigateToDashboard(){
    try{
      let roleId = '';
      if (!this.IsCognitoUserLoaded){
        const user = await this._cognitoService.GetUser();

        if (!user || this._stringHelperService.IsNullOrWhiteSpace(user.username)){
          await this._cognitoService.SignOut();
        }

        roleId = user.attributes['custom:roles']
        if (this._stringHelperService.IsNullOrWhiteSpace(roleId)){
          await this._cognitoService.SignOut();
        }
      }
      else{
        roleId = this.currentCognitoUser.attributes['custom:roles'];
        if (this._stringHelperService.IsNullOrWhiteSpace(roleId)){
          await this._cognitoService.SignOut();
        }
      }

      if (this._cognitoService.IsLaUser(roleId)){
        this._router.navigate([this._urlHelperService.LaUserDashboardUrl()]);
      }
  
      if (this._cognitoService.IsBCUUser(roleId)){
        this._router.navigate([this._urlHelperService.BcuUserDashboardUrl()]);
      }
    }
    catch(err){
      console.error(err);
      await this._cognitoService.SignOut();
    }
  }
}
