<!-- <app-home-nav-menu [componentName]="'team-members'"></app-home-nav-menu> -->

<div class="py-50 message-form member_add">
  <div class="container">
    <div class="accordion tableaccordian" id="addmember" *ngIf="isstakeHoldersCategoryLoaded">
      <div class="accordion-item">
        <h2 class="accordion-header" id="addmemberheading1">
          <!-- <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#addmember1"
            aria-expanded="true" aria-controls="addmember1">
            Add team member 
          </button> -->
          <div class="accordion-button" type="button">
            Add Stakeholder
          </div>
        </h2>
        <form [formGroup]="stakeholderForm" (submit)="onSubmit()">
          <div id="addmember1" class="accordion-collapse collapse show" aria-labelledby="addmemberheading11"
          data-bs-parent="#addmember">
            <div class="accordion-body">
              <div class="form-style">
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input select">
                          <img class="hs_icon" src="assets/images/user.png" alt="user">
                          <select name="AllTeamMembers" formControlName="category" id="teammembers">
                            <option [ngValue]="null">Select Category</option>
                            <option [ngValue]="category.id" *ngFor="let category of stakeHoldersCategory">{{category.label}}</option>
                            <!-- <option ngValue="GroupAdmin">Admin</option>
                            <option ngValue="GroupMember">Team Member</option>
                            <option ngValue="GroupCommunicator">Team member Communicator</option> -->

                            <!-- <ng-container *ngFor="let option of options">
                              <option [value]="option">{{ option }}</option>
                            </ng-container> -->
                            <!-- <option value="role1">role1</option>
                            <option value="role1">role1</option> -->
                          </select>
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.category.touched) && stakeholderForm.controls.category.hasError('required')">
                              Categories is required</span>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input select">
                          <!-- <img class="hs_icon" src="assets/images/user.png" alt="user">
                          <select name="AllTeamMembers" id="teammembers">
                            <option value="Select team member role">Select team member role</option>
                            <option value="role1">role1</option>
                            <option value="role1">role1</option>
                          </select> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="firstName" placeholder="First name"
                            formControlName="firstName">
                          
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.firstName.touched) && stakeholderForm.controls.firstName.hasError('required')">
                              First name is required</span>
                      </div>
                      
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="givenName" placeholder="Given name" 
                            formControlName="givenName">
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.givenName.touched) && stakeholderForm.controls.givenName.hasError('required')">
                              Given name is required</span>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-rol.png" alt="user-rol">
                          <input class="form-control" type="text" id="jobTitle" placeholder="jobTitle"
                            formControlName="jobTitle">       <!--formControlName="role"-->
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.jobTitle.touched) && stakeholderForm.controls.jobTitle.hasError('required')">
                              jobTitle is required</span>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-id.png" alt="user-id">
                          <input class="form-control" type="text" id="employer" placeholder="Employer"
                           formControlName="employer">
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.employer.touched) && stakeholderForm.controls.employer.hasError('required')">
                              Employer is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/Local_Authority_blue.png" alt="Local_Authority_blue">
                          <input class="form-control" type="tel" id="phone" placeholder="Mobile" 
                            formControlName="phone">
                        </div>
                       
                            <div class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.phone.touched) && stakeholderForm.controls.phone.hasError('required')">
                              Mobile is required</div>

                            <div class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.phone.touched) && stakeholderForm.controls.phone.hasError('pattern')">
                              Invalid mobile number (formate example: '+1234567890')</div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon">
                          <input class="form-control" type="mail" id="Email" placeholder="Email"
                            formControlName="email">
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.email.touched) && stakeholderForm.controls.email.hasError('required')">
                              Email is required</span>
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.email.touched) && stakeholderForm.controls.email.hasError('email')">
                              Invalid Email id</span>
                      </div>
                      
                    </div>
                  </div>
                </div>


                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <!-- <img class="hs_icon" src="./assets/images/Local_Authority_blue.png" alt="Local_Authority_blue">
                          <input class="form-control" type="tel" id="phone" placeholder="Mobile" 
                            formControlName="phone"> -->
                        </div>
                       
                            <!-- <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.phone.touched) && stakeholderForm.controls.phone.hasError('required')">
                              Mobile is required</span>
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              stakeholderForm.controls.phone.touched) && stakeholderForm.controls.phone.hasError('pattern')">
                              Invalid mobile number (formate example: xxx-xxx-xxxx)</span> -->
                      </div>
                      
                      <div class="col-12 col-sm-6">
                        <!-- <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon">
                          <input class="form-control" type="mail" id="Email" placeholder="Email"
                            formControlName="email">
                        </div> -->
                        
                        <div class="col-12 d-flex align-items-end justify-content-end">
                          <div class="hs-submit p-0">
                            <button type="submit" class="primary-btn">Save</button> 
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center" *ngIf="!isstakeHoldersCategoryLoaded">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

