import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CognitoService } from '../cognito.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  errorMessage: string = '';
  isSubmitted: boolean = false;

  constructor(
    private _cognitoService: CognitoService,
    private _appStartService: AppStartService
  ) {}


  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), this.noSpaces.bind(this)])
    });
  }

  private noSpaces(control: FormControl) {
    const value = control.value?.trim();
    const isValid = value === control.value;
    return isValid ? null : { containsSpaces: true };
  }

  async onSubmit() {
    try {
      this.errorMessage = '';
      const { oldPassword, newPassword } = this.changePasswordForm.value;
      let currentUser: any;
      if (!this._appStartService.IsCognitoUserLoaded){
        currentUser = await this._cognitoService.GetUser();
      }
      else{
        currentUser = this._appStartService.currentCognitoUser;
      }
      var resposne = await this._cognitoService.ChangePassword(currentUser, oldPassword, newPassword);
      if (resposne === "SUCCESS"){
        await this._appStartService.NavigateToDashboard();
      }
      // add success message and redirect to another page
    } catch (error) {
      this.errorMessage = error.message
      // add error message to form
    }
  }

}
