<!-- <div class="py-50 message-form member_add">
  <div class="container">
    <div class="accordion tableaccordian" id="addmember">
      <div class="accordion-item">
        <h2 class="accordion-header" id="addmemberheading1">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#addmember1"
            aria-expanded="true" aria-controls="addmember1">
            Add stakeholder member
          </button>
        </h2>
        <form [formGroup]="stakeHolderMemberForm" (submit)="onSubmit()">
          <div id="addmember1" class="accordion-collapse collapse show" aria-labelledby="addmemberheading11"
          data-bs-parent="#addmember">
            <div class="accordion-body">
              <div class="form-style">
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input select">
                          <img class="hs_icon" src="assets/images/user.png" alt="user">
                          <select name="AllTeamMembers" formControlName="role" id="teammembers">
                            <option value="">Select stakeholder member role</option>
                            <option value="role1">role1</option>
                            <option value="role1">role1</option>
                          </select>
                        </div>
                        <div *ngIf="(isSubmitted ||
                        stakeHolderMemberForm.controls.role.touched)">
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.role.hasError('required')">
                              Role is required</span>
                          </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input select">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="firstName" placeholder="First name"
                            formControlName="firstName">
                          
                        </div>
                        <div *ngIf="(isSubmitted ||
                        stakeHolderMemberForm.controls.firstName.touched)">
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.firstName.hasError('required')">
                              First name is required</span>
                          </div>
                      </div>
                      
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="givenName" placeholder="Given name" 
                            formControlName="givenName">
                        </div>
                        <div *ngIf="(isSubmitted ||
                        stakeHolderMemberForm.controls.firstName.touched)">
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.firstName.hasError('required')">
                              Given name is required</span>
                          </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-rol.png" alt="user-rol">
                          <input class="form-control" type="text" id="role" placeholder="Role"
                            formControlName="role">
                        </div>
                        <div *ngIf="(isSubmitted ||
                        stakeHolderMemberForm.controls.role.touched)">
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.role.hasError('required')">
                              Role is required</span>
                          </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-id.png" alt="user-id">
                          <input class="form-control" type="text" id="employer" placeholder="Employer"
                           formControlName="employer">
                        </div>
                        <div *ngIf="(isSubmitted ||
                        stakeHolderMemberForm.controls.employer.touched)">
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.employer.hasError('required')">
                              Employer is required</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/Local_Authority_blue.png" alt="Local_Authority_blue">
                          <input class="form-control" type="tel" id="phone" placeholder="Mobile" 
                            formControlName="phone">
                        </div>
                        <div *ngIf="(isSubmitted ||
                        stakeHolderMemberForm.controls.phone.touched)">
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.phone.hasError('required')">
                              Mobile is required</span>
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.phone.hasError('pattern')">
                              Invalid mobile number (formate example: xxx-xxx-xxxx)</span>
                          </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon">
                          <input class="form-control" type="mail" id="Email" placeholder="Email"
                            formControlName="email">
                        </div>
                        <div *ngIf="(isSubmitted || stakeHolderMemberForm.controls.email.touched)">
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.email.hasError('required')">
                              Email is required</span>
                            <span class="text-danger"
                              *ngIf="stakeHolderMemberForm.controls.email.hasError('email')">
                              Invalid Email id</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->


<!-- <div class="py-50 pt_sm_0 message-form member_add">
  <div class="container">
    <div class="accordion tableaccordian" id="editmember">
      <div class="accordion-item">
        <h2 class="accordion-header" id="editmemberheading1">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#editmember1"
            aria-expanded="true" aria-controls="editmember1">
            Edit stakeholder member
          </button>
        </h2>
        <div id="editmember1" class="accordion-collapse collapse show" aria-labelledby="editmemberheading11"
          data-bs-parent="#editmember">
          <div class="accordion-body">
            <div class="form-style">
              <div class="fieldset">
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div class="hs_icon_input select">
                        <img class="hs_icon" src="assets/images/user.png" alt="user">
                        <select name="AllTeamMembers" id="teammembers">
                          <option value="Select team member role">Select stakeholder member role</option>
                          <option value="role1">role1</option>
                          <option value="role1">role1</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="hs_icon_input select">
                        <img class="hs_icon" src="assets/images/user.png" alt="user">
                        <select name="AllTeamMembers" id="teammembers">
                          <option value="Select team member role">Select stakeholder member role</option>
                          <option value="role1">role1</option>
                          <option value="role1">role1</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/user.png" alt="user">
                        <input class="form-control" type="text" id="firstName" name="firstName" placeholder="First name" required="">
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/user.png" alt="user">
                        <input class="form-control" type="text" id="givenName" name="givenName" placeholder="Given name" required="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/user-rol.png" alt="user-rol">
                        <input class="form-control" type="text" id="role" name="Role" placeholder="Role" required="">
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/user-id.png" alt="user-id">
                        <input class="form-control" type="text" id="employer" name="Employer" placeholder="Employer" required="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/Local_Authority_blue.png" alt="Local_Authority_blue">
                        <input class="form-control" type="tel" id="phone" name="phone" placeholder="mobile" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon">
                        <input class="form-control" type="mail" id="Email" name="Email" placeholder="Email" required="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/Local_Authority_blue.png" alt="Local_Authority_blue">
                        <input class="form-control" type="tel" id="phone" name="phone" placeholder="Mobile" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 d-flex align-items-end justify-content-end">
                      <div class="hs-submit p-0">
                        <button type="button" class="primary-btn">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 d-flex align-items-end justify-content-end">
                <div class="hs-submit p-0">
                  <button type="button" class="primary-btn">Save</button>
                </div>
              </div>
              <div class="fieldset">
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div class="hs_icon_input">
                        <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon">
                        <input class="form-control" type="tel" id="alertpreferences" name="alertPreferences" placeholder="Alert preferences" required>
                      </div>
                      <div class="checkbox-cross">
                        <div class="checkbox_each">
                          <input type="checkbox" name="rollover" id="rollover">
                          <label for="rollover">(rollover)</label>
                        </div>
                        <div class="checkbox_each">
                          <input type="checkbox" name="rollover" id="rollover" checked>
                          <label for="rollover">Police initial incident report</label>
                        </div>
                        <div class="checkbox_each">
                          <input type="checkbox" name="rollover" id="rollover" checked>
                          <label for="rollover">Location</label>
                        </div>
                        <div class="checkbox_each">
                          <input type="checkbox" name="rollover" id="rollover">
                          <label for="rollover">Partner Message</label>
                        </div>
                        <div class="checkbox_each">
                          <input type="checkbox" name="rollover" id="rollover">
                          <label for="rollover">Section 60</label>
                        </div>
                        <div class="checkbox_each">
                          <input type="checkbox" name="rollover" id="rollover">
                          <label for="rollover">Section 35</label>
                        </div>
                        <div class="checkbox_each">
                          <input type="checkbox" name="rollover" id="rollover">
                          <label for="rollover">Protocol launch</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 d-flex align-items-end justify-content-end">
                      <div class="hs-submit p-0">
                        <button type="button" class="primary-btn">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<app-home-nav-menu></app-home-nav-menu>

<div class="team-protocol-section pt-15 local-auth-14 second-incident-sec">
  <div class="container cst-protocol Team_protocol_action">

    <h2 class="sec-title">Stakeholders</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>

    <div class="w-100">
      <div class="accordion tableaccordian  " id="contactaccordian" *ngIf="isTeamMembersLoaded">
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord1" aria-expanded="true" aria-controls="contactaccord1">
              <!-- Gang Violence <span>Outside 218 Francis Road E10, 22:30, April 10</span> -->

            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="text-start">First name</th>
                      <th class="text-start">Given name</th>
                      <th class="text-start">Job title</th>
                      <th class="text-start">Organisation</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let stakeholder of stakeholders">
                      <td>
                        <div class="team-member-name">
                          <img [src]="(stakeholder.avatar != null && stakeholder.avatar != undifined) ? stakeholder.avatar : './assets/images/rd-icon.png'" alt="rd-icon">
                          <span>{{stakeholder.firstName}}</span>
                        </div>
                      </td>
                      <td>{{stakeholder.familyName}}</td>
                      <td>{{stakeholder.jobTitle}}</td>
                      <td>{{stakeholder.employer}}</td>
                      <td class="email-link">
                        <a [href]="'mailto:' + stakeholder.email" *ngIf="stakeholder.email != null && stakeholder.email != undefined && stakeholder.email != ''">
                          <img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new">
                        </a>
                        <img *ngIf="stakeholder.email == null || stakeholder.email == undefined || stakeholder.email == ''" 
                          class="message" src="./assets/images/mail-icon-unable.png" alt="">
                      </td>
                      <!-- <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td> -->
                      <td class="phone-link">
                        <a [href]="'tel:' + stakeholder.phoneNo" *ngIf="stakeholder.phoneNo != null && stakeholder.phoneNo != undefined && stakeholder.phoneNo != ''">
                          <img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new">
                        </a>
                        <img *ngIf="stakeholder.phoneNo == null || stakeholder.phoneNo == undefined || stakeholder.phoneNo == ''" 
                          class="message" src="./assets/images/phone-icon-unable.png" alt="
                          ">
                      </td>
                      <!-- <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td> -->
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><span (click)="stackHolderEditClicked(stakeholder)" class="test showHand" href="#">Edit</span></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<div class="divloader" *ngIf="!isTeamMembersLoaded">
  <mat-spinner diameter="50"></mat-spinner>
</div>





<!-- <div class="team-protocol-section py-50 local-auth-14 second-incident-sec">
  <div class="container cst-protocol Team_protocol_action">
    <div class="w-100">
      <div class="accordion tableaccordian  " id="contactaccordian">
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord1" aria-expanded="true" aria-controls="contactaccord1">

            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="text-start">First name</th>
                      <th class="text-start">Given name</th>
                      <th>Role</th>
                      <th>Organisation</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td class="text-start">Communication</td>
                      <td class="text-start">Red thread</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a class="test" href="#">Edit</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div> -->