import { ToastrService } from 'ngx-toastr';
import { TeamMessagingDashboardService } from "./team-messaging-dashboard.service";
import { IncidentMessageSendComponent } from "../incident-message-send/incident-message-send.component";
import { IncidentMessageComponent } from "../../overlays/incident-message/incident-message.component";
import { LoadScriptService } from "../../home-dashboard/load-script.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { LATeamMessage, LATeamMessagingDashboard } from "../../Models/la-Team-messages/LATeamMessagingDashboard";
import { tap } from "rxjs/operators";
import { ErrorConfirmationBoxComponent } from 'src/app/overlays/error-confirmation-box/error-confirmation-box.component';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';

@Component({
  selector: "app-team-messaging-dashboard",
  templateUrl: "./team-messaging-dashboard.component.html",
  styleUrls: ["./team-messaging-dashboard.component.css"],
})
export class TeamMessagingDashboardComponent implements OnInit, OnDestroy {
  teamMessagesIncedents: LATeamMessagingDashboard[] = [];
  isLoading = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _loadScriptService: LoadScriptService,
    private _teamMessagingDashboardService: TeamMessagingDashboardService,
    private _dialog: MatDialog,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    public _urlHelperService: UrlHelperService
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    this._loadScriptService.LoadScript("team-messaging-dashboardScriptTag");

    this.GetTeamMessagingDashboard();
  }

  GetTeamMessagingDashboard(){
    this._teamMessagingDashboardService.GetTeamMessagingDashboard().pipe(tap(() => this.isLoading = false)).subscribe(
      (response) => {
        this.teamMessagesIncedents = this._teamMessagingDashboardService.SortIncidents(response.data);
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      }
    );
  }


  IncidentMessageClicked(message: LATeamMessage) {
    this._dialog.open(IncidentMessageComponent, {
      data: { messageInfo: message }
    });
  }

  SendMessageClicked(messageType: string, incidentId: string) {
    if (!messageType || !incidentId){
      return;
    }

    let messageData = { messageType : messageType, incidentId : incidentId }
    this._dialog.open(IncidentMessageSendComponent, {
      minHeight: '100px',
      minWidth : '100px',
      data: { messageData: messageData }
    })
    .afterClosed().subscribe(result => {
      if (!result){
        return;
      }

      this.isLoading = true;

      const messageQueueDTO = result;

      this._dialog.open(ErrorConfirmationBoxComponent, {
        width: 'auto',
        panelClass: 'custom-dialog-container',
        data: { tital: "Message sent", body: "Your message has been sent" }
      });

      const teamMessageIncidentIndex = this.teamMessagesIncedents.findIndex(x => x.incidentId == incidentId);
      if (teamMessageIncidentIndex != -1){
        let laTeamMessage = new LATeamMessage();
        laTeamMessage.content = messageQueueDTO.message;
        laTeamMessage.from = messageQueueDTO.fromInfo;
        laTeamMessage.sent = new Date();
        laTeamMessage.subject = messageQueueDTO.subject;
        laTeamMessage.to = messageQueueDTO.toInfo;
        laTeamMessage.type = messageQueueDTO.deliveryChannel?.toString();
        this.teamMessagesIncedents[teamMessageIncidentIndex].messages.push(laTeamMessage);
      }

      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this._loadScriptService.RemoveScript("team-messaging-dashboardScriptTag");
  }
}