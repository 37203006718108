import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private _apiService: ApiService) { }
  getDashBoard(bcuRegion) {
    const url = "/BCU/GetDashboard/"+bcuRegion;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  
}
