import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Amplify } from 'aws-amplify';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const oauth = {
  domain: environment.cognito.AWSDomain,
  userPoolId : environment.cognito.userPoolId,
  userPoolWebClientId: environment.cognito.userPoolWebClientId,
  scope: environment.cognito.Scope,
  redirectSignIn: environment.cognito.AWSCognitoSignInURL,
  redirectSignOut: environment.cognito.AWSCognitoSignOut,
  responseType: environment.cognito.ResponseType,
  options: environment.cognito.Options
}

const awsmobile = {
  aws_project_region: environment.cognito.AWSProjectRegion,
  aws_cognito_region: environment.cognito.AWSCognitoRegion,
  aws_user_pools_id: environment.cognito.userPoolId,
  aws_user_pools_web_client_id: environment.cognito.userPoolWebClientId,
  aws_appsync_authenticationType: environment.cognito.AWSAppsyncAuthenticationType,
  oauth: oauth,
};

Amplify.configure(awsmobile);

if (environment.production) {
  enableProdMode();
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
