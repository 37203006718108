import { IncidentStatusEnum } from "src/app/Enum/IncidentStatus.enum";
import { ProtocolStatus } from "src/app/Enum/ProtocolStatus.enum";
import { ResponseStatus } from "src/app/Enum/ResponseStatus.enum";

export class LAIncidentDashboardModel {
  declinedTotal: number;
  incidents: LAIncedentsModel[];
  pendingTotal: number;
  protocolInProgressTotal: number;
  responseInProgressTotal: number;
}

export class LAIncedentsModel{
  datetime : Date;
  id: string;
  location: string;
  incidentStatus: IncidentStatusEnum;
  protocolStatus: ProtocolStatus;
  responseStatus: ResponseStatus;
  title: string;
}

export class LaDashboardUserActionsModel{
  
  acceptedTotal: number;
  issuesTotal : number;
  lateTotal: number;
  pendingTotal: number;
  incidentTotals : LAIncedentsUserActionsModel[];
}

export class LAIncedentsUserActionsModel{
  accepted: number;
  id: string;
  issues : number;
  dateTime : number;
  late: number;
  location: string;
  pending: number;
  title: string;
  emergencyResponseActive : number;
  emergencyResponsePending : number;
  protocolStatus : boolean;
  responseStatus : boolean;
  incidentStatus : number;
}

export class LAIncidentLaunchDTO{
  incidentId: string;
  incidentTitle: string;
  template: string;
  templateId: string;
  taskGroups: IncidentLaunchTask[] = [];

}

export class IncidentLaunchTask{
  id: string;
  incidentTaskAssignees: IncidentTaskAssignee[] = [];
  parentId: string;
  taskTimeFrame: number;
  tasks: IncidentLaunchTask[] = [];
  title: string;
}

export class IncidentTaskAssignee{
  assigneeId: string;
  assigneeName: string;
  avatar: string;
}


export class SaveProtocolLaunchDTO{
  incidentId: string;
  Items: SaveProtocolLaunchItemDTO[] = [];
  TemplateIdUsed: string;
}

export class SaveProtocolLaunchItemDTO{
  TaskId: number;
  MemberId: string;
  Timeframe: IncidentTaskTimeFrame;
}

export enum IncidentTaskTimeFrame{
  Within24Hours=1,
  Within1Week =2,
  Within1Month =3,
  OnGoing =4
}