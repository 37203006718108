<div class="footer">
    <div [class]="navmenuClass">
      <!-- <div class="foot-admin">
        <a href="#"><img src="../../assets/images/setting-icon.png" alt="setting-icon"> Admin</a>
      </div> -->
      <div class="foot-support">
        <span class="mx-2"><img class="margin-right-img" src="../../assets/images/person-icon.png" alt="person-icon"> Support</span>
        <a class="mail" href="mailto: support@seriousincidentmanager.com"> support@seriousincidentmanager.com</a>
        <a class="tel" href="tel:0207 234 5678">t: 0208 064 1733</a>
      </div>
    </div>
  </div>