<div class="pt-4">
  <div class="container" *ngIf="isUserLoaded">
    <div class="select-wrapper">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-5">
          <div class="title-box d-flex">
            <div class="title" *ngIf="selectedRegion!=null">

              <h2 class="text-gradient">{{selectedRegion?.name}}</h2>
              <span>{{selectedRegion?.locationDescription}}</span>
              <!-- <h2 class="text-gradient">{{SelectedBCURegionDTO.name}}</h2>
              <span>{{SelectedBCURegionDTO.locationDescription}}</span> -->
            </div>
            <div class="dropdown">
              <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                aria-expanded="false">
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <li>Choose BCUs to display</li>
                <!-- <li class="form-check" *ngFor="let region of usersRegionsAndGroups.region;let i = index">
                  <input class="form-check-input" (change)="changeBUCRegion($event.target.checked,region)" type="checkbox"
                    [id]="region.regionId" name="{{region.regionId}}" [checked]="region.selected" [value]="region.selected">
                  <label class="form-check-label" [for]="region.regionId">{{region.regionName}}</label>
                </li> -->
                <!-- <li class="form-check" *ngFor="let item of BCURegionDTO;let i = index">
                  <input class="form-check-input" (change)="changeBUCRegion($event.target.checked,item)" type="checkbox"
                    [id]="item.id" name="{{item.id}}" [checked]="item.selected" [value]="item.selected">
                  <label class="form-check-label" [for]="item.id">{{item.name}}</label>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subselect-wrapper">
      <div class="row five-grid">
        <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
          <div class="option-card  bg_purple d-flex option-dropdown" [routerLinkActive]="['bg_gradient']">
            <a [routerLink]="['/initial-incident-form',0,'StartIncidentTab']"
              class="title-icon text-white d-flex flex-wrap flex-wrap">
              <img src="../../assets/images/plus-icon.png" alt="">
              <h3>New Incident</h3>
            </a>
            <div class="dropdown">
              <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown"
                aria-expanded="false"></button>
              <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                <li><a [routerLink]="['/initial-incident-form',0,'StartIncidentTab']">Incidents</a></li>
                <li><a href="#">Team</a></li>
                <li><a href="#">Stakeholder</a></li>
                <li><a href="#">Templates</a></li>
                <li><a href="#">Messaging</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
          <div class="option-card bg_purple" [routerLinkActive]="['bg_gradient']">
            <a [routerLink]="['/local-authority-actions-response']"
              class="title-icon text-white d-flex flex-wrap flex-wrap">
              <img src="../../assets/images/action-icon.png" alt="action-icon">
              <h3>Actions</h3>
              <div class="action-number">
                <div class="action-each">
                  <span class="number-circle">7</span>
                  Accept / decline
                </div>
                <div class="action-each">
                  <span class="number-circle">5</span>
                  Late
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
          <div class="option-card bg_purple" [routerLinkActive]="['bg_gradient']">
            <a href="https://app.seriousincidentmanager.com/stakeholder-messaging" target="_blank"
              class="title-icon text-white d-flex flex-wrap flex-wrap">
              <img src="../../assets/images/message-icon.png" alt="">
              <h3>Messaging</h3>
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
          <div class="option-card bg_purple" [routerLinkActive]="['bg_gradient']">
            <a [routerLink]="['/contacts']" class="title-icon text-white d-flex flex-wrap flex-wrap">
              <img src="../../assets/images/contact-icon.png" alt="">
              <h3>Contacts</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="table-responsive table-style local-dashboard-style">
    <table class="table table-hover table-bordered" *ngIf="!dashboardLoad">
      <thead>
        <tr>
          <th></th>
          <th>Incident</th>
          <th>Type</th>
          <th>Time Date</th>
          <th>CAD</th>
          <th>RVP</th>
          <th>Crime Scene</th>
          <th>Cordon</th>
          <th>Peolpe displaced?</th>
          <th>Partner messages</th>
          <th>Section 35</th>
          <th>Section 60</th>
          <th>Local Authority Protocol progress</th>
          <th>Actions</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="LADashboardDTO!=null && LADashboardDTO.length>0">
        <tr *ngFor="let incident of LADashboardDTO">
          <td><a class="edit" [routerLink]="['/initial-incident-form',incident.id,'StartIncidentTab']"><img
                src="../../assets/images/edit-icon.png" alt=""></a></td>
          <td>
            <a [routerLink]="['/BCU/incident-details',incident.id]">{{incident.title}}</a>
          </td>
          <td>{{getTypeOfIncident(incident.type)}}</td>
          <td>
            <span class="time">{{incident.datetime}}</span>
            <!-- <span class="date">28/7/22</span> -->
          </td>
          <td>{{incident.cad}}</td>
          <td>
            <a [routerLink]="['/initial-incident-form',incident.id,'LocationsTab']">
              <img *ngIf="incident.rvp" src="../../assets/images/true-icon.png" alt="" />
              <img *ngIf="!incident.rvp" src="../../assets/images/plus-circle-icon.png">
            </a>
          </td>
          <td>
            <a [routerLink]="['/initial-incident-form',incident.id,'LocationsTab']">
              <img *ngIf="incident.crimescene" src="../../assets/images/true-icon.png" alt="">
              <img *ngIf="!incident.crimescene" src="../../assets/images/plus-circle-icon.png">
            </a>
          </td>
          <td>
            <a [routerLink]="['/initial-incident-form',incident.id,'LocationsTab']">
              <img *ngIf="incident.cordon" src="../../assets/images/true-icon.png">
              <img *ngIf="!incident.cordon" src="../../assets/images/plus-circle-icon.png">
            </a>
          </td>
          <td>
            <a [routerLink]="['/initial-incident-form',incident.id,'StartIncidentTab']">
              <img *ngIf="incident.peopledisplaced" src="../../assets/images/true-icon.png">
              <img *ngIf="!incident.peopledisplaced" src="../../assets/images/plus-circle-icon.png">
            </a>
          </td>
          <td>
            <a [routerLink]="['/initial-incident-form',incident.id,'PartnerMessageTab']">
              <img *ngIf="incident.partnerMessage" src="../../assets/images/true-icon.png">
              <img *ngIf="!incident.partnerMessage" src="../../assets/images/plus-circle-icon.png">
            </a>
          </td>
          <td>
            <a [routerLink]="['/initial-incident-form',incident.id,'Section35RequestTab']">
              <div *ngIf="incident.section35>=0" class="progress hrs-progress">
                <div class="progress-bar bg_green" role="progressbar" [style.width.%]="incident.section35"
                  aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <img *ngIf="incident.section35<0" src="../../assets/images/dashed-round-icon.png">
            </a>
          </td>
          <td>
            <a [routerLink]="['/initial-incident-form',incident.id,'Section60RequestTab']">
              <div *ngIf="incident.section60>=0" class="progress hrs-progress">
                <div class="progress-bar bg_green" role="progressbar" [style.width.%]="incident.section60"
                  aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <img *ngIf="incident.section60<0" src="../../assets/images/dashed-round-icon.png">
            </a>
          </td>
          <td>
            <div *ngIf="incident.teamProgress!=null && incident.teamProgress.length>0">
              <div class="ep-cst">
                <span class="title-text">EP </span>
                <div class="progress protocol-progress">
                  <div *ngFor="let color of incident.teamProgress[0].barProgress">
                    <div class="progress-bar bg_green" *ngIf="color.status==1" [ngClass]="{'bg_green' :color.status==1}"
                      role="progressbar" [style.width.%]="color.percentage" aria-valuenow="50" aria-valuemin="0"
                      aria-valuemax="100"></div>
                    <div class="progress-bar bg_orange" *ngIf="color.status==2"
                      [ngClass]="{'bg_green' :color.status==2}" role="progressbar" [style.width.%]="color.percentage"
                      aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="progress-bar bg_red" *ngIf="color.status==3" [ngClass]="{'bg_green' :color.status==3}"
                      role="progressbar" [style.width.%]="color.percentage" aria-valuenow="50" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div class="ep-cst">
                <span class="title-text">CST </span>
                <div class="progress protocol-progress">
                  <div *ngFor="let color of incident.teamProgress[0].barProgress">
                    <div class="progress-bar bg_green" *ngIf="color.status==1" [ngClass]="{'bg_green' :color.status==1}"
                      role="progressbar" [style.width.%]="color.percentage" aria-valuenow="50" aria-valuemin="0"
                      aria-valuemax="100"></div>
                    <div class="progress-bar bg_orange" *ngIf="color.status==2"
                      [ngClass]="{'bg_green' :color.status==2}" role="progressbar" [style.width.%]="color.percentage"
                      aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    <div class="progress-bar bg_red" *ngIf="color.status==3" [ngClass]="{'bg_green' :color.status==3}"
                      role="progressbar" [style.width.%]="color.percentage" aria-valuenow="50" aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="dropdown actions position_unset">
              <button class="btn dropdown-toggle" type="button" id="actiondropdown1" data-bs-toggle="dropdown"
                aria-expanded="false"></button>
              <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                <!-- <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li> -->
                <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related incidents</a></li>
                <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
              </ul>
            </div>
          </td>
          <td class="launch-box " [ngClass]="incident.type=='1'?'greenbg_gradient':'bg_gradient'">
            <section *ngIf="incident.type=='1'">
              <button class="btn view-launch-btn dropdown-toggle" type="button" id="viewlaunchProtocol"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets/images/eye-icon.png" alt="eye-icon">
                <span class="launch-title">View Protcol</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="viewlaunchProtocol">
                <li><a href="/local-authority-actions-response"><img src="assets/images/launch-icon-1.png"
                      alt="launch-icon-1"> View Protocol</a></li>
                <!--<li><a href="#"><img src="assets/images/launch-icon-2.png" alt="launch-icon-2"> Launch protocol from a
                    custom template</a></li>-->
              </ul>
            </section>
            <section *ngIf="incident.type!='1'">
              <button class="btn view-launch-btn dropdown-toggle" type="button" id="launchProtocol"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets/images/rocket-icon.png" alt="rocket-icon">
                <span class="launch-title">Launch Protcol</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                <li><a href="https://app.seriousincidentmanager.com/admin/incidents/templates/preview/{{incident.id}}"
                    target="_blank"><img src="assets/images/launch-icon-1.png" alt="launch-icon-1"> Launch protocol from
                    existing
                    template</a></li>
                <!--<li><a href="#"><img src="assets/images/launch-icon-2.png" alt="launch-icon-2"> Launch protocol from a
                    custom template</a></li>-->
              </ul>
            </section>
          </td>
        </tr>

      </tbody>
      <tbody *ngIf="LADashboardDTO.length==0">
        <tr>
          <td colspan="15">
            <div class="alert alert-info">No Incident Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <app-table-loader *ngIf="dashboardLoad"></app-table-loader>
  </div>
</div>