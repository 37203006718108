import { Injectable } from '@angular/core';
import { Months } from 'src/app/Enum/Months.enum';
import { CommonType } from 'src/app/Models/incident.modal';
import { StringHelperService } from './string-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private _stringHelperService: StringHelperService
  ) { }

  GetDateTimeForIncident(date: Date) : {time: string, day: string, month: string, year: string}{
    let hours = date.getHours();
    let time: string;
    let day: string;
    let month: string;
    let year: string;
    let incidentDateMintes = date.getMinutes().toString();
    if (incidentDateMintes.length == 1) {
      incidentDateMintes = "0" + incidentDateMintes;
    }
    if (hours < 10) {
      if (date.getHours() > 0) {
        time =
          "0" +
          Number(Number(date.getHours())) +
          ":" +
          incidentDateMintes;
      } else {
        time =
          "0" + Number(date.getHours()) + ":" + incidentDateMintes;
      }
    } else {
      time =
        Number(Number(date.getHours())) + ":" + incidentDateMintes;
    }

    day = date.getDate().toString();
    month = Number(date.getMonth() + 1).toString();
    year = date.getFullYear().toString();

    return {time: time, day: day, month: month, year: year};
  }

  CreateDateFromDayMonthYear(day: string, month: string, year: string): Date{
    let date = new Date();
    return this.SetDate(date, day, month, year);
  }


  CreateDateTimeInstance(time: string, day: string, month: string, year: string) : Date{
    let date = new Date();
    let times = time.split(":");
    date.setHours(Number(Number(times[0])));
    date.setMinutes(Number(times[1]));
    return this.SetDate(date, day, month, year);
  }

  SetDate(date: Date, day: string, month: string, year: string): Date{
    date.setDate(Number(day));
    date.setMonth(Number(month) - 1);
    date.setFullYear(Number(year));
    return date;
  }


  GetDatesInMonth(month: number, year: number): CommonType[] {

    const dates: { id: number, name: string }[] = [];
    const date = new Date(year, month, 0);
    const isLeap = this.IsLeapYear(year);

    const lastDay = (isLeap && date.getMonth() == 1) ? 29 : date.getDate();

    for (let day = 1; day <= lastDay; day++) {
      dates.push({ id: day, name: day.toString() });
    }

    return dates;
  }

  IsLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  GetMonths(): CommonType[] {
    const totalMonths = 12;
    let months: CommonType[] = [];
    for (let index = 1; index <= totalMonths; index++) {
      months.push({ 'id': index, 'name': this.GetMonthNameFromMonthIndex(index) });
    }
    return months;
  }

  GetYears(): CommonType[] {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 15;
    const endYear = currentYear + 20;
    const years: CommonType[] = [];
  
    for (let year = startYear; year <= endYear; year++) {
      years.push({ 'id': year, 'name': year.toString() });
    }
  
    return years;
  }

  GetMonthNameFromMonthIndex(monthIndex: number){
    const monthNames = Object.keys(Months).filter((key) => isNaN(Number(key))) as Array<keyof typeof Months>;
  
    const month = monthNames.find((month) => Months[month] === monthIndex);

    if (month) {
      return month;
    }

    return '';
  }

  IsValidDate(day: number, month: number, year: number){
    if (this._stringHelperService.IsAnyNullOrWhiteSpace(day.toString(), month.toString(), year.toString())){
      return false;
    }

    // Adjust month value by subtracting 1 to match zero-based index
    const adjustedMonth = month - 1;

    // Create a new Date object with the given day, month, and year
    const date = new Date(year, adjustedMonth, day);

    // Check if the date's month and year match the provided values
    // This is necessary to handle cases like 31/04/2023, which will be automatically adjusted to 01/05/2023
    if (date.getMonth() !== adjustedMonth || date.getFullYear() !== year) {
      return false;
    }

    // Check if the day is within the valid range for the given month and year
    return day === date.getDate();
  }



  ConvertDateToSend(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Construct the string representation in the format 'YYYY-MM-DDTHH:mm:ss'
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  }

  RetrieveDate(date: string) {
    const dateParts = date.split("T")[0].split("-");
    const timeParts = date.split("T")[1].split(".");

    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);
    const hours = parseInt(timeParts[0].split(":")[0]);
    const minutes = parseInt(timeParts[0].split(":")[1]);
    const seconds = parseInt(timeParts[0].split(":")[2]);

    // Reconstruct the Date object with the extracted components
    return new Date(year, month, day, hours, minutes, seconds);
  }



}

