import { tap } from 'rxjs/operators';
import { IncidentService } from './../../initial-incident-form/incident.service';
import { OnDestroy } from "@angular/core";
import { MemberProfile } from "./../../Models/Members/MemberProfile";
import {
  IncidentTaskAssignee,
  SaveProtocolLaunchDTO,
  SaveProtocolLaunchItemDTO,
} from "./../../Models/LAIncedenceDashboard/LAIncidentDashboardModel";
import { BasicTemplateInfo } from "./../../Models/Templates/Template";
import { CstProtocolLaunchService } from "./cst-protocol-launch.service";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LoadScriptService } from "src/app/home-dashboard/load-script.service";
import {
  IncidentLaunchTask,
  LAIncidentLaunchDTO,
} from "src/app/Models/LAIncedenceDashboard/LAIncidentDashboardModel";
import { Subject, Subscription } from 'rxjs';
import { AssignMemberPopupComponent } from 'src/app/overlays/assign-member-popup/assign-member-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { ImmediateResponseService } from 'src/app/home-dashboard/immediate-response/immediate-response.service';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';

@Component({
  selector: "app-cst-protocol-launch",
  templateUrl: "./cst-protocol-launch.component.html",
  styleUrls: ["./cst-protocol-launch.component.css"],
})
export class CstProtocolLaunchComponent implements OnInit, OnDestroy {
  incidentId: string;
  incident: any;
  templates: BasicTemplateInfo[] = [];
  selectedTemplate: BasicTemplateInfo;
  isBasicTemplatesLoaded = false;

  incidentLoaded = false;
  isLaunched = false;

  incidentLaunchData: LAIncidentLaunchDTO;
  isIncidentLaunchdataLoaded: number = 0;

  teamMemebers: MemberProfile[] = [];
  isTeamMembersLoaded: boolean = false;

  selectedCheckbox: IncidentLaunchTask[] = [];

  selectedTeamMemberForBulkUpdate: MemberProfile;

  dataLoadedEvent = new Subject<boolean>();
  dataLoadedEventSubscription: Subscription;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _loadScriptService: LoadScriptService,
    private _toastr: ToastrService,
    private _cstProtocolLaunchService: CstProtocolLaunchService,
    private _router: Router,
    private _immediateResponseService: ImmediateResponseService,
    private _incidentService: IncidentService,
    private _dialog: MatDialog,
    private _apiErrorService: ApiErrorService,
    public _urlHelperService: UrlHelperService
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    //let sadfas = this._activatedRoute.snapshot.getCurrentNavigation().extras.state.myObject;

    this._loadScriptService.LoadDropdownScript("Launch-CSTProtocolScriptTag");

    // this.dataLoadedEventSubscription = this.dataLoadedEvent.subscribe((x) => {
    //   if (x) {
    //     let timerId = setInterval(() => {
    //       if (this.isBasicTemplatesLoaded && this.incidentLoaded && this.isTeamMembersLoaded && this._loadScriptService.ShouldDropdownScriptLoad()){
    //         this._loadScriptService.LoadScript("Launch-CSTProtocolScriptTag");
    //         clearInterval(timerId);
    //       }
    //     }, 100);
    //   }
    // });

    this._activatedRoute.params.subscribe((params) => {
      if (params.id != null && params.id != undefined) {
        this.incidentId = params.id;

        if (this.incidentId){

          if (!this.isTeamMembersLoaded) {
            this._immediateResponseService
              .GetTeamMembersForIncident(this.incidentId)
              .subscribe(
                (response) => {
                  if (response && response.data && response.statusCode == 200) {
                    this.isTeamMembersLoaded = true;
                    this.teamMemebers = response.data;
                    this.sortTeamMemberByName();
                    this.dataLoadedEvent.next(true);
                    //console.log(response.data);
                  }
                },
                (error) => {
                  this._apiErrorService.ShowError(error);
                }
              );
          }
  
          this._incidentService.getIncidentDetails(this.incidentId).subscribe(response => {
            if (response && response.data && response.statusCode == 200) {
              this.incident = response.data;
              this.incidentLoaded = true;
              this.dataLoadedEvent.next(true);
              //console.log(this.incident);
            }
          },
          (error) => {
            this._apiErrorService.ShowError(error);
          });
        }
        
      }
    });

    this._cstProtocolLaunchService.GetCSTTemplates().subscribe(
      (data) => {
        if (data.statusCode == 200) {
         
          //console.log(data);
          this.templates = data.data;
          this.isBasicTemplatesLoaded = true;
          this.dataLoadedEvent.next(true);
          
        }
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      }
    );
  }

  trackByFn(index: number, item: IncidentLaunchTask) {
    return index;
  }

  templateSelected(selectedTemplate: BasicTemplateInfo) {
    this.isIncidentLaunchdataLoaded = 1;
    this.incidentLaunchData = null;
    this.selectedCheckbox = [];
    
    if (selectedTemplate == null && selectedTemplate == undefined) {
      return;
    }

    document.getElementById("t_dropdownTemplates").classList.remove("shown");

    this.selectedTemplate = selectedTemplate;

    

    this._cstProtocolLaunchService
      .GetCSTTemplatesActions(this.incidentId, selectedTemplate.templateId)
      .subscribe(
        (response) => {
          //console.log(response);
          if (response && response.data && response.statusCode == 200) {
            this.incidentLaunchData = response.data;

            if (this.incidentLaunchData && this.incidentLaunchData.taskGroups && this.incidentLaunchData.taskGroups.length > 0){
              this.sortByTitalAndTimeframe();
  
              console.log(response.data);
              // if (this.incidentLaunchData){
              //   for (const taskGroup of this.incidentLaunchData.taskGroups) {
              //     if (taskGroup && taskGroup.tasks && taskGroup.tasks.length > 0){
              //       for (const task of taskGroup.tasks) {
              //         if (task && task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
              //           for (const assignee of task.incidentTaskAssignees) {
              //             if (assignee && assignee.assigneeId && assignee.assigneeName){
              //               if (!this.teamMemebers.some(x => x.toLocaleLowerCase() === assignee.assigneeName.toLocaleLowerCase())){
              //                 this.teamMemebers.push(assignee.assigneeName);
              //               }
              //             }
              //           }
              //         }
              //       }
              //     }
              //   }
              // }
              this.isIncidentLaunchdataLoaded = 2;
            }
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
        }
      );
  }

  sortByTitalAndTimeframe(){
    this.incidentLaunchData.taskGroups.sort((a, b) => {
      // Sort by title first
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
    
      // If title are the same, sort by taskTimeFrame
      if (a.taskTimeFrame < b.taskTimeFrame) {
        return -1;
      }
      if (a.taskTimeFrame > b.taskTimeFrame) {
        return 1;
      }
    
      return 0; // If both name and number are equal
    });
  }

  sortTeamMemberByName(){
    this.teamMemebers.sort((a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    });
  }

  allCheckChanged(event: Event, index: number, incident: IncidentLaunchTask) {
    const tagClass = (event.target as HTMLElement).id;
    const tasks = incident?.tasks;
    if (tagClass != null && tagClass != undefined) {
      if (tasks != null && tasks != undefined && tasks.length > 0) {
        const chk = event.target as HTMLInputElement;
        if (chk.checked) {
          for (const task of tasks) {
            if (!this.selectedCheckbox.some((item) => item === task)) {
              this.selectedCheckbox.push(task);
            }
          }
        } else {
          this.selectedCheckbox = this.selectedCheckbox.filter(
            (item1) => !tasks.some((item2) => item1.id === item2.id)
          );
        }
      }

      const checkboxes = document.querySelectorAll(
        "." + tagClass
      ) as NodeListOf<HTMLInputElement>;
      if (
        checkboxes != null &&
        checkboxes != undefined &&
        checkboxes.length > 0
      ) {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = (event.target as HTMLInputElement).checked;
        });
      }
      //#region common checkbox logic
      // const commonCheckbox = document.querySelectorAll(
      //   "." + "chkSame" + index
      // ) as NodeListOf<HTMLInputElement>;
      // if (
      //   commonCheckbox != null &&
      //   commonCheckbox != undefined &&
      //   commonCheckbox.length > 0
      // ) {
      //   commonCheckbox.forEach((checkbox) => {
      //     checkbox.checked = (event.target as HTMLInputElement).checked;
      //   });
      // }
      //#endregion
    }
  }

  checkboxChanged(event, task: IncidentLaunchTask) {
    if (event.target.checked) {
      if (!this.selectedCheckbox.some((item) => item === task)) {
        this.selectedCheckbox.push(task);
      }
    } else {
      this.selectedCheckbox = this.selectedCheckbox.filter(
        (item) => item !== task
      );
    }
  }

  bulkUpdateClicked(teamMember: MemberProfile) {
    this.selectedTeamMemberForBulkUpdate = teamMember;

    this.incidentLaunchData.taskGroups.forEach((teamGroup) => {
      if (teamGroup.tasks != null && teamGroup.tasks.length > 0) {
        teamGroup.tasks.forEach((task) => {
          if (task != null && task.id) {
            if (this.selectedCheckbox.some((x) => x.id == task.id)) {
              if (task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
                task.incidentTaskAssignees[0].assigneeId = teamMember.id;
                task.incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
                task.incidentTaskAssignees[0].avatar = teamMember.avatar;
              }
              else{
                const taskAssignee = new IncidentTaskAssignee();
                taskAssignee.assigneeId = teamMember.id;
                taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
                taskAssignee.avatar = teamMember.avatar;
                task.incidentTaskAssignees.push(taskAssignee);
              }
            }
          }
        });
      }
    });

    // const asdsad = this.incidentLaunchData.taskGroups.filter(x => this.selectedCheckbox.some(y => y.id == x.id));
    // console.log(asdsad);

    // if (!teamMember || !teamMember.id){
    //   return;
    // }

    // if (this.incidentId && this.incidentLaunchData && this.incidentLaunchData.templateId && this.selectedCheckbox && this.selectedCheckbox.length > 0){

    //   let saveProtocolLaunchDTO = new SaveProtocolLaunchDTO();
    //   saveProtocolLaunchDTO.incidentId = this.incidentId;
    //   saveProtocolLaunchDTO.TemplateIdUsed = this.incidentLaunchData.templateId;

    //   for (const task of this.selectedCheckbox) {

    //     let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
    //     saveProtocolLaunchItemDTO.TaskId = +task.id;
    //     saveProtocolLaunchItemDTO.Timeframe = task.taskTimeFrame;
    //     saveProtocolLaunchItemDTO.MemberId = teamMember.id;

    //     saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
    //   }

    //   this._cstProtocolLaunchService.SaveCSTProtocolLaunchAssignments(saveProtocolLaunchDTO).subscribe(response => {
    //     this._router.navigate(['home-dashboard']);
    //   }, err => {
    //     console.log("Error:" + JSON.stringify(err));
    //     this._toastr.error(err?.error?.message, "Error");
    //   });
    // }
  }

  SaveAndLaunchTheProtocol() {
    // if (
    //   !this.selectedTeamMemberForBulkUpdate ||
    //   !this.selectedTeamMemberForBulkUpdate.id
    // ) {
    //   return;
    // }

    if (
      this.incidentId &&
      this.incidentLaunchData &&
      this.incidentLaunchData.templateId &&
      this.incidentLaunchData.taskGroups &&
      this.incidentLaunchData.taskGroups.length > 0
    ) {
      this.isLaunched = true;

      let saveProtocolLaunchDTO = new SaveProtocolLaunchDTO();
      saveProtocolLaunchDTO.incidentId = this.incidentId;
      saveProtocolLaunchDTO.TemplateIdUsed = this.incidentLaunchData.templateId;

      // for (const task of this.selectedCheckbox) {
      //   let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
      //   saveProtocolLaunchItemDTO.TaskId = +task.id;
      //   saveProtocolLaunchItemDTO.Timeframe = task.taskTimeFrame;
      //   saveProtocolLaunchItemDTO.MemberId =
      //     this.selectedTeamMemberForBulkUpdate.id;

      //   saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
      // }
      
      for (const task of this.incidentLaunchData.taskGroups) {
        if(task.tasks && task.tasks.length > 0){
          for (const innerTask of task.tasks) {
            let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
            saveProtocolLaunchItemDTO.TaskId = +innerTask.id;
            saveProtocolLaunchItemDTO.Timeframe = innerTask.taskTimeFrame;
            if (innerTask.incidentTaskAssignees && innerTask.incidentTaskAssignees.length > 0){
              saveProtocolLaunchItemDTO.MemberId = innerTask.incidentTaskAssignees[0].assigneeId;
            }
          
            saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
          }
        }
      }

      this._cstProtocolLaunchService
        .SaveCSTProtocolLaunchAssignments(saveProtocolLaunchDTO)
        .pipe(tap(() => this.isLaunched = true))
        .subscribe(
          (response) => {
            this._router.navigate(["home-dashboard"]);
          },
          (error) => {
            this._apiErrorService.ShowError(error);
          }
        );
    }
  }

  personClicked(teamMember: MemberProfile[], task: any, outerIndex: number, innerIndex: number, ){
    let memberArray : {name: string, avatar: string}[] = [];
    memberArray = teamMember.map(x => { 
      return {name: `${x.firstName} ${x.familyName}`, avatar: x.avatar}
    });

    //const memberStringArray = teamMember.map(x => `${x.firstName} ${x.familyName}`).sort();

    this._dialog.open(AssignMemberPopupComponent, {
      height: '80vh',
      data: {members: memberArray}
    }).afterClosed().subscribe(selectedMember => {
      if (selectedMember == null || selectedMember == undefined || selectedMember == ''){
        return;
      }

      const selectedTeamMember = teamMember.find(x => `${x.firstName} ${x.familyName}` === selectedMember);
      if (!selectedTeamMember){
        return;
      }

      this.AssignMember(selectedTeamMember, task, outerIndex, innerIndex);
    });
  }

  AssignMember(teamMember: MemberProfile, task: any, outerIndex: number, innerIndex: number) {

    if (this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.length > 0){
      this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeId = teamMember.id;
      this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
      this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].avatar = teamMember.avatar;
    }
    else{
      const taskAssignee = new IncidentTaskAssignee();
      taskAssignee.assigneeId = teamMember.id;
      taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
      taskAssignee.avatar = teamMember.avatar;
      this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.push(taskAssignee);
    }
  }

  // AssignMember(teamMember: MemberProfile, task: any, outerIndex: number, innerIndex: number) {

  //   if (this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.length > 0){
  //     this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeId = teamMember.id;
  //     this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //   }
  //   else{
  //     const taskAssignee = new IncidentTaskAssignee();
  //     taskAssignee.assigneeId = teamMember.id;
  //     taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //     this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.push(taskAssignee);
  //   }
    
  //   // this.incidentLaunchData.taskGroups.forEach((teamGroup) => {
  //   //   if (teamGroup.tasks != null && teamGroup.tasks.length > 0) {
  //   //     teamGroup.tasks.forEach((task) => {
  //   //       if (task != null && task.id) {
  //   //         if (this.selectedCheckbox.some((x) => x.id == task.id)) {
  //   //           if (task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
  //   //             task.incidentTaskAssignees[0].assigneeId = teamMember.id;
  //   //             task.incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //   //           }
  //   //           else{
  //   //             const taskAssignee = new IncidentTaskAssignee();
  //   //             taskAssignee.assigneeId = teamMember.id;
  //   //             taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //   //             task.incidentTaskAssignees.push(taskAssignee);
  //   //           }
  //   //         }
  //   //       }
  //   //     });
  //   //   }
  //   // });
  // }

  // SaveCSTProtocolLaunchAssignments(){

  //   if (this.incidentId && this.incidentLaunchData && this.incidentLaunchData.templateId && this.selectedCheckbox && this.selectedCheckbox.length > 0){

  //     let saveProtocolLaunchDTO = new SaveProtocolLaunchDTO();
  //     saveProtocolLaunchDTO.incidentId = this.incidentId;
  //     saveProtocolLaunchDTO.TemplateIdUsed = this.incidentLaunchData.templateId;

  //     for (const task of this.selectedCheckbox) {

  //       let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
  //       saveProtocolLaunchItemDTO.TaskId = +task.id;
  //       saveProtocolLaunchItemDTO.Timeframe = task.taskTimeFrame;
  //       saveProtocolLaunchItemDTO.MemberId = '';

  //       saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
  //     }

  //     this._cstProtocolLaunchService.SaveCSTProtocolLaunchAssignments(saveProtocolLaunchDTO).subscribe(response => {
  //       this._router.navigate(['home-dashboard']);
  //     }, err => {
  //       console.log("Error:" + JSON.stringify(err));
  //       this._toastr.error(err?.error?.message, "Error");
  //     });
  //   }

  // }

  GetTimeFram(index: number): string {
    return this._cstProtocolLaunchService.GetTimeFram(index);
  }

  GetStringOfTeamMembersList(teamMebers: IncidentTaskAssignee[]) {
    let result = "";
    if (teamMebers && teamMebers.length > 0) {
      for (const teamMember of teamMebers) {
        result += teamMember.assigneeName + ", ";
      }
    }
    if (result.charAt(result.length - 2) == ",") {
      result = result.slice(0, -2);
    }
    return result;
  }

  ngOnDestroy(): void {
    this._loadScriptService.RemoveScript("Launch-CSTProtocolScriptTag");

    if (this.dataLoadedEventSubscription){
      this.dataLoadedEventSubscription.unsubscribe();
    }
  }
}
