import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/interceptors/api.service';
import { LATeamMessagingDashboardDTO } from '../Models/Stakeholder/LATeamMessagingDashboardDTO';

@Injectable({
  providedIn: 'root'
})
export class StakeholderService {

  constructor(
    private _apiService: ApiService
    ) {}

  GetStakeHolderDashBoard() {
    const url = "/la/StakeholderMessaging/Dashboard";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  SortIncidents(incidents: LATeamMessagingDashboardDTO[]){
    return incidents.sort((a, b) => {
      const aDate = new Date(a.dateTime);
      const bDate = new Date(b.dateTime);
      return bDate.getTime() - aDate.getTime();
    });
  }
}
