<app-home-nav-menu [componentName]="'team-messaging-dashboard'"></app-home-nav-menu>

<div class="team-protocol-section pt-15 local-auth-9">
  <div class="container cst-protocol Team_protocol_action">

    <h2 class="sec-title">Stakeholder Messages</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>

    <div class="w-80 w-sm-100" *ngIf="!isLoading">
      <!-- <div class="row">
        <div class="msg-indicat">
          <span class="Team-Messges-dot"> Team Messges</span>
          <span class="Partner-Messages-dot"> Partner Messages</span>
          <span class="IIR-Messages-dot"> IIR Messagess</span>
        </div>
      </div> -->
      <div class="accordion tableaccordian  " id="contactaccordian">

        <div class="accordion-item" *ngFor="let incident of incidentMessagesData; index as i">
          <h2 class="accordion-header" [id]="contactheading + i">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#contactaccord' + i" [attr.aria-expanded]="i === 0 ? true : false" [attr.aria-controls]="'contactaccord' + i">
              {{incident.title}} <span>{{incident.location}}, {{ incident.dateTime | date: 'HH:mm, MMMM d' }}</span>
              <div class="message-icon">
                <span (click)="sendMessageClicked('Mail', incident.incidentId)"><img src="assets/images/blue-mail-icon.png" alt=""> </span>
                <span (click)="sendMessageClicked('SMS', incident.incidentId)"><img src="assets/images/Green-mail-icon.png" alt=""> </span>
                <!-- <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a> -->
              </div>
            </button>
          </h2>
          <div [id]="'contactaccord' + i" class="accordion-collapse collapse" [class]="i === 0 ? 'show' : ''" [attr.aria-labelledby]="'contactheading' + i"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <!-- <th>To</th> -->
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="noMessagesTr" *ngIf="incident.messages.length === 0; else elseBlock">
                      <i>No messsages sent yet.</i>
                    </tr>
                    <ng-template #elseBlock>
                      <tr class="IIR-Messages" *ngFor="let message of incident.messages; index as ii" (click)="IncidentMessageClicked(message)">
                        <td class="messageContent no-bg-color"><strong>{{message.type === "1" ? "Mail": "SMS"}}</strong> &nbsp; <span>{{message.content | sorterString}}</span></td>
                        <!-- <td>{{messageToStringBuilder(message.to)}}</td> -->
                        <td>{{message.sent | date: 'HH:mm, MMMM d'}}</td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <!-- <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord1" aria-expanded="true" aria-controls="contactaccord1">
              Gang Violence <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a>
              </div>
            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="IIR-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading2">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord2" aria-expanded="false" aria-controls="contactaccord2">
              Non-fatal multiple stabbing <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a>
              </div>
            </button>
          </h2>
          <div id="contactaccord2" class="accordion-collapse collapse " aria-labelledby="contactheading2"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="IIR-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading3">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord3" aria-expanded="false" aria-controls="contactaccord3">
              Attempted Murder/firearms <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a>
              </div>
            </button>
          </h2>
          <div id="contactaccord3" class="accordion-collapse collapse " aria-labelledby="contactheading3"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="IIR-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading4">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord4" aria-expanded="false" aria-controls="contactaccord4">
              Multiple fatal stabbing <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a>
              </div>
            </button>
          </h2>
          <div id="contactaccord4" class="accordion-collapse collapse " aria-labelledby="contactheading4"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  <tbody>
                    <tr class="IIR-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</div>
<div class="divloader" *ngIf="isLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>