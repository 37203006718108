<div class="d-flex align-items-center justify-content-center" *ngIf="!membersLoaded">
  <mat-spinner diameter="30"></mat-spinner>
</div>
<div *ngIf="membersLoaded">
  <div class="d-flex justify-content-end">
    <img class="btnMapClose showHand" (click)="closeMapClicked()" src="../../../assets/images/close-black-icon.png">
  </div>
  <h4 class="popupHeading">Assign member</h4>
  <div class="d-flex align-items-center pb-2 mb-2 link-dark text-decoration-none border-bottom showHand" *ngFor="let member of members" (click)="memberClicked(member)">
    <img class="profile-image right-margin" [src]="member && member.avatar ? member.avatar : 'assets/images/profile-blank-icon.png'" alt="profile-ah-icon">
    <span class="member " > {{member.name}}</span>
  </div>  
  <!-- <img alt="user-profile" class="user-profile" src="https://simstore1.s3.eu-west-1.amazonaws.com/Pete.jpg">
  <p class="member showHand" *ngFor="let member of members" (click)="memberClicked(member)">{{member}}</p> -->
</div>