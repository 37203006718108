import { MemberProfileCreate } from "../Members/MemberProfileCreate";

export class TeamMember extends MemberProfileCreate {
  incidentAlert: boolean;
  locationAlert: boolean;
  section60Alert: boolean;
  section35Alert: boolean;
  partnerMessage: boolean;
  incidentLaunchIfNotTeam: boolean;
  dashboardContact: boolean;
}
