import { Injectable } from '@angular/core';
import { LAIncidentLaunchDTO } from '../Models/LAIncedenceDashboard/LAIncidentDashboardModel';

@Injectable({
  providedIn: 'root'
})
export class CstRoutingStateService {

  constructor() { }

  incidentTeplate: LAIncidentLaunchDTO

}
