import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class CstTemplateService {

  constructor(
    private _apiService: ApiService
  ) { }

  getTemplateData() {
    const url = "/Manager/Template/New";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetTemplates(){
    const url = "/Manager/Template";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetTemplateDetail(Id: string){
    const url = `/Manager/Template/Details/${Id}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }



  // GetCSTTemplates(){
  //   const url = `/LA/CSTProtocol/Templates`;
  //   return this._apiService.get(url).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );
  // }

}
