import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/core/interceptors/api.service";
import {
  LAIncedentsModel,
  LAIncedentsUserActionsModel,
} from "src/app/Models/LAIncedenceDashboard/LAIncidentDashboardModel";
import { forkJoin } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeDashboardService {
  constructor(
    private _apiService: ApiService
    ) {}

  getDashBoard() {
    const url = "/La/Dashboard";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  

  getDashboardUserActions() {
    const url = "/La/Dashboard/Actions";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      }, err => {
        console.log("sdf");
      })
    );
  }

  getDashboardTeamActions() {
    const url = "/La/Dashboard/Team";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetLADashboardContacts() {
    const url = "/La/Dashboard/Contacts";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  loadAlldata() {
    return forkJoin([
      this.getDashBoard(),
      this.getDashboardUserActions(),
      this.getDashboardTeamActions(),
      this.GetLADashboardContacts(),
    ]);
  }

  SortIncedenceData(apiResult: LAIncedentsModel[]) {
    let sortedResult: LAIncedentsModel[] = [];
    let pendingIncidents: LAIncedentsModel[] = [];
    let responceInProgressIncidents: LAIncedentsModel[] = [];
    let protocolInProgressIncidents: LAIncedentsModel[] = [];
    let sacledDownIncidents: LAIncedentsModel[] = [];
    let declinedIncidents: LAIncedentsModel[] = [];

    for (const incedence of apiResult) {
      if (+incedence.incidentStatus === 0) {
        pendingIncidents.push(incedence);
        continue;
      } else if (+incedence.incidentStatus === 5) {
        responceInProgressIncidents.push(incedence);
        continue;
      } else if (+incedence.incidentStatus === -1) {
        protocolInProgressIncidents.push(incedence);
        continue;
      } else if (+incedence.incidentStatus === 2) {
        sacledDownIncidents.push(incedence);
        continue;
      } else {
        declinedIncidents.push(incedence);
        continue;
      }
    }
    sortedResult = [
      ...this.sortArrayBasedOnDateTime(pendingIncidents),
      ...this.sortArrayBasedOnDateTime(responceInProgressIncidents),
      ...this.sortArrayBasedOnDateTime(protocolInProgressIncidents),
      ...this.sortArrayBasedOnDateTime(declinedIncidents),
      ...this.sortArrayBasedOnDateTime(sacledDownIncidents),
    ];
    return sortedResult;
  }

  sortArrayBasedOnDateTime(incedents: LAIncedentsModel[]) {
    return incedents.sort((a, b) => {
      const aDate = new Date(a.datetime);
      const bDate = new Date(b.datetime);
      return bDate.getTime() - aDate.getTime();
    });
  }

  sortYourActionArrayBasedOnDateTime(incedents: LAIncedentsUserActionsModel[]) {
    return incedents.sort((a, b) => {
      const aDate = new Date(a.dateTime);
      const bDate = new Date(b.dateTime);
      return bDate.getTime() - aDate.getTime();
    });
  }

  dashBoardIncedenceTrClassResolver(incedenceStatus: number): string {
    let result = "";
    switch (incedenceStatus) {
      case -1: {
        // protocol in progress
        result = "protocol_inprogress-line";
        break;
      }
      case 0: {
        // pending
        result = "pending-line";
        break;
      }
      case 5: {
        // response in progress
        result = "response_inprogress-line";
        break;
      }
      case 4: {
        // Decliend
        result = "declined-line disable";
        break;
      }
      case 2: {
        // scale down
        result = "scale_down-line";
        break;
      }
      default: {
        result = "pending-line";
        break;
      }
    }
    return result;
  }
}
