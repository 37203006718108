import { Marker, MouseEvent } from '@agm/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IncidentFormLocationEntity } from 'src/app/Models/IncidentFormLocationEntity';
import { MapForm } from 'src/app/Models/MapForm';
import { Markers, LocationDTO } from 'src/app/Models/incident.modal';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { StringHelperService } from 'src/app/core/services/string-helper.service';
import { DrawMapComponent } from '../draw-map/draw-map.component';
import { LocationType } from 'src/app/Enum/LocationType.enum';
import { IncidentDrawMapService } from './incident-draw-map.service';

@Component({
  selector: 'app-incident-draw-map',
  templateUrl: './incident-draw-map.component.html',
  styleUrls: ['./incident-draw-map.component.css']
})
export class IncidentDrawMapComponent implements OnInit {
  @ViewChild('mapForm', { static: true }) mapForm: NgForm;

  get RendezvousPointLocation() {
    return LocationType.RendezvousPointLocation;
  }
  //#region input fields and labels
  drawingType: string;
  //#endregion

  //#region form
  mapData: MapForm = new MapForm();
  isDuplicateName : boolean;
  isEdit : boolean;
  id : string;

  isInvalidMap: boolean = false;

  isMapReady = false;
  isSubmitted = false;

  incidentId: string;
  //#endregion

  //#region map
  InitialLatitude: number;
  InitialLongitude: number;
  InitialZoom: number;
  markerIconUrl: string;
  color: string;
  closed: boolean;

  marker: Markers;

  drawingManager: any;

  pointList: any[] = [];
  existingAllPolygon : IncidentFormLocationEntity[] = [];
  existingRVPMarkers : IncidentFormLocationEntity[] = [];
  LocationToBeUpdate : IncidentFormLocationEntity;

  editable = false;
  polygonDrawn: boolean = false;

  RVPLatitude : number;
  RVPLongitude : number;

  HeadingText: string = "";
  
  //#endregion

  constructor(
    private _dialogRef: MatDialogRef<DrawMapComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _incidentDrawMapService: IncidentDrawMapService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _stringHelperService: StringHelperService,
    private _googleMapService: GooglemapService
  ) {}

  ngOnInit(): void {
    this.incidentId = this._initialInfo.incidentId;
    if (!this._stringHelperService.IsValidGuid(this.incidentId)){
      this._dialogRef.close();
    }

    this.InitialLatitude = this._initialInfo.latitude;
    this.InitialLongitude = this._initialInfo.longitude;
    this.InitialZoom = this._initialInfo.zoom;
    this.drawingType = this._initialInfo.drawingType;

    if (!this._stringHelperService.IsNullOrWhiteSpace(this.drawingType)){
      this.HeadingText = this._incidentDrawMapService.GetHeadingText(this.drawingType);
    }

    this.color = this._initialInfo.color;
    this.existingAllPolygon = this._initialInfo.existingAllPolygon
    this.existingRVPMarkers = this._initialInfo.existingRVPMarkers
    this.isEdit = this._initialInfo.isEdit;
    this.LocationToBeUpdate = this._initialInfo.LocationToBeUpdate;
    this.id = this._initialInfo.id;
    this.closed = this._initialInfo.closed ?? false;
    this.marker = this._initialInfo.marker;

    this.mapData.Name = this.LocationToBeUpdate?.name;
    this.mapData.Description = this.LocationToBeUpdate?.description;

    if (this.marker){
      let cordinatesArray = [];
      cordinatesArray.push({lat: this.marker.lat, lng: this.marker.lng});
      this.pointList.push(cordinatesArray);
    }
    else {
      if (this.isEdit){
        const array = [...this.LocationToBeUpdate?.pathList];
        this.pointList.push(array);
      }
    }

    this.isMapReady = true;
  }


  //#region polygon

  onMapReadyForCrimeSceneOnlyShowMap($event: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: "polygon",
    };

    this._googleMapService.OverlayCompletehandler($event, options, (path) => {
      const latLngArray = this._incidentDrawMapService.CreatePointArrayForPolygon(path);
      this.pointList.push(latLngArray);
      this.editable = false;
      this.isInvalidMap = false;
    });
  }

  //#endregion

 


 //#region RVP marker

  mapClicked($event: MouseEvent) {
    // (this.RVPLatitude = $event.coords.lat), (this.RVPLongitude = $event.coords.lng);
    // this._googleMapService.MapClicked($event, (addresses) => {

    // })
  }


  markerDragEnd(m: Marker, $event: MouseEvent) {
    this.pointList[0][0].lat = $event.coords.lat;
    this.pointList[0][0].lng = $event.coords.lng;

    this.isInvalidMap = false;
  }



   //#endregion

  markerDragEndLocation($event: MouseEvent) {
    let path = $event.coords;

    this.pointList.forEach((element) => {
      element.LocationDTOItem.forEach((sub) => {
        if (sub.mapShow) {
          sub.selected = true;
          sub.locationPoints = [];
          sub.locationPoints.push(path);
        }
      });
    });

  }

  
  

  nameChange(event: string){
    if (!this._stringHelperService.IsNullOrWhiteSpace(event)){
      if (this.isEdit){
        const isPolyNameDuplicate = this.existingAllPolygon.filter(x => x.name?.toLowerCase() === event.toLowerCase() && x.id != this.id).length > 0 ? true : false;
        const isRVPMarkerDuplicate = this.existingRVPMarkers.filter(x => x.name?.toLowerCase() === event.toLowerCase() && x.id != this.id).length > 0 ? true : false;
        this.isDuplicateName = isPolyNameDuplicate || isRVPMarkerDuplicate
      }
      else{
        const isPolyNameDuplicate = this.existingAllPolygon.findIndex(x => x.name?.toLowerCase() === event.toLowerCase()) == -1 ? false : true;
        const isRVPMarkerDuplicate = this.existingRVPMarkers.findIndex(x => x.name?.toLowerCase() === event.toLowerCase()) == -1 ? false : true;
        this.isDuplicateName = isPolyNameDuplicate || isRVPMarkerDuplicate
      }
      if (this.isDuplicateName){
        this.mapForm.form.setErrors({ 'invalid': true });
      }
    }
  }

  RemoveLocations(){
    this.pointList = [];
    this.isInvalidMap = true
  }

  onSubmit(formData: MapForm) {
    this.isSubmitted = true;
    if (!this.pointList || this.pointList.length == 0){
      this.isInvalidMap = true;
      return;
    }

    document.getElementById('save-spinner').style.display = 'block';
    document.getElementById('btn-save').style.display = 'none';

    let result = {
      id: this.isEdit ? this.id : null,
      name: formData.Name,
      description: formData.Description,
      locations: this.pointList[0],
      color: this.color,
      locationDTO: new LocationDTO()
    };

    if (this.isEdit){
      result.id = this.id;
    }

    let locationDTO = new LocationDTO();
    locationDTO.type =  this._incidentDrawMapService.LocationTypeDesider(this.drawingType);
    locationDTO.name = result.name;
    locationDTO.closed = false;
    locationDTO.description = result.description;
    locationDTO.locationPoints =  this.pointList[0];

    if (this.isEdit){
      locationDTO.id = this.id;
      locationDTO.closed = this.closed;
    }
    result.locationDTO = locationDTO;

    this._incidentDrawMapService.saveUpdateLocation(locationDTO, this.incidentId).subscribe(response => {
      document.getElementById('save-spinner').style.display = 'none';
      document.getElementById('btn-save').style.display = 'block';

      if (response && response.statusCode == 200 && response.data){
        result.locationDTO.id = response.data;
        result.id = response.data;
        this._dialogRef.close(result);
      }
    },
    (error) => {
      document.getElementById('save-spinner').style.display = 'none';
      document.getElementById('btn-save').style.display = 'block';
      this._apiErrorService.ShowError(error);
    });

   
  }

  closeMapClicked(){
    this._dialogRef.close();
  }

}
