
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CognitoService } from '../cognito.service';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private _cognitoService: CognitoService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this._cognitoService.GetCurrentSession())
            .pipe(
                switchMap((auth: any) => { // switchMap() is used instead of map().

                    // let jwt = auth.accessToken.jwtToken;
                    let jwt = auth.idToken.jwtToken;
                    let with_auth_request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${jwt}`
                        }
                    });
                    // console.log("Cloned",with_auth_request);
                    return next.handle(with_auth_request);
                })
                // ,catchError((err) => {
                //     console.log("Error ", err);
                //     return next.handle(request);
                // })
            );
        
    }

}