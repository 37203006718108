import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headersConfig = {};
        let url = req.url.split("/");
        if (url[url.length - 1].includes("UploadMap")) {
            headersConfig = {};
        } else {
            headersConfig = {
                "Content-Type": "application/json",
                Accept: "application/json",
            };
        }
        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request);
    }
}
