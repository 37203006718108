import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EmergencyTaskAssignee } from 'src/app/Models/EmergencyPlanningModels/EmergencyTaskAssignee';
import { ApiService } from 'src/app/core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class ImmediateResponseService {

  constructor(private _apiService: ApiService) {}

  GetAllIncidentEmergencyPlanningActions(){
    const url = `/La/AllIncidentEmergencyPlanningActions`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }


  GetEmergencyPlanningActions(incidentId: string){
    const url = `/La/EmergencyPlanningActions/${incidentId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  UpdateResponseStatus(status: string, incidentId: string){
    if (status ===  "Reject"){
      const url = `/La/Incident/Task/Reject/${incidentId}`;
      return this._apiService.get(url).pipe(
        map((data) => {
          return data;
        })
      );
    }
    else{
      const url = `/La/Incident/Task/Acknowledge/${incidentId}`;
      return this._apiService.get(url).pipe(
        map((data) => {
          return data;
        })
      );
    }
  }
  
  GetTeamMembersForIncident(incidentId: string){
    const url = `/La/OrganisationTeam/${incidentId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  UpdateIndividualStatus(emergencyTaskMemberId : number, status : number){
    const url = `/La/EmergencyPlanningActions/UpdateStatus/${emergencyTaskMemberId}/${status}`;
    return this._apiService.post(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AssignTeamMemberToTask(emergencyTaskAssignee : EmergencyTaskAssignee){
    const url = `/La/EmergencyPlanningActions/Assign`;
    return this._apiService.post(url, emergencyTaskAssignee).pipe(
      map((data) => {
        return data;
      })
    );
  }
  
  GetEmergencyPlanningNotes(taskId: number){
    const url = `/La/Notes/Emergency/${taskId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  

//   { [Display(Name = "Not Required")] NotRequired= 1, 
// [Display(Name = "Pending")] Pending = 2, [
// Display(Name = "Active")] Active = 3, [
// Display(Name = "Complete")] Complete = 4 }


getStatusData(status: number): {classname: string, statusName: string}{
  let result: {classname: string, statusName: string};
  switch(status) { 
    case 1: {                                 // NotRequired
      result = {classname : "notRequired", statusName : ""};
      // result = {classname : "dark-green", statusName : "Done"};
      break; 
    }     
    case 2: {                                  // Pending
      result = {classname : "grey", statusName : "Pending"};     
      break; 
    } 
    case 3: {                                  // Active
      result = {classname : "orange", statusName : "Active"};
      break; 
    } 
    case 4: {                                  // Complete
      result = {classname : "dark-green", statusName : "Complete"};
      // result = {classname : "orange", statusName : "Active"};
      break; 
    } 
    default: { 
      result = {classname : "notRequired", statusName : ""};
      break; 
    } 
 } 
 return result;
}

getStatusNumber(status: string): number{
  let result: number;
  switch(status) { 
    case "notRequired": {                                 // Active
      result = 1;
      break; 
    }     
    case "Pending": {                                  // Ongoing
      result = 2;
      break; 
    } 
    case "Active": {                                  // Pending
      result = 3;
      break; 
    } 
    case "Complete": {                                  // Done
      result = 4;
      break; 
    } 
    default: { 
      result = 1;
      break; 
    } 
 } 
 return result;
}

getStringNameFromNumberResponseStatus(status: number) : string{
  let result: string;
  switch(status) { 
    case 0: {                                 // Active
      result = "Active";
      break; 
    }   
    case 1: {                                 // ScaledDown
      result = "ScaledDown";
      break; 
    }     
    case 2: {                                  // Declined
      result = "Declined";
      break; 
    } 
    case 3: {                                  // Pending
      result = "Pending";
      break; 
    } 
    default: { 
      result = "Active";
      break; 
    } 
 } 
 return result;
}

}
