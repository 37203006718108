import { tap } from 'rxjs/operators';
import { UserProfileService } from './../../core/services/user-profile.service';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { CognitoService } from './../cognito.service';
import { ActivatedRoute } from '@angular/router';
import { User } from './../../Models/Authentication/User';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MemberProfileDetailsDTO } from 'src/app/Models/Messages/MessageTemplateDTO';
import { StringHelperService } from 'src/app/core/services/string-helper.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  user: User | undefined;
  userId: string;

  usermember: MemberProfileDetailsDTO

  errorMessage: string = '';
  errorMessageConfirm: string = '';
  
  isSubmitted = false;
  isConfirmSubmitted = false;
  showConfirmationForm: boolean;

  signUpForm: FormGroup;
  confirmSignUpForm: FormGroup;

  isMemberProfileLoaded = false;
  isCurrentUserLoaded = false;

  constructor(
    private _fb: FormBuilder,
    private _toastr: ToastrService,
    private _activatedRoute: ActivatedRoute,
    private _userProfileService : UserProfileService,
    private _apiErrorService: ApiErrorService,
    private _cognitoService: CognitoService,
    private _stringHelperService: StringHelperService,
    private _appStartService: AppStartService
    ) { }

  ngOnInit(): void {
    this.user = {} as User;

    this.userId = this._activatedRoute.snapshot.params['id'];
    if (!this._stringHelperService.IsNullOrWhiteSpace(this.userId)){
      this._userProfileService.GetMemberProfile(this.userId).pipe(tap(() => { this.isMemberProfileLoaded = true})).subscribe(response => {
        if (response && response.data && response.statusCode == 200){
          this.usermember = response.data as MemberProfileDetailsDTO;
        }
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      })
    }

    this.SignUpForm();
    this.ConfirmSignUpForm();


    this.checkAccountStatus();
  }

  private SignUpForm(){
    this.signUpForm =  this._fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8), this.noSpaces.bind(this)]],
      confirmPassword: [null, [Validators.required, this.confirmPasswordValidator]],
    });
  }

  private ConfirmSignUpForm(){
    this.confirmSignUpForm =  this._fb.group({
      email: [null, [Validators.required, Validators.email]],
      confirmationCode: [null, [Validators.required]]
    });
  }

  private noSpaces(control: FormControl) {
    const value = control.value?.trim();
    const isValid = value === control.value;
    return isValid ? null : { containsSpaces: true };
  }

  confirmPasswordValidator(control: FormControl) {
    const password = control.root.get('password');
    if (password && control.value !== password.value) {
      return {
        confirmPassword: true
      };
    }
    return null;
  }

  public get email(){
    return this.signUpForm.controls.email as FormControl
  }

  public get password(){
    return this.signUpForm.controls.password as FormControl
  }

  public get confirmPassword(){
    return this.signUpForm.controls.confirmPassword as FormControl
  }

  public OnSubmit(){
    this.isSubmitted = true;
    if (this.signUpForm.invalid){
      return;
    }

    const providedEmail = this.email.value as string
    if (providedEmail.toLocaleLowerCase() !== this.usermember.email.toLocaleLowerCase()){
      this.errorMessage = "Email address is wrong";
      return;
    }

    this.user.email = this.email.value;
    this.user.password = this.password.value;
    if (this.usermember && this.user.email && this.user.password){

      this._cognitoService.SignUp(
        this.email.value, this.password.value, {
          email: this.email.value,
          given_name: this.usermember.firstName,
          family_name: this.usermember.familyName,
          phone_number: this.usermember.phoneNo
        })
      .then(data => {
        this.confirmSignUpForm.controls.email.patchValue(this.email.value);

        this.showConfirmationForm = true;
        this.errorMessage = '';
        // handle success
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error(error);
        // handle error
      });
    }
    else{
      this._toastr.error("Missing user information");
    }
  }

  // public confirmSignUp(){

  //   Auth.confirmSignUp(username, confirmationCode)
  //   .then(data => {
  //     console.log(data);
  //     // handle success
  //   })
  //   .catch(error => {
  //     console.error(error);
  //     // handle error
  //   });

  //   if (this.user && this.user.verificationCode){
  //     this._cognitoService.confirmSignUp(this.user)
  //     .then(() => {
  //       this.router.navigate(['/sign-in']);
  //     })
  //     .catch((error) => {
  //       this.displayAlert(error.message);
  //     })
  //   }
  //   else{
  //     this.displayAlert("Missing user information");
  //   }
  // }

  async onSubmitConfirmation() {
    try {
      this.isConfirmSubmitted = true;
      const { email, confirmationCode } = this.confirmSignUpForm.value;
      this._cognitoService.ConfirmSignUp(email, confirmationCode)
        .then(async (response) => {
          console.log(response);
          this.errorMessageConfirm = '';
          this._toastr.success("Account created successfully", "Success");

          if (this._appStartService.IsCognitoUserLoaded){
            await this._appStartService.NavigateToDashboard();
          }
          else{
            this._cognitoService.RedirectToLogin();
          }
        })
        .catch(error => {
          this.errorMessageConfirm = error.message;
          //this.toastr.error(error.message, "Error");
        });
    } catch (error) {
      this.errorMessageConfirm = error.message;
    }
  }

  async onResendConfirmationCode() {
    try {
      const { email } = this.confirmSignUpForm.value;
      await this._cognitoService.ResendSignUp(email);
      this.errorMessageConfirm = '';
    } catch (error) {
      this.errorMessageConfirm = error.message;
    }
  }

  async checkAccountStatus(): Promise<void> {
    try {
      let currentUser: any;
      if (!this._appStartService.IsCognitoUserLoaded){
        currentUser = await this._cognitoService.GetUser();
      }
      else{
        currentUser = this._appStartService.currentCognitoUser;
      }
      if (currentUser) {
        const isEmailVarified = currentUser.attributes['email_verified'] as boolean;
        if (isEmailVarified == true){
          //this.router.navigate(['/home-dashboard']);
        }
        else{
          this.showConfirmationForm = true;
        }
      }
      this.isCurrentUserLoaded = true;
    } catch (error) {
      this.isCurrentUserLoaded = true;
      console.log(error);
    }
  }

}
