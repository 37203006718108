import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { LocationDTO } from 'src/app/Models/incident.modal';

@Injectable({
  providedIn: 'root'
})
export class DrawMapService {

  constructor(private _apiService: ApiService) {}

  saveUpdateLocation(LocationSectionDTO: LocationDTO, incidentId: string) {
    const url = `/BCU/Location/Map/Save/${incidentId}`;
    return this._apiService.post(url, LocationSectionDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }


  LocationTypeDesider(locatuionType : string) : number{
    let result : number;
    switch (locatuionType) {
      case 'PoliceCordon':
        result = 0;
        break;

        case 'RendezvousPoint':
        result = 1;
        break;

        case 'CrimeScene':
        result = 2;
        break;

      default:
        result = 0;
    }

    return result;
  }
}
