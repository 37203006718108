<!-- <div class="container-error local-auth-btn">
    <div class="accordion max-width-popup tableaccordian error-accordian mx-auto remove-margin error-model-width" id="contactaccordian1">
      <div class="accordion-item">
        <h2 class="accordion-header" id="contactheading1">
          <button class="accordion-button remove-after collapsed remove-background-img hideHand" type="button"
            >
            {{tital}}

            <img (click)="btnCloseClicked()" class="float-right showHand" src="../../../assets/images/cross-icon.png">
          </button>
        </h2>
        <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
          data-bs-parent="#contactaccordian">
          <div class="accordion-body">
            <div class="flex d-flex align-items-center justify-content-center text-center py-25 border-bottom border-3 px-3 more-height">
              <p>{{body}}</p>
            </div>
            <div class="flex align-items-center justify-content-center text-center py-25 px-3">
              <button (click)="btnCloseClicked()" class="btn bg_gradient text-white">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div> -->


  <div class="container-error local-auth-btn">
    <div class="accordion tableaccordian error-accordian w-75 mx-auto remove-margin-border min-w-400" id="contactaccordian1">
      <div class="accordion-item">
        <h2 class="accordion-header" id="contactheading1">
          <button class="accordion-button collapsed remove-after hideHand" type="button">
            {{tital}}

            <img (click)="btnCloseClicked()" class="float-right showHand" src="../../../assets/images/cross-icon.png">
          </button>
        </h2>
        <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
          data-bs-parent="#contactaccordian">
          <div class="accordion-body">
            <div class="flex align-items-center justify-content-center text-center py-37 border-bottom border-3 px-3">
              <p>{{body}}</p>
            </div>
            <div class="flex align-items-center justify-content-center text-center py-37 px-3">
              <button (click)="btnCloseClicked()"  class="btn bg_gradient text-white">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
