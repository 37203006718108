<div class="container">

  <div class="header-logo d-flex justify-content-center align-content-center">
      <img src="../../assets/images/vru-logo1.png" alt="vru-logo">
      <img src="../../assets/images/sim-logo1.png" alt="sim-logo">
  </div>

  
  <div class="align-content-center">

    
    <div class="form-wrapper" *ngIf="isCurrentUserLoaded && isMemberProfileLoaded">
      <form [formGroup]="signUpForm" (submit)="OnSubmit()"  *ngIf="!showConfirmationForm">
        <div>
    
          <!-- <div class="col-12 field-margin">
            <div class="hs_icon_input">
              <input class="form-control" type="text" placeholder="Given name" 
                formControlName="givenName">
            </div>
            <div *ngIf="(isSubmitted ||
            signUpForm.controls.givenName.touched)">
                <span class="text-danger"
                  *ngIf="signUpForm.controls.givenName.hasError('required')">
                  Given name is required</span>
              </div>
          </div>
    
          <div class="col-12 field-margin">
            <div class="hs_icon_input">
              <input class="form-control" type="text" placeholder="Family name" 
                formControlName="familyName">
            </div>
            <div *ngIf="(isSubmitted ||
            signUpForm.controls.familyName.touched)">
                <span class="text-danger"
                  *ngIf="signUpForm.controls.familyName.hasError('required')">
                  Family name is required</span>
              </div>
          </div> -->
    
          <div class="col-md-12 field-margin">
            <div class="hs_icon_input">
              <input class="form-control" type="text" placeholder="Email" 
                formControlName="email">
            </div>
            <div *ngIf="(isSubmitted || signUpForm.controls.email.touched)">
                <div class="text-danger"
                  *ngIf="signUpForm.controls.email.hasError('required')">
                  Email is required
                </div>

                <div class="text-danger"
                  *ngIf="signUpForm.controls.email.hasError('email')">
                  Email is not in correct formate
                </div>
              </div>
          </div>
    
    
          <!-- <div class="col-12 field-margin">
            <div class="hs_icon_input">
              <input class="form-control" type="text" placeholder="Mobile number" 
                formControlName="phoneNumber">
            </div>
            <div *ngIf="(isSubmitted ||
            signUpForm.controls.phoneNumber.touched)">
                <span class="text-danger"
                  *ngIf="signUpForm.controls.phoneNumber.hasError('required')">
                  Mobile number is required</span>
              </div>
          </div> -->
    
          <div class="col-md-12 field-margin">
            <div class="hs_icon_input">
              <input class="form-control" type="password" placeholder="Password" 
                formControlName="password">
            </div>
            <div *ngIf="(isSubmitted ||
            signUpForm.controls.password.touched)">
                <div class="text-danger"
                  *ngIf="signUpForm.controls.password.hasError('required')">
                  Password is required
                </div>

                <div class="text-danger"
                  *ngIf="signUpForm.controls.password.hasError('minlength')">
                  Password length atleast 8 charactor long
                </div>

                <div class="text-danger"
                    *ngIf="signUpForm.controls.password.hasError('containsSpaces')">
                    Password must not contain leading or trailing spaces.
                </div>
              </div>
          </div>

          <div class="col-md-12 field-margin">
            <div class="hs_icon_input">
              <input class="form-control" type="password" placeholder="Confirm Password" 
                formControlName="confirmPassword">
            </div>
            <div *ngIf="(isSubmitted ||
            signUpForm.controls.confirmPassword.touched)">
                <div class="text-danger"
                  *ngIf="signUpForm.controls.confirmPassword.hasError('required')">
                  Confirm Password is required</div>

                <div class="text-danger"
                  *ngIf="signUpForm.controls.confirmPassword.hasError('confirmPassword')">
                  Confirm Password does not matched with Password</div>
              </div>
          </div>

          <div class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
    
          <div>
            <button type="submit"  class="primary-btn m-3">Submit</button>
          </div>

          
    
          
    
          <!-- <input type="text" formControlName="" placeholder="Given name">
          <input type="text" name="familyName" [(ngModel)]="user.familyName" placeholder="Last name">
          <input type="text" name="email" [(ngModel)]="user.email" placeholder="Eamil">
          <input type="text" name="password" [(ngModel)]="user.password" placeholder="Password"> -->
          <!-- <input type="text" name="phoneNumber" [(ngModel)]="user.phone_number" placeholder="Mobile number"> -->
          
        </div>
      </form>
    
        <form [formGroup]="confirmSignUpForm" (ngSubmit)="onSubmitConfirmation()" *ngIf="showConfirmationForm">
          <div>

            <div class="col-12 field-margin">
              <div class="hs_icon_input">
                <input class="form-control" type="text" placeholder="Email" 
                  formControlName="email">
              </div>
              <div *ngIf="(isConfirmSubmitted ||
              confirmSignUpForm.controls.email.touched)">
                  <span class="text-danger"
                    *ngIf="confirmSignUpForm.get('email').hasError('required')">
                    Email is required</span>
                    <span class="text-danger"
                    *ngIf="confirmSignUpForm.get('email').hasError('email')">
                    Email is not in correct formate</span>
                </div>
            </div>

            <div class="col-12 field-margin">
              <div class="hs_icon_input">
                <input class="form-control" placeholder="Confirmation Code" 
                  formControlName="confirmationCode">
              </div>
              <div *ngIf="(isConfirmSubmitted ||
              confirmSignUpForm.controls.confirmationCode.touched)">
                  <span class="text-danger"
                    *ngIf="confirmSignUpForm.controls.confirmationCode.hasError('required')">
                    Confirmation code is required</span>
                </div>
            </div>

          </div>
          <!-- <div>
            <label for="confirmationCode">Confirmation Code:</label>
            <input type="text" id="confirmationCode" formControlName="confirmationCode" required>
            <div class="text-danger" *ngIf="confirmSignUpForm.get('confirmationCode').hasError('required')">
              Confirmation code is required
            </div>
          </div> -->
          <button type="submit" class="btn btn-primary m-2">Confirm</button>
          <button type="button" class="btn btn-danger m-2" (click)="onResendConfirmationCode()">Resend Confirmation Code</button>
          <div class="text-danger" *ngIf="errorMessageConfirm">{{ errorMessageConfirm }}</div>
        </form>
    
      <!-- <div class="card-body" *ngIf="isConfirm">
        <div class="row">
          <div class="col-md-3 mb-2">
            <label for="code" class="form-label">Code:</label>
            <input type="text" id="code" name="code" #code="ngModel" [(ngModel)]="user.code" class="form-control form-control-sm">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 d-grid">
            <button type="button" (click)="confirmSignUp()" class="btn btn-sm btn-success" [disabled]="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              Confirm
            </button>
          </div>
        </div>
      </div> -->
    </div>
    <div class="divloader" *ngIf="!isCurrentUserLoaded || !isMemberProfileLoaded">
      <mat-spinner diameter="50"></mat-spinner>
  </div>
    
  </div>
  
</div>
