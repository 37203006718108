import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class StakeholderMembersService {

  constructor(private _apiService: ApiService) {}

  // GetStakeHolderDashBoard() {
  //   const url = "/la/StakeholderMessaging/Dashboard";
  //   return this._apiService.get(url).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );
  // }

  GetStakeHolderMembers() {
    const url = `/Manager/Stakeholders/ListStakeholdersByGroup`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetStakeHolderMembersWithAdditional() {
    const url = `/Manager/Stakeholders/ListStakeholdersByGroupIncludeAdditional`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }


  deleteStakeholder(id: string){
    const url = `/manager/stakeholders/deletestakeholder/${id}`;
    return this._apiService.put(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

}
