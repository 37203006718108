import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarService } from '../nav-menu/navbar.service';
import { Subscription } from 'rxjs';
import { AppStartService } from '../core/services/app-start.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  navmenuClass: string;
  CurrentUrlChangesSubscription= new Subscription();

  constructor(
    private _navbarService: NavbarService
  ) { }

  ngOnInit() {
    this.CurrentUrlChangesSubscription = this._navbarService.navbarClassSubject.subscribe(x => this.navmenuClass = x);
  }

  ngOnDestroy(): void {
    if (this.CurrentUrlChangesSubscription){
      this.CurrentUrlChangesSubscription.unsubscribe();
    }
  }

}
