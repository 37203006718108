<app-home-nav-menu></app-home-nav-menu>

<div class="team-protocol-section pt-15 pb-50 local-auth-17">
  <div class="container cst-protocol Team_protocol_action">
    <div class="w-100">
      <h2 class="sec-title">CST Protocol Launch</h2>

      <div class="back-to-dashboard">
        <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png" alt="left-angle-primary"> Back to dashboard</a>
      </div>
      
      <div class="accordion tableaccordian" id="contactaccordian" *ngIf="isBasicTemplatesLoaded && incidentLoaded && isTeamMembersLoaded">
        <div class="teamprotocol-heading with-dropdown">
          <div class="t_h2 main-dropdown-js">
            <h1>
              <!-- <strong>Launch CST protocol for Fatal Stabbing</strong> -->
              <strong>Launch CST protocol for {{incident.title}}</strong>
              <!-- Protocol Response template for <span class="rel each-dropdown-js" rel="gt_dropdown"> Gang related violence</span>
              for <span class="rel each-dropdown-js" rel="gt_dropdown2"> everyone </span> -->
            </h1>
            <div class="dropdown tab-title ">
              <!-- <button class="dropdown-toggle hsr-btn" type="button" id="launchprotocol2" data-bs-toggle="dropdown" aria-expanded="false">+ NEW</button> -->
              <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
                <li><a class="no-img" href="#">Incident</a></li>
                <li><a class="no-img" href="#">Copy this one</a></li>
              </ul>
            </div>
            <ul class="t-dropdown each-dropdown-content-js" id="gt_dropdown">
              <li><button type="button" class="link">Stabbing at educational setting</button></li>
              <li><button type="button" class="link">Fatal firearms incident</button></li>
            </ul>
            <ul class="t-dropdown each-dropdown-content-js" id="gt_dropdown2">
              <li><button type="button" class="link">Everyone</button></li>
              <li><button type="button" class="link">Mackenzie Thornton</button></li>
              <li><button type="button" class="link">Shania Barker</button></li>
              <li><button type="button" class="link">Romeo Foley</button></li>
              <li><button type="button" class="link">Carl Salazar</button></li>
            </ul>
          </div>
        </div>
        <div class="accrord-subtitle">
          <span class="t_h2 main-dropdown-js">
            <span>Incident template title &nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span rel="t_dropdownTemplates" class="rel each-dropdown-js"><img src="assets/images/pencil-icon.png" alt="pencil-icon"> 
              {{(selectedTemplate != undefined && selectedTemplate != null) ? selectedTemplate.templateName : 'No Template Selected'}}
            </span>
            <ul id="t_dropdownTemplates" class="t-dropdown each-dropdown-content-js">
              <li *ngFor="let template of templates">
                <button type="button" class="link text-nowrap" (click)="templateSelected(template)">
                  {{template.templateName}}
                </button>
              </li>
            </ul>
          </span>
          
          <!-- <div class="date-wrap">
            <p>Created: <span>22:30, April 10</span></p>
            <p>Modified: <span>22:30, April 1</span></p>
          </div> -->
        </div>

        <div *ngIf="isIncidentLaunchdataLoaded == 2">
          <div class="accordion-item" *ngFor="let incidentLaunch of incidentLaunchData.taskGroups; trackBy: trackByFn; index as i">
            <h2 class="accordion-header" [attr.id]="'contactheading' + i">
              <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#contactaccord' + i" aria-expanded="false"
                [attr.aria-controls]="'contactaccord' + i">
                {{incidentLaunch?.title + ' - ' + GetTimeFram(incidentLaunch.taskTimeFrame)}}
              </button>
            </h2>
            <div [attr.id]="'contactaccord' + i" class="accordion-collapse collapse"
              [attr.aria-labelledby]="'contactheading' + i"
              data-bs-parent="#contactaccordian">
              <div class="accordion-body">
                <div class="table-responsive local-action-table accord-table">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="border-end-0">Person</th>
                        <th class="border-start-0 border-end-0">Action</th>
                        <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                        <!-- <th class="border-start-0"><input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value=""> -->
                        <!-- <th class="border-start-0"><input class="table-checkbox form-check-input border-check" type="checkbox" value=""> -->
                        <th class="border-start-0">
                          <input [id]="'taskCheckbox' + i" class="table-checkbox
                            border-check
                            form-check-input" [class]="'chkSame' + i"
                            type="checkbox" value=""
                            (change)="allCheckChanged($event, i,
                            incidentLaunch)">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let task of incidentLaunch.tasks; index as ii">
                        <td>
                          <div class="dropdown profile-dd">

                            <button (click)="personClicked(teamMemebers, task, i, ii)" class="btn dropdown-toggle" type="button" id="incidentdropdown"> 
                              
                              <img [src]="task?.incidentTaskAssignees[0]?.avatar ? task?.incidentTaskAssignees[0]?.avatar : 'assets/images/profile-blank-icon.png'" alt="user-profile" class="user-profile">
                              <span>{{GetStringOfTeamMembersList(task?.incidentTaskAssignees)}}</span>
                            </button>

                            <!-- <button (click)="personClicked(teamMemebers, task, i, ii)" class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                              
                              <img src="assets/images/profile-blank-icon.png" alt="user-profile" class="user-profile">
                              <span>{{GetStringOfTeamMembersList(task?.incidentTaskAssignees)}}</span>
                            </button> -->
                            <!-- <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                              <li *ngFor="let assignee of task?.incidentTaskAssignees"><a href="#">{{assignee.assigneeName}}</a></li>
                              <li *ngFor="let teamMember of teamMemebers"><span (click)="AssignMember(teamMember, task, i, ii)" class="showHand">{{teamMember.firstName + ' ' + teamMember.familyName}}</span></li>
                            </ul> -->
                          </div>
                        </td>
                        <td class="border-start-0 border-end-0">{{task?.title}}</td>
                        <td class="text-end border-start-0 border-end-0">
                          <span *ngIf="task?.taskTimeFrame != undefined && task?.taskTimeFrame != null && task?.taskTimeFrame > 0" 
                          class="status-btn timeline">{{GetTimeFram(task?.taskTimeFrame)}}</span>
                        </td>
                        <td class="border-start-0">
                          <input class="table-checkbox border-check
                          form-check-input" [class]="'taskCheckbox' + i"
                          type="checkbox" (change)="checkboxChanged($event, task)">
                          <!-- <input class="table-checkbox form-check-input border-style" type="checkbox" value=""> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        
      </div>


      <div *ngIf="isBasicTemplatesLoaded && isTeamMembersLoaded && isIncidentLaunchdataLoaded == 2">
        <div class="bottom-submit">
          <div class="dropdown  local-action-btn action-final">
            <button class="dropdown-toggle m-0 hsr-btn down-caret" type="button" id="launchprotocol2" data-bs-toggle="dropdown" aria-expanded="false">Update</button>
            
            <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
              <li *ngFor="let teamMember of teamMemebers">
                <div (click)="bulkUpdateClicked(teamMember)">
                  <a>
                    <img class="profile-image" [src]="teamMember.avatar ? teamMember.avatar : 'assets/images/profile-blank-icon.png'" alt="profile-icon">{{teamMember.firstName + ' ' + teamMember.familyName}}
                  </a>
                </div>
              </li>
              <!-- <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Mackenzie Thornton</a></li>
              <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Shania Barker</a></li>
              <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Romeo Foley</a></li>
              <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Carl Salazar</a></li> -->
            </ul>
          </div>
        </div>
  
        <div class="d-flex justify-content-end mt-2">
          <button *ngIf="!isLaunched" class="m-0 hsr-btn" type="button" (click)="SaveAndLaunchTheProtocol()">Launch Protocol</button>
          <div class="divloader remove-height" *ngIf="isLaunched">
            <mat-spinner diameter="20"></mat-spinner>
          </div>
        </div>
      </div>

      <!-- <div id="divloader" *ngIf="isIncidentLaunchdataLoaded == 1 || !isBasicTemplatesLoaded || !incidentLoaded || !isTeamMembersLoaded">
        <span>↓</span>
        <span style="--delay: 0.1s">↓</span>
        <span style="--delay: 0.3s">↓</span>
        <span style="--delay: 0.4s">↓</span>
        <span style="--delay: 0.5s">↓</span>
      </div> -->

      <div class="divloader" *ngIf="isIncidentLaunchdataLoaded == 1 || !isBasicTemplatesLoaded || !incidentLoaded || !isTeamMembersLoaded">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>





<!-- <div class="team-protocol-section pt-30 pb-50 local-auth-17">
  <div class="container cst-protocol Team_protocol_action">
    <div class="w-100">
      <div class="back-to-dashboard">
        <a href="#"><img src="assets/images/left-angle-primary.png" alt="left-angle-primary"> Back to dashboard</a>
      </div>
      <h2 class="sec-title">CST Template</h2>
      <div class="accordion tableaccordian" id="contactaccordian">
        <div class="teamprotocol-heading with-dropdown">
          <div class="t_h2 main-dropdown-js">
            <h1>
              Protocol Response template for <span class="rel each-dropdown-js" rel="gt_dropdown"> Gang related violence</span>
              for <span class="rel each-dropdown-js" rel="gt_dropdown2"> everyone </span>
            </h1>
            <div class="dropdown tab-title ">
              <button class="dropdown-toggle hsr-btn" type="button" id="launchprotocol2" data-bs-toggle="dropdown" aria-expanded="false">+ NEW</button>
              <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
                <li><a class="no-img" href="#">Incident</a></li>
                <li><a class="no-img" href="#">Copy this one</a></li>
              </ul>
            </div>
            <ul class="t-dropdown each-dropdown-content-js" id="gt_dropdown">
              <li><button type="button" class="link">Stabbing at educational setting</button></li>
              <li><button type="button" class="link">Fatal firearms incident</button></li>
            </ul>
            <ul class="t-dropdown each-dropdown-content-js" id="gt_dropdown2">
              <li><button type="button" class="link">Everyone</button></li>
              <li><button type="button" class="link">Mackenzie Thornton</button></li>
              <li><button type="button" class="link">Shania Barker</button></li>
              <li><button type="button" class="link">Romeo Foley</button></li>
              <li><button type="button" class="link">Carl Salazar</button></li>
            </ul>
          </div>
        </div>
        <div class="accrord-subtitle">
          <p>Incident temaplate title &nbsp;&nbsp;&nbsp;&nbsp;<a><img src="assets/images/pencil-icon.png" alt="pencil-icon"> <strong>Gang related violence</strong></a></p>
          <div class="date-wrap">
            <p>Created: <span>22:30, April 10</span></p>
            <p>Modified: <span>22:30, April 1</span></p>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord1" aria-expanded="true" aria-controls="contactaccord1">
              Communication
            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="border-end-0">Person</th>
                      <th class="border-start-0 border-end-0">Action</th>
                      <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                      <th class="border-start-0"><input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd not-required">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/profile-blank-icon.png" alt="profile-blank-icon" class="user-profile">
                            <span>Not required</span>
                          </button>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 WEEK</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 MONTH</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">3 MONTHS</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading2">
            <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord2" aria-expanded="false" aria-controls="contactaccord2">
              Safeguarding and support
            </button>
          </h2>
          <div id="contactaccord2" class="accordion-collapse collapse" aria-labelledby="contactheading2"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="border-end-0">Person</th>
                      <th class="border-start-0 border-end-0">Action</th>
                      <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                      <th class="border-start-0"><input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 WEEK</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 MONTH</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">3 MONTHS</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading3">
            <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord3" aria-expanded="false" aria-controls="contactaccord3">
              Hospital
            </button>
          </h2>
          <div id="contactaccord3" class="accordion-collapse collapse" aria-labelledby="contactheading3"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="border-end-0">Person</th>
                      <th class="border-start-0 border-end-0">Action</th>
                      <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                      <th class="border-start-0"><input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 WEEK</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 MONTH</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">3 MONTHS</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading4">
            <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord4" aria-expanded="false" aria-controls="contactaccord4">
              Educational settings
            </button>
          </h2>
          <div id="contactaccord4" class="accordion-collapse collapse" aria-labelledby="contactheading4"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="border-end-0">Person</th>
                      <th class="border-start-0 border-end-0">Action</th>
                      <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                      <th class="border-start-0"><input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 WEEK</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 MONTH</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">3 MONTHS</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading5">
            <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord5" aria-expanded="false" aria-controls="contactaccord5">
              Additional resource deployment
            </button>
          </h2>
          <div id="contactaccord5" class="accordion-collapse collapse" aria-labelledby="contactheading5"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="border-end-0">Person</th>
                      <th class="border-start-0 border-end-0">Action</th>
                      <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                      <th class="border-start-0"><input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 WEEK</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 MONTH</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">3 MONTHS</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading6">
            <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord6" aria-expanded="false" aria-controls="contactaccord6">
              Family
            </button>
          </h2>
          <div id="contactaccord6" class="accordion-collapse collapse" aria-labelledby="contactheading6"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="border-end-0">Person</th>
                      <th class="border-start-0 border-end-0">Action</th>
                      <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                      <th class="border-start-0"><input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">24 HR</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 WEEK</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">1 MONTH</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr><tr>
                      <td>
                        <div class="dropdown profile-dd">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"> 
                            <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                            <span>Peter Dickenson</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
                            <li><a href="#">Mackenzie Thornton </a></li>
                            <li><a href="#">Shania Barker</a></li>
                            <li><a href="#">Romeo Foley</a></li>
                            <li><a href="#">Carl Salazar</a></li>
                          </ul>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">Share partner information (police, VRP etc)</td>
                      <td class="text-end border-start-0 border-end-0"><span class="status-btn timeline">3 MONTHS</span></td>
                      <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-submit">
        <div class="dropdown  local-action-btn action-final">
          <button class="dropdown-toggle m-0 hsr-btn down-caret" type="button" id="launchprotocol2" data-bs-toggle="dropdown" aria-expanded="false">Edit</button>
          <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Mackenzie Thornton</a></li>
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Shania Barker</a></li>
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Romeo Foley</a></li>
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Carl Salazar</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> -->
