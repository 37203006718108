<app-home-nav-menu [componentName]="''"></app-home-nav-menu>

<div *ngIf="!isDataLoading">


  <!-- Incidents -->
  <div class="pt-17 pb-50 second-incident-sec"
    *ngIf="!isdashBoardIncedenceDataLoading">
    <div class="container">
      <div class="table-responsive table-style local-authority-accord">
        <table class="table table-hover table-bordered">
          <thead class="incident_toggle-view-js">
            <!-- <tr class="only-text-withbg">
              <td colspan="2"></td>
              <td>LA have confirmed</td>
              <td>Protocol launched</td>
              <td colspan="3"></td>
            </tr> -->
            <tr>
              <th class="incident_toggle incident_toggle-js">
                <span class="text-down-angle">
                  Incidents
                </span>
              </th>
              <th>
                <span class="inc-title">Pending incidents</span>
                <span class="text-circle pending latter-spaccing">{{dashBoardIncedenceData.pendingTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Immediate response</span>
                <span class="text-circle response_in_progress latter-spaccing">{{dashBoardIncedenceData.responseInProgressTotal}}</span>
              </th>
              <th>
                <span class="inc-title">CST <br>protocol</span>
                <span class="text-circle protocol_in_progress latter-spaccing">
                  {{dashBoardIncedenceData.protocolInProgressTotal > 0 ?
                  dashBoardIncedenceData.protocolInProgressTotal : 0}}
                </span>
              </th>
              <!-- <th class="bg_gradient">
                <div class="option-card">
                  <a [routerLink]="['/new-incident']"
                    class="title-icon text-white d-flex flex-wrap flex-wrap">
                    <img src="assets/images/hamburger-white.png"
                      alt="hamburger-white">
                    <h3 style="margin: 0px;">Start <br> Incident</h3>
                  </a>
                </div>
              </th> -->
            </tr>
          </thead>
          <tbody class="incident_toggle-content-js">
            <tr class="showHand" *ngFor="let dashBoardIncedence of
              dashBoardIncedenceData.incidents"
              (click)="incidentSummaryPreProtocolLaunchClicked(dashBoardIncedence.id)">
              <!-- class="text-nowrap" -->
              <td>
                <p class="incidentTitle"><strong>{{dashBoardIncedence.title}}</strong> &nbsp;&nbsp;<span>{{dashBoardIncedence.datetime | date:'HH:mm EEE d MMMM'}}</span></p>
                <p>{{dashBoardIncedence.location}}</p></td>
              <td class="text-right text-nowrap" colspan="4">
                <span *ngIf="dashBoardIncedence.incidentStatus === 2"
                  class="status-btn scaled-down">
                  Scaled down
                </span>
                <span *ngIf="dashBoardIncedence.incidentStatus === 0"
                class="status-btn pending"
                (click)="IncidentsPendingTagClicked(dashBoardIncedence)">
                PENDING
              </span>
                <span *ngIf="dashBoardIncedence.responseStatus === 0"
                  class="status-btn emergency"
                  (click)="IncidentsEmergencyTagClicked(dashBoardIncedence)">
                  Immediate
                </span>
                <span *ngIf="dashBoardIncedence.protocolStatus === 0 && dashBoardIncedence.incidentStatus === 1"
                  class="status-btn cst"
                  (click)="IncidentsCSTTagClicked(dashBoardIncedence)">
                  CST
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

    
  <div class="pb-50 ">
    <div class="container">

      <!-- Your Actions -->
      <div class="table-responsive table-style local-authority-accord subaction"
        *ngIf="!isdashboardActionsDataLoading">
        <table class="table table-hover table-bordered">
          <thead class="incident_toggle-view-js">
            <tr class="only-text-withbg">
              <td></td>
              <td></td>
              <td class="bg-blue-63 text-white border-right-3 border-color-grey"
                colspan="2">Immediate response</td>
              <td class="bg-yellow-ec text-white border-left-3
                border-color-grey" colspan="4">CST</td>
            </tr>
            <tr>
              <th class="incident_toggle incident_toggle-js border-end-0">
                <span class="text-down-angle"> Your Actions </span>
              </th>
              <th></th>
              <th>
                <span class="inc-title">Pending</span>
                <span class="text-border-circle pending latter-spaccing">{{dashboardActionsData.responsePending}}</span>
              </th>
              <th class="border-right-3 border-color-grey">
                <span class="inc-title">Active</span>
                <span class="text-border-circle active latter-spaccing">{{dashboardActionsData.responseActive}}</span>
              </th>
              <th class="border-left-3 border-color-grey">
                <span class="inc-title">Pending</span>
                <span class="text-border-circle pending latter-spaccing">{{dashboardActionsData.pendingTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Active</span>
                <span class="text-border-circle active latter-spaccing">{{dashboardActionsData.acceptedTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Late</span>
                <span class="text-border-circle late latter-spaccing">{{dashboardActionsData.lateTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Issues</span>
                <span class="text-border-circle issues latter-spaccing">{{dashboardActionsData.issuesTotal}}</span>
              </th>
            </tr>
          </thead>
          <tbody class="incident_toggle-content-js">
            <tr *ngFor="let incedent of dashboardActionsData.incidentTotals">
              <td class="border-start-0">
                
                <!-- <p class="incidentTitle"><strong>{{incedent.title}}</strong> &nbsp;&nbsp;<span>{{incedent.datetime}}</span></p>
                <p>{{incedent.location}}</p> -->

                <strong>{{incedent.title}}</strong>
                <p>{{incedent.dateTime | date:'HH:mm EEE d MMMM'}}</p> {{incedent.location}}
                
              </td>
              <td class="border-start-0 text-right text-nowrap">
                <span class="status-btn emergency showHand"
                  (click)="YourActionsEmergencyClicked(incedent)"
                  *ngIf="incedent.responseStatus == 0">
                  Immediate
                </span>
                <span class="status-btn cst showHand"
                  (click)="YourActionsCSTClicked(incedent)"
                  *ngIf="incedent.protocolStatus == 0">
                  CST
                </span>
              </td>
              <td (click)="yourActionTrClicked('Pending',incedent)"
                class="showHand ">
                <span *ngIf="incedent.responseStatus == 0" class="text-circle latter-spaccing" [class]="incedent.emergencyResponsePending > 0 ? 'pending' : 'active'">
                  {{incedent.emergencyResponsePending}}
                </span>
              </td>
              <td (click)="yourActionTrClicked('Active', incedent)"
                class="showHand border-right-3 border-color-grey">
                <span *ngIf="incedent.responseStatus == 0" class="text-border-circle active latter-spaccing">
                  {{incedent.emergencyResponseActive}}
                </span>
              </td>
              <td class="showHand border-left-3 border-color-grey" (click)="YourTeamRowDiscClicked('Pending', incedent.id)">
                <span *ngIf="incedent.protocolStatus == 0" class="text-circle latter-spaccing" [class]="incedent.pending > 0 ? 'pending' : 'active'">
                  {{incedent.pending}}
                </span>
              </td>
              <td class="showHand" (click)="YourTeamRowDiscClicked('Accepted', incedent.id)">
                <span *ngIf="incedent.protocolStatus == 0" class="text-circle active latter-spaccing">{{incedent.accepted}}</span>
              </td>
              <td class="showHand" (click)="YourTeamRowDiscClicked('Late', incedent.id)">
                <span *ngIf="incedent.protocolStatus == 0" class="text-circle latter-spaccing" [class]="incedent.late > 0 ? 'late' : 'active'">
                  {{incedent.late}}
                </span>
              </td>
              <td class="showHand"  (click)="YourTeamRowDiscClicked('Active', incedent.id)">
                <span *ngIf="incedent.protocolStatus == 0" class="text-circle latter-spaccing" [class]="incedent.issues > 0 ? 'issues' : 'active'">
                  {{incedent.issues}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <!-- Your Team actions -->
      <div class="table-responsive table-style local-authority-accord subaction"
        *ngIf="!isdashboardTeamActionsDataLoading">
        <table class="table table-hover table-bordered">
          <thead class="incident_toggle-view-js">
            <tr class="only-text-withbg">
              <td colspan="2" class="border-right-3 border-color-grey"></td>
              <td class="bg-yellow-ec text-white border-left-3
                border-color-grey" colspan="6">CST</td>
            </tr>
            <tr>
              <th colspan="2" class="incident_toggle incident_toggle-js
                border-right-3 border-color-grey">
                <span class="text-down-angle"> Your Team actions </span>
              </th>
              <th class="border-left-3 border-color-grey">
                <span class="inc-title">Pending</span>
                <span class="text-border-circle pending latter-spaccing">{{dashboardTeamActionsData.pendingTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Declined</span>
                <span class="text-border-circle declined latter-spaccing">{{dashboardTeamActionsData.declinedTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Active</span>
                <span class="text-border-circle active latter-spaccing">{{dashboardTeamActionsData.acceptedTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Late</span>
                <span class="text-border-circle pending latter-spaccing">{{dashboardTeamActionsData.lateTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Complete</span>
                <span class="text-border-circle complete latter-spaccing">{{dashboardTeamActionsData.completeTotal}}</span>
              </th>
              <th>
                <span class="inc-title">Issues</span>
                <span class="text-border-circle issues latter-spaccing">{{dashboardTeamActionsData.issuesTotal}}</span>
              </th>
            </tr>
          </thead>
          <tbody class="incident_toggle-content-js">
            <tr *ngFor="let incedent of
              dashboardTeamActionsData.incidentTotals">
              <td (click)="yourTeamTitalClicked(incedent.id)"
                class="border-start-0 showHand">
                <strong>{{incedent.title}}</strong>
                <p>{{incedent.dateTime | date:'HH:mm EEE d MMMM'}}</p> {{incedent.location}}</td>
              <td (click)="yourTeamTitalClicked(incedent.id)" class="text-right
                border-start-0 border-right-3 border-color-grey showHand">
                <span class="status-btn emergency"
                  (click)="YourTeamsActionsEmergencyClicked(incedent)"
                  *ngIf="incedent.responseStatus == 0">
                  Immediate
                </span>
                <span class="status-btn cst"
                  (click)="YourTeamsActionsCSTClicked(incedent)"
                  *ngIf="incedent.protocolStatus == 0">
                  CST
                </span>
              </td>

              <td class="border-left-3 border-color-grey showHand"
                (click)="YourTeamRowDiscClicked('Pending', incedent.id)">
                <span class="text-circle latter-spaccing" [class]="incedent.pending > 0 ? 'pending' : 'active'"  *ngIf="incedent.protocolStatus == 0">
                  {{incedent.pending}}
                </span>
              </td>
              <td class="showHand" (click)="YourTeamRowDiscClicked('Declined',
                incedent.id)">
                <span class="text-border-circle declined latter-spaccing"  *ngIf="incedent.protocolStatus == 0">
                  {{incedent.declined}}
                </span>
              </td>
              <td class="showHand" (click)="YourTeamRowDiscClicked('Active',
                incedent.id)">
                <span class="text-border-circle active latter-spaccing"  *ngIf="incedent.protocolStatus == 0">
                  {{incedent.accepted}}
                </span>
              </td>
              <td class="showHand" (click)="YourTeamRowDiscClicked('Late',
                incedent.id)">
                <span class="text-border-circle latter-spaccing" [class]="incedent.late > 0 ? 'late' : 'active'"  *ngIf="incedent.protocolStatus == 0">
                  {{incedent.late}}
                </span>
              </td>
              <td class="showHand" (click)="YourTeamRowDiscClicked('Done',
                incedent.id)">
                <span class="text-border-circle complete latter-spaccing"  *ngIf="incedent.protocolStatus == 0">
                  {{incedent.completed}}
                </span>
              </td>
              <td class="showHand" (click)="YourTeamRowDiscClicked('Issue',
                incedent.id)">
                <span class="text-border-circle latter-spaccing" [class]="incedent.issues > 0 ? 'issues' : 'active'"  *ngIf="incedent.protocolStatus == 0">
                  {{incedent.issues}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>


  <!-- contacts -->
  <div class="pb-50">
    <div class="container local-auth-btn">
      <div class="accordion tableaccordian" id="contactaccordian">
        <div class="accordion-item" id="contacts">
          <h2 class="accordion-header m-0" id="contactheading1">
            <button class="accordion-button" type="button"
              data-bs-toggle="collapse" success
              data-bs-target="#contactaccord1" aria-expanded="false"
              aria-controls="contactaccord1">
              Local Authority contacts
            </button>
            <!-- <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactaccord1"
                aria-expanded="true" aria-controls="contactaccord1">
                Local Authority contacts
              </button> -->
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse"
            aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian" style="">
            <div class="accordion-body">
              <div class="table-responsive accord-table">
                <table class="table table-hover" *ngFor="let dashBoardContact of dashBoardContacts">
                  <thead>
                    <tr>
                      <th colspan="5" class="cnt-table-title">{{dashBoardContact.groupName}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let contact of dashBoardContact.contacts">
                      <td>
                        <img class="profile-icon"
                          [src]="contact.avatar ? contact.avatar : 'assets/images/profile-blank-icon.png'" alt="profile-picture">
                      </td>
                      <td><span class="cnt-name">{{contact.name}}</span></td>
                      <td><span class="cnt-occupation">{{contact.jobTitle}}</span></td>
                      <td>

                        <a class="mailto-link" *ngIf="contact.email"
                          [href]="'mailto:' + contact.email">
                          <img src="assets/images/mail-icon-new.png" alt="mail">
                          {{contact.email}}
                        </a>

                        <img *ngIf="!contact.email"
                            src="assets/images/mail-icon-unable.png" alt="mail">

                      </td>
                      <td>

                        <a class="tel-link" *ngIf="contact.phone" [href]="'tel:' + contact.phone"><img
                            src="assets/images/phone-icon-new.png"
                            alt="phone">{{contact.phone}}</a>

                        <img *ngIf="!contact.phone"
                          src="assets/images/assets/images/phone-icon-unable.png" alt="phone">

                      </td>
                    </tr>
                  </tbody>
                </table>
                    
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="divloader" *ngIf="isDataLoading">
    <mat-spinner diameter="50"></mat-spinner>
</div>
