<!-- <div class="modal map-modal-style show" id="cordon-one-Modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close"></button>
      </div>
      <div class="modal-body d-flex align-items-center">
        <div class="body-contain">
          <div class="location-tab-input">
            <div class="form-label ">
              <label for="cordonone-check">Crime scene</label>
            </div>
            <div class="hs-input">
              <div class="row w-331">
                <div class="col-12 col-md-5">
                  <input class="form-control" type="text" id="post_code" name="PostCode" value="Crime scene 1" placeholder="" required="">
                </div>
              </div>
            </div>
          </div>
          <div class="map-iframe">
            <agm-map [latitude]="InitialLatitude" [longitude]="InitialLongitude"
              [zoom]="InitialZoom" [zoomControl]="true"
              [fullscreenControl]='true' [mapTypeControl]='true'>
              
            </agm-map>

            <agm-map [zoom]="InitialZoom" [latitude]="InitialLatitude"
                            [longitude]="InitialLongitude"
                            (mapReady)="onMapReadyForCrimeSceneOnlyShowMap($event)"
                            [fullscreenControl]='true'
                            [mapTypeControl]='true'>
                            <agm-polygon
                                >
                            </agm-polygon>
                          </agm-map>
          </div>
          <div class="location-disc-input">
            <div class="form-label ">
              <label for="initialLocation">Description</label>
            </div>
            <div class="hs-input">
              <div class="row mb-0 mb-md-2">
                <div class="col-12 col-md-9">
                  <textarea class="form-control" id="incidentDetails" placeholder=""
                    required=""></textarea>
                    <p class="last-update-text">Updated 22:40, April 10</p>
                </div>
                <div class="col-12 col-md-3 text-end d-flex align-items-end justify-content-end">
                  <div class="disc-btn">
                    <button type="button" class="primary-btn">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="d-flex justify-content-end"><img class="btnMapClose showHand" (click)="closeMapClicked()" src="../../../assets/images/close-black-icon.png"></div>
      
      <!-- <div class="modal-header">
        <button type="button" class="btn-close"></button>
      </div> -->
      <form #mapForm="ngForm" (ngSubmit)="onSubmit(mapForm.value)">
        <div class="modal-body d-flex align-items-center">
          <div class="body-contain">
            <div class="location-tab-input">
              <div class="form-label ">
                <label for="cordonone-check">{{HeadingText}}</label>
              </div>
              <div class="hs-input">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <input class="form-control" type="text" id="post_code"
                      name="Name" [(ngModel)]="mapData.Name" #Name="ngModel"
                      placeholder="" required (ngModelChange)="nameChange($event)">
                  </div>
                  <span class="text-danger"
                    *ngIf="(Name.touched || Name.submitted) &&
                    Name.errors?.required">
                    Name is required
                  </span>
                  <span class="text-danger"
                    *ngIf="isDuplicateName">
                    Name is already exist
                  </span>
                </div>
              </div>
              <div class="map-iframe" *ngIf="isMapReady">
                <div class="map-iframe" style="width: 500px;">

                </div>
                <agm-map [zoom]="InitialZoom" *ngIf="drawingType !== 'RendezvousPoint'"
                  [latitude]="InitialLatitude" [longitude]="InitialLongitude"
                  (mapReady)="onMapReadyForCrimeSceneOnlyShowMap($event)"
                  [fullscreenControl]='true'
                  [mapTypeControl]='true'
                  >
                  <agm-marker
                    [iconUrl]="{url: '../../../assets/images/red-marker.png'}"
                    [latitude]="InitialLatitude" [longitude]="InitialLongitude"
                  ></agm-marker>
                  <agm-polygon
                    [paths]="pointList">
                  </agm-polygon>
                </agm-map>
                
                <agm-map *ngIf="drawingType === 'RendezvousPoint'"  [latitude]="InitialLatitude" [longitude]="InitialLongitude"
                    [zoom]="InitialZoom" [zoomControl]="true"
                    (mapClick)="mapClicked($event)"
                    [fullscreenControl]='true' [mapTypeControl]='true'>
                    <agm-marker
                    [iconUrl]="{url: '../../../assets/images/red-marker.png'}"
                    [latitude]="InitialLatitude" [longitude]="InitialLongitude"
                  ></agm-marker>
                    <agm-marker 
                      [latitude]="marker.lat" [longitude]="marker.lng"
                      [visible]="marker.visible"
                      [label]="marker.label" [markerDraggable]="marker.draggable"
                      (dragEnd)="markerDragEnd(marker, $event)"
                      [iconUrl]="{url: marker.icon}">
                    </agm-marker>
                </agm-map>

                

                <!-- <agm-map *ngIf="drawingType === 'RendezvousPoint'" [latitude]="InitialLatitude"
                  [longitude]="InitialLongitude" [zoom]="InitialZoom"
                  [zoomControl]="true" [fullscreenControl]='true'
                  [mapTypeControl]='true'>
                  <agm-marker [iconUrl]="{url: m.icon}"
                    *ngFor="let m of markers; let i= index"
                    [latitude]="m.lat" [longitude]="m.lng"
                    [label]="m.label"
                    [markerDraggable]="m.draggable"
                    (dragEnd)="markerDragEndLocation($event)">
                  </agm-marker>
                </agm-map> -->
                <button type="button" class="btn btn-danger mt-1"
                              *ngIf="isEdit && drawingType !== 'RendezvousPoint'"
                              (click)="RemoveLocations(sub)"> Reset</button>


                             
                <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.561033557228!2d0.0044854000000000005!3d51.5212689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3b3e581d4d3b85f3!2zNTHCsDMxJzE2LjYiTiAwwrAwMCcxNi4yIkU!5e0!3m2!1sen!2sin!4v1663849704233!5m2!1sen!2sin"
              width="600" height="450" style="border:0;" allowfullscreen="true" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe> -->
              </div>
              <div>
                <span class="text-danger"
                  *ngIf="isInvalidMap">
                  Map is invalid
                </span>
              </div>

              <div class="location-disc-input">
                <div class="form-label ">
                  <label for="initialLocation">Description</label>
                </div>
                <div class="hs-input">
                  <div class="row mb-0 mb-md-2">
                    <div class="col-12 col-md-9">
                      <textarea class="form-control" id="incidentDetails"
                        name="Description" [(ngModel)]="mapData.Description"
                        #Description="ngModel" placeholder=""
                        ></textarea>
                    </div>
                    <div class="col-12 col-md-3 text-end d-flex align-items-end
                      justify-content-end">
                      <div class="disc-btn">

                        <!-- <button type="submit" class="primary-btn"
                          [disabled]="mapForm.invalid">Save
                          <span class="save-loader"></span>
                        </button> -->

                        <button type="submit" class="primary-btn" [disabled]="mapForm.invalid">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>