import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from '../Authentication/change-password/change-password.component';
import { BCUAuthGuard } from '../bcu/Authentication/BCUAuthGuard';


const routes: Routes = [
  {
    path: "change-password",
    component: ChangePasswordComponent,
    data: {
      title: "Change Password",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
