import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class LocalAuthorityActionsResponseService {

  constructor(
    private _apiService: ApiService
    ) { }
  getIncidentListforAction(userId:any) {
    const url = "/BCU/GetBCUChecklist/"+`${userId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  UpdateMemberTaskStatus(taskMemberId:any,taskStatus:any) {
    const url = "/BCU/UpdateMemberTaskStatus/"+`${taskMemberId}`+"/"+`${taskStatus}`;
    return this._apiService.post(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
