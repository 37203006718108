<div class="team-protocol-section py-50 local-auth-7 ">
  <div class="container cst-protocol Team_protocol_action">
    <div class="w-100">
      <div class="accordion tableaccordian" id="contactaccordian">
        <div class="teamprotocol-heading">
          <div class="t_h2 main-dropdown-js">
            <h1>
              Finalise protocol for <span class="rel each-dropdown-js mw-120 border-bottom border_dark"
              rel="ddlTemplate"> {{selectedTemplate}}</span>
              for <span class="rel each-dropdown-js" rel="ddlCategory"> 
                {{selectedCategory === "All" ? "all categories " : selectedCategory}}
              </span> 
              and 
              <span
                class="rel each-dropdown-js" rel="ddlPerson"> 
                {{selectedPerson === "All" ? "all people " : selectedPerson}}
              </span> 
              at
              <span
                class="rel each-dropdown-js" rel="ddlTime"> 
                {{selectedTime === "All" ? "all times " : selectedTime}}
              </span>
            </h1>
            <ul class="t-dropdown each-dropdown-content-js" id="ddlTemplate">
              <li *ngFor="let template of templates">
                <button *ngIf="template.templateTitle != null && template.templateTitle != undefined"
                 (click)="TemplatSelectedClicked(template)" type="button" class="link">
                  {{template.templateTitle}}
                </button>
              </li>
            </ul>
            <ul class="t-dropdown each-dropdown-content-js" id="ddlCategory">
              <li><button (click)="CategorySelectedClicked('All')" type="button" class="link">All</button></li>
              <li><button (click)="CategorySelectedClicked('Communication')" type="button" class="link">Communication</button></li>
              <li><button (click)="CategorySelectedClicked('Safeguarding and support')" type="button" class="link">Safeguarding and support</button></li>
              <li><button (click)="CategorySelectedClicked('Hospital')" type="button" class="link">Hospital</button></li>
              <li><button (click)="CategorySelectedClicked('Community')" type="button" class="link">Community</button></li>
              <li><button (click)="CategorySelectedClicked('Partnership work (CSP)')" type="button" class="link">Partnership work (CSP)</button></li>
              <li><button (click)="CategorySelectedClicked('Education settings')" type="button" class="link">Education settings</button></li>
              <li><button (click)="CategorySelectedClicked('Additional Resource deployment')" type="button" class="link">Additional Resource deployment</button></li>
              <li><button (click)="CategorySelectedClicked('Family')" type="button" class="link">Family</button></li>
              <li><button (click)="CategorySelectedClicked('Emergency Planning')" type="button" class="link">Emergency Planning</button></li>
            </ul>
            <ul class="t-dropdown each-dropdown-content-js" id="ddlPerson">
              <li><button (click)="PersonSelectedClicked('All')" type="button" class="link">All</button></li>
              <li><button (click)="PersonSelectedClicked('Mackenzie Thornton')" type="button" class="link">Mackenzie Thornton</button></li>
              <li><button (click)="PersonSelectedClicked('Shania Barker')" type="button" class="link">Shania Barker</button></li>
              <li><button (click)="PersonSelectedClicked('Romeo Foley')" type="button" class="link">Romeo Foley</button></li>
              <li><button (click)="PersonSelectedClicked('Carl Salazar')" type="button" class="link">Carl Salazar</button></li>
              <li><button (click)="PersonSelectedClicked('Marnie Schmidt')" type="button" class="link">Marnie Schmidt</button></li>
              <li><button (click)="PersonSelectedClicked('Mitchell Lee')" type="button" class="link">Mitchell Lee</button></li>
              <li><button (click)="PersonSelectedClicked('Sameer Carrillo')" type="button" class="link">Sameer Carrillo</button></li>
              <li><button (click)="PersonSelectedClicked('Anish Hamilton')" type="button" class="link">Anish Hamilton</button></li>
              <li><button (click)="PersonSelectedClicked('Karina Odom')" type="button" class="link">Karina Odom</button></li>
              <li><button (click)="PersonSelectedClicked('Crystal Lindsay')" type="button" class="link">Crystal Lindsay</button></li>
            </ul>
            <ul class="t-dropdown each-dropdown-content-js" id="ddlTime">
              <li><button (click)="TimeSelectedClicked('All')" type="button" class="link">All</button></li>
              <li><button (click)="TimeSelectedClicked('24 hours')" type="button" class="link">24 hours</button></li>
              <li><button (click)="TimeSelectedClicked('1 week')" type="button" class="link">1 week</button></li>
              <li><button (click)="TimeSelectedClicked('1 month')" type="button" class="link">1 month</button></li>
              <li><button (click)="TimeSelectedClicked('3 month')" type="button" class="link">3 month</button></li>
            </ul>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button bg-gradient-lightblue-tb" type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord1" aria-expanded="true" aria-controls="contactaccord1">
            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Person</th>
                      <th>Action</th>
                      <th>Category</th>
                      <th>Timeframe</th>
                      <th><input class="table-checkbox border-check form-check-input" type="checkbox" value="">
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="templateWithTaskDetaiil?.incidentTaskList">
                    <tr *ngFor="let task of templateWithTaskDetaiil.incidentTaskList">
                      <td>
                        <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                        <span>Peter Dickenson</span>
                      </td>
                      <td>Share partner information (police, VRP etc)</td>
                      <td>{{task.label}}</td>
                      <td>24 hrs</td>
                      <td>
                        <input class="table-checkbox form-check-input" type="checkbox" value="">
                      </td>
                    </tr>
                    <!--  <tr>
                      <td>
                        <img src="assets/images/user-profile.svg" alt="user-profile" class="user-profile">
                        <span>Tom Farakah</span>
                      </td>
                      <td>Resident door knocking local area for reassurance</td>
                      <td>Communication</td>
                      <td>24 hrs</td>
                      <td>
                        <input class="table-checkbox form-check-input" type="checkbox" value="">
                      </td>
                    </tr> -->
                  </tbody>
                </table>
                <div class="hs-input updatebtn local-auth-5">
                  <div class="dropdown local-action-btn ">
                    <button class="dropdown-toggle gyrbtn hsr-btn down-caret" type="button" id="launchprotocol2"
                      data-bs-toggle="dropdown" aria-expanded="false">Update</button>
                    <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
                      <li><a href="#">all</a></li>
                      <li><a href="#">Mackenzie Thornton</a></li>
                      <li><a href="#">Shania Barker</a></li>
                      <li><a href="#">Romeo Foley</a></li>
                      <li><a href="#">Carl Salazar</a></li>
                      <li><a href="#">Marnie Schmidt</a></li>
                      <li><a href="#">Mitchell Lee</a></li>
                      <li><a href="#">Sameer Carrillo</a></li>
                      <li><a href="#">Anish Hamilton</a></li>
                      <li><a href="#">Karina Odom</a></li>
                      <li><a href="#">Crystal Lindsay</a></li>
                    </ul>
                  </div>
                  <div class="local-action-btn ">
                    <button type="button" id="launchprotocol2">Launch protocol</button>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>