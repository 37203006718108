import { Component } from '@angular/core';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-login-receiver',
  template: ``,
  styles: []
})
export class LoginReceiverComponent {

  constructor(
    private _appStartService: AppStartService
    ) { 
      (async () => {await this._appStartService.NavigateToDashboard();})();
  }
}
