import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModelService {

  constructor() { }

  @Output() onConfirmEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() onCancelEvent: EventEmitter<boolean> = new EventEmitter();
}
