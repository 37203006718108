import { EmergencyNoteDTO } from './../../Models/EmergencyPlanningModels/LAEmergencyPlanningActions';
import { CognitoService } from './../../Authentication/cognito.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IncidentNoteDTO } from 'src/app/Models/Notes/IncidentNoteDTO';
import { NotesOverlayService } from './notes-overlay.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IncidentNoteCreateDTO } from 'src/app/Models/Notes/IncidentNoteCreateDTO';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-notes-overlay',
  templateUrl: './notes-overlay.component.html',
  styleUrls: ['./notes-overlay.component.css']
})
export class NotesOverlayComponent implements OnInit {

  incidentTitle: string;
  incidentId: string;
  noteType: string;
  taskId: number;
  noteData: IncidentNoteDTO[];
  emergencyTaskMemberId: number;
  isNotesLoaded = false;
  notesForm: FormGroup;
  currentlySignedInUser : any;
  

  constructor(
    public _dialogRef: MatDialogRef<NotesOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fb: FormBuilder,
    private _notesOverlayService: NotesOverlayService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _appStartService: AppStartService
    ) { }

  ngOnInit(): void {
    //console.log(this._data);
    this.InitForm();
    this.incidentTitle = this._data.incidentTitle;
    this.noteData = this._data.noteData;
    this.incidentId = this._data.incidentId;
    this.taskId = this._data.taskId;
    this.noteType = this._data.noteType;
    this.emergencyTaskMemberId = this._data.emergencyTaskMemberId;
    this.isNotesLoaded = true;

    this.currentlySignedInUser = this._appStartService.currentCognitoUser;

    // this._cognitoService.GetUser()
    // .then(user => {
    //   if (user){
    //     //console.log(user);
    //     this.currentlySignedInUser = user;
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    // this._notesOverlayService.getMessageLog().subscribe(x => console.log(x), (err) => {console.log(err);})
  }

  InitForm() {
    this.notesForm = this._fb.group({
      noteToBeAdd: [null, Validators.required]
    });
  }

  get noteToBeAdd() {
    return this.notesForm.get("noteToBeAdd") as FormControl;
  }

  onSubmit(){
    if (this.notesForm.invalid){
      this._toastr.error("Data is Invalid");
      return;
    }

    

    if (this.noteType === "CST"){
      let incidentNoteCreateDTO = new IncidentNoteCreateDTO();
      incidentNoteCreateDTO.date = new Date();
      incidentNoteCreateDTO.message = this.noteToBeAdd.value;
      incidentNoteCreateDTO.taskId = this.taskId;
      incidentNoteCreateDTO.incidentId = this.incidentId;
      incidentNoteCreateDTO.createdById = this.currentlySignedInUser.username;

      this._notesOverlayService.AddCSTTaskNote(incidentNoteCreateDTO).subscribe(response => {
        if (!response || !response.data || response.statusCode != 200){
          return;
        }
  
        console.log();
        this._toastr.success("Note created successfully", "Success");
        this._dialogRef.close(true);
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      });
    }
    else if (this.noteType === "EMERGENCY"){
      let emergencyNoteDTO = new EmergencyNoteDTO();
      emergencyNoteDTO.createdById = this.currentlySignedInUser.username;
      emergencyNoteDTO.date = new Date();
      emergencyNoteDTO.message = this.noteToBeAdd.value;
      emergencyNoteDTO.emergencyTaskMemberId = this.emergencyTaskMemberId;

      this._notesOverlayService.AddEmergencyTaskNote(emergencyNoteDTO).subscribe(response => {
        if (!response || !response.data || response.statusCode != 200){
          return;
        }
  
        console.log(response.data);
        this._toastr.success("Note created successfully", "Success");
        this._dialogRef.close(true);
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      });
    }
  }

}
