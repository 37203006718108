<div class="pt-4">
    <div class="container">
        <div class="subselect-wrapper">
            <div class="row five-grid">
                <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
                    <div class="option-card bg_purple d-flex option-dropdown"
                        [routerLinkActive]="['bg_gradient']">
                        <a
                            [routerLink]="['/BCU/initial-incident-form',0,'StartIncidentTab']"
                            class="title-icon text-white d-flex flex-wrap
                            flex-wrap">
                            <img src="../../../../assets/images/plus-icon.png"
                                alt="">
                            <h3>New Incident</h3>
                        </a>
                        <div class="dropdown">
                            <button class="btn dropdown-toggle" type="button"
                                id="incidentdropdown"
                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                            <ul class="dropdown-menu"
                                aria-labelledby="incidentdropdown">
                                <li><a
                                        [routerLink]="['/BCU/initial-incident-form',0,'StartIncidentTab']">Incidents</a></li>
                                <li><a href="#">Team</a></li>
                                <li><a href="#">Stakeholder</a></li>
                                <li><a href="#">Templates</a></li>
                                <li><a href="#">Messaging</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
                    <div class="option-card bg_purple"
                        [routerLinkActive]="['bg_gradient']">
                        <a [routerLink]="['/local-authority-actions-response']"
                            class="title-icon text-white d-flex flex-wrap
                            flex-wrap">
                            <img src="../../../../assets/images/action-icon.png"
                                alt="action-icon">
                            <h3>Actions</h3>
                            <div class="action-number">
                                <div class="action-each">
                                    <span class="number-circle">7</span>
                                    Accept / decline
                                </div>
                                <div class="action-each">
                                    <span class="number-circle">5</span>
                                    Late
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
                    <div class="option-card bg_purple"
                        [routerLinkActive]="['bg_gradient']">
                        <a [routerLink]="['/stakeholder-messaging']"
                            target="_blank"
                            class="title-icon text-white d-flex flex-wrap
                            flex-wrap">
                            <img
                                src="../../../../assets/images/message-icon.png"
                                alt="">
                            <h3>Partner Messaging</h3>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
                    <div class="option-card bg_purple"
                        [routerLinkActive]="['bg_gradient']">
                        <a [routerLink]="['/contacts']" class="title-icon
                            text-white d-flex flex-wrap flex-wrap">
                            <img
                                src="../../../../assets/images/contact-icon.png"
                                alt="">
                            <h3>Contacts</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container local-action">
    <div class="accordion tableaccordian" id="contactaccordian">
        <div class="accordion-item">
            <h2 class="accordion-header with-link" id="contactheading1">
                <button class="accordion-button" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#contactaccord1" aria-expanded="true"
                    aria-controls="contactaccord1">
                    Local Authority
                </button>
                <a class="redirect-page" [routerLink]="['/la-dashboard']"></a>
            </h2>

            <div id="contactaccord1" class="accordion-collapse collapse show"
                aria-labelledby="contactheading1"
                data-bs-parent="#contactaccordian">
                <div class="accordion-body">
                    <div class="table-responsive local-action-table
                        accord-table">
                        <table class="table table-hover"
                            *ngIf="!IncidentListLoad">
                            <thead>
                                <tr>
                                    <th colspan="7 " class="cnt-table-title">
                                        Your Incident Actions
                                    </th>
                                    <th colspan="2">
                                        <div class="dropdown local-action-btn
                                            action-final"
                                            *ngIf="IncidentAction.length>0">
                                            <button class="btn dropdown-toggle
                                                primary-btn"
                                                [ngClass]="{'loading-start':UpdateMemberTaskStatusLoad}"
                                                [disabled]="UpdateMemberTaskStatusLoad"
                                                type="button"
                                                id="actiondropdown2"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                Action <span
                                                    class="save-loader"></span>
                                            </button>
                                            <ul class="dropdown-menu"
                                                aria-labelledby="actiondropdown2">
                                                <li>
                                                    <button type="button"
                                                        (click)="IncidentAccept()"><img
                                                            src="../../assets/images/Local_check_Light.svg"
                                                            alt="" />
                                                        Accept</button>
                                                </li>
                                                <li>
                                                    <button type="button"
                                                        (click)="IncidentDecline()"><img
                                                            src="../../assets/images/Local_Authority_Light.svg"
                                                            alt="" />
                                                        Decline</button>
                                                </li>
                                                <li>
                                                    <button type="button"
                                                        (click)="IncidentComplete()"><img
                                                            src="../../assets/images/flag-icon.png"
                                                            alt="" />Complete</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </th>
                                </tr>
                                <tr class="active">
                                    <th>Action</th>
                                    <th>Incident</th>
                                    <th>Category</th>
                                    <th>Action</th>
                                    <th>24hr</th>
                                    <th>week 1</th>
                                    <th>month 1</th>
                                    <th>month 3</th>
                                    <th class="text-center"><input
                                            *ngIf="IncidentAction.length>0"
                                        class="table-checkbox form-check-input"
                                        [(ngModel)]="SelectAllAction"
                                        (change)="changeSelectAll($event.target.checked)"
                                        type="checkbox"
                                        value="" /></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="IncidentAction.length>0">
                                <tr *ngFor="let item of IncidentAction">
                                    <td>
                                        <div class="dropdown actions
                                            position_unset">
                                            <button class="btn dropdown-toggle"
                                                type="button"
                                                id="actiondropdown1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"></button>
                                            <ul class="dropdown-menu"
                                                aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img
                                                            src="../../assets/images/decline.png"
                                                            alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img
                                                            src="../../assets/images/manage-icon.png"
                                                            alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img
                                                            src="../../assets/images/scale-down.png"
                                                            alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        {{item.incidentTitle}}
                                    </td>
                                    <td>{{item.category}}</td>
                                    <td>{{item.task}}</td>
                                    <td>
                                        <span *ngIf="item.timeframe==1 &&
                                            item.memberTaskStatus==1"
                                            class="blue-circle"></span>
                                        <span *ngIf="item.timeframe==1 &&
                                            item.memberTaskStatus==2"
                                            class="red-circle"></span>
                                        <span *ngIf="item.timeframe==1 &&
                                            item.memberTaskStatus==3"
                                            class="orange-circle"></span>
                                        <span *ngIf="item.timeframe==1 &&
                                            item.memberTaskStatus==4"
                                            class="green-circle"></span>
                                    </td>
                                    <td>
                                        <span *ngIf="item.timeframe==2 &&
                                            item.memberTaskStatus==1"
                                            class="blue-circle"></span>
                                        <span *ngIf="item.timeframe==2 &&
                                            item.memberTaskStatus==2"
                                            class="red-circle"></span>
                                        <span *ngIf="item.timeframe==2 &&
                                            item.memberTaskStatus==3"
                                            class="orange-circle"></span>
                                        <span *ngIf="item.timeframe==2 &&
                                            item.memberTaskStatus==4"
                                            class="green-circle"></span>

                                    </td>
                                    <td>
                                        <span *ngIf="item.timeframe==3 &&
                                            item.memberTaskStatus==1"
                                            class="blue-circle"></span>
                                        <span *ngIf="item.timeframe==3 &&
                                            item.memberTaskStatus==2"
                                            class="red-circle"></span>
                                        <span *ngIf="item.timeframe==3 &&
                                            item.memberTaskStatus==3"
                                            class="orange-circle"></span>
                                        <span *ngIf="item.timeframe==3 &&
                                            item.memberTaskStatus==4"
                                            class="green-circle"></span>

                                    </td>
                                    <td>

                                        <span *ngIf="item.timeframe==4 &&
                                            item.memberTaskStatus==1"
                                            class="blue-circle"></span>
                                        <span *ngIf="item.timeframe==4 &&
                                            item.memberTaskStatus==2"
                                            class="red-circle"></span>
                                        <span *ngIf="item.timeframe==4 &&
                                            item.memberTaskStatus==3"
                                            class="orange-circle"></span>
                                        <span *ngIf="item.timeframe==4 &&
                                            item.memberTaskStatus==4"
                                            class="green-circle"></span>
                                    </td>

                                    <td class="text-center">
                                        <input class="table-checkbox
                                            form-check-input"
                                            [checked]="item.select"
                                            (change)="changeIncidentSelect($event.target.checked,item)"
                                            type="checkbox"
                                            value="" />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="IncidentAction.length==0">
                                <tr>
                                    <td colspan="10">
                                        <div class="alert alert-info">No
                                            Incident Found</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <app-table-loader *ngIf="IncidentListLoad"></app-table-loader>
                    </div>
                    <!-- <div class="table-responsive local-action-table accord-table b-radius">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th colspan="7  " class="cnt-table-title">My actions</th>
                                    <th colspan="2">
                                        <div class="dropdown local-action-btn action-final">
                                            <button class="btn dropdown-toggle primary-btn" type="button"
                                                id="actiondropdown2" data-bs-toggle="dropdown" aria-expanded="false">
                                                Action
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown2">
                                                <li>
                                                    <a href="#"><img src="../../assets/images/Local_check_Light.svg" alt="" />
                                                        Accept</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/Local_Authority_Light.svg"
                                                            alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/flag-icon.png"
                                                            alt="" />Complete</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </th>
                                </tr>
                                <tr class="active">
                                    <th>Action</th>
                                    <th>Incident</th>
                                    <th>Category</th>
                                    <th>Action</th>
                                    <th>24hr</th>
                                    <th>week 1</th>
                                    <th>month 1</th>
                                    <th>month 3</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        Double knife crime, Great Swindon Street, London E11 1HQ
                                    </td>
                                    <td>Communications</td>
                                    <td>Share partner information</td>
                                    <td><span class="green-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>

                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>Press release</td>
                                    <td><span class="green-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>Hospital</td>
                                    <td>
                                        Liaise with hospital team & prep report for DRM meeting
                                    </td>
                                    <td><span class="red-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        Ensure multi-agency liaison for discharge planning
                                    </td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="orange-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>Family</td>
                                    <td>
                                        Following multi-agency consultation, make contact with
                                        victim and family
                                    </td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="orange-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>Knife crime, Gaberdeen St, E12 3AH</td>
                                    <td>Communications</td>
                                    <td>
                                        Liaise with Police Media team & Issue joint updates
                                    </td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="orange-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>Partnership work</td>
                                    <td>Work with local partners, agree messages.</td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="orange-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="dropdown actions position_unset">
                                            <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                                                data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                                                <li>
                                                    <a><img src="../../assets/images/decline.png" alt="" />
                                                        Decline</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/manage-icon.png" alt="" />
                                                        Manage related incidents</a>
                                                </li>
                                                <li>
                                                    <a href="#"><img src="../../assets/images/scale-down.png" alt="" />
                                                        Scaled down</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        Appeal for witnesses, prepare statement for
                                        CrimeStoppers
                                    </td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="grey-circle"></span></td>
                                    <td><span class="orange-circle"></span></td>
                                    <td><span class="orange-circle"></span></td>
                                    <td>
                                        <input class="table-checkbox form-check-input" type="checkbox" value="" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
