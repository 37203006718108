
<div class="py-50 pt_sm_0 message-form">
  <div class="container width-controller" *ngIf="isIncidentTeamLoaded && isOrgenizationTeamLoaded">
    <div class="accordion tableaccordian" id="newmessage">
      <div class="accordion-item">
        <h2 class="accordion-header" id="newheading1">
          <!-- <button class="accordion-button" type="button"
            data-bs-toggle="collapse" data-bs-target="#newaccord1"
            aria-expanded="true" aria-controls="newaccord1">
            {{overlayData.messageType === 'SMS' ? 'Send ': ''}}Incident message
          </button> -->
          <button class="accordion-button hideHand" type="button">
            {{overlayData.messageType === 'SMS' ? 'Send ': ''}}Incident message
          </button>
        </h2>
        <form [formGroup]="sendMessageForm" (submit)="onSubmit()">
          <div id="newaccord1" class="accordion-collapse collapse show"
            aria-labelledby="newheading11"
            data-bs-parent="#newmessage">
            <div class="accordion-body">
              <div class="form-style">
                <div class="fieldset">
                  <div class="form-label">
                    <label for="sendmail"><strong>{{overlayData.messageType === 'SMS' ? 'SMS' : 'Email'}}</strong></label>
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12">
                        <div class="para-text">
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Sent</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Jan 21, 2022, 11:38:35 AM</p>
                        <p>{{ messageInfo.sent | date: 'MMM d, y, h:mm:ss a' }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
                <div class="fieldset">
                  <div class="form-label">
                    <label for="sendmail"><strong>From</strong></label>
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12">
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <span>{{currentlySignedInUserName}}</span>
                          <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>(pen)</small> -->
                          <!-- <span>{{messageInfo.from}}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>(pen)</small> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="form-label">
                      <label for="sendmail"><strong>To<br>Groups</strong></label> <br>
                      <span *ngIf="messsageToGroup && messsageToGroup.length > 0">Clear all</span>
                      <span 
                      *ngIf="messsageToGroup && messsageToGroup.length > 0"
                        (click)="RemoveAllFromToGroupListClicked()" 
                        class="name-close showHand left-margin">[ x ]</span>
                  </div>
                  <div class="hs-input">
                    <div class="row">

                      <div class="col-md-6">
                        <div class="team-member-group">
                          <select name="AllTeamMembers" id="teammembers" formControlName="toGroup">
                            <option value=""></option>
                            <option [value]="toGroupOption.id" *ngFor="let toGroupOption of toGroupOptions">
                              {{toGroupOption.name}}
                            </option>
                          </select>
                        </div>
                        
                      </div>
                    </div>

                    
                    <div class="team-member-group">
                      <!-- <div class="team-member-item"> -->
                        <div class="row">
                          <div class="team-member-name col-lg-4 col-md-6" *ngFor="let to of messsageToGroup; index as i">
                            <img [src]="to.avatar ? to.avatar : './assets/images/profile-blank-icon.png'"
                              alt="rd-icon">
                              <div class="name-desig">
                                <span>{{to.name}}</span>
                                <span (click)="RemoveFromToGroupListClicked(to)" class="name-close">[ x ]</span>
                                <p>{{to.jobTitle}}</p>
                              </div>
                            <!-- <span>{{to.name}}</span>
                            <span (click)="removeFromToListClicked(to)" class="name-close">[ x ]</span> -->
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                
                <div class="fieldset incident-upsection">
                  <div class="form-label">
                    <label for="sendmail"><strong>To <br>individuals</strong></label> <br>
                    <span *ngIf="messsageToIndividuals && messsageToIndividuals.length > 0">Clear all</span>
                    <span 
                      *ngIf="messsageToIndividuals && messsageToIndividuals.length > 0" 
                      (click)="RemoveAllFromToIndividualListClicked()" 
                      class="name-close showHand left-margin">[ x ]</span>
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-md-6 mb-4 mb-sm-0">
                        <div class="option-card border-btn d-flex option-dropdown btn-height">
                          <!-- <span class="title-icon d-flex flex-wrap align-items-start">
                          </span> -->
                          <div class="dropdown">
                            <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown" aria-expanded="false"></button>
                            <ul class="dropdown-menu vertical-scroll" aria-labelledby="incidentdropdown" style="">
                              <li class="showHand" (click)="IndividualMemeberSelected(indivualMemeber)" *ngFor="let indivualMemeber of indivualMemeberList">
                                <div class="team-member-item">
                                  <div class="team-member-name">
                                    <img [src]="indivualMemeber.avatar ? indivualMemeber.avatar : './assets/images/profile-blank-icon.png'" alt="icon">
                                    <div class="name-desig">
                                      <span>{{indivualMemeber.name}}</span>
                                      <!-- <span class="name-close">[ x ]</span> -->
                                      <p>{{indivualMemeber.jobTitle}}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>                      
                            </ul>
                          </div>
                        </div>
                      </div>
                     
                    </div>

                    
                    <div class="team-member-group">
                      <!-- <div class="team-member-item"> -->
                        <div class="row">
                          <div class="team-member-name col-lg-4 col-md-6" *ngFor="let member of messsageToIndividuals; index as i">
                            <img [src]="member.avatar ? member.avatar : './assets/images/profile-blank-icon.png'"
                              alt="rd-icon">
                              <div class="name-desig">
                                <span>{{member.name}}</span>
                                <span (click)="RemoveFromToIndividualListClicked(member)" class="name-close">[ x ]</span>
                                <p>{{member.jobTitle}}</p>
                              </div>
                            <!-- <span>{{to.name}}</span>
                            <span (click)="removeFromToListClicked(to)" class="name-close">[ x ]</span> -->
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="isSubmitted && messsageTofinal.length == 0">
                          Members are required</span>
                      </div>

                  </div>
                </div>
                


                <div class="fieldset border-bottom-0"
                  *ngIf="overlayData.messageType !== 'SMS'">
                  <div class="form-label">
                    <label for="sendmail"><strong>Subject</strong></label>
                  </div>
                  <div class="hs-input msg-subject">
                    <div class="row">
                      <div class="col-12">
                        <textarea class="form-control"
                          formControlName="subject"></textarea>
                      </div>
                      <div *ngIf="(isSubmitted ||
                        sendMessageForm.controls.subject.touched)">
                            <span class="text-danger"
                              *ngIf="sendMessageForm.controls.subject.hasError('required')">
                              Subject is required</span>
                          </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset border-bottom-0">
                  <div class="form-label">
                    <label for="sendmail"><strong>Message</strong></label>
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12">
                        <textarea class="form-control"
                          formControlName="messageContent"></textarea>
                      </div>
                      <div *ngIf="(isSubmitted ||
                        sendMessageForm.controls.messageContent.touched)">
                            <span class="text-danger"
                              *ngIf="sendMessageForm.controls.messageContent.hasError('required')">
                              Message is required</span>
                          </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset hs-submit">
                  <div class="form-label">
                  </div>
                  <div class="hs-input">
                    <div class="row">

                      <div class="d-flex align-items-end justify-content-end" >
                        <mat-spinner id="send-message-spinner" style="display: none;" diameter="20"></mat-spinner>
                      </div>

                      <div class="col-12 text-end" style="display: block;">
                        <button type="submit" id="btn-send" class="primary-btn">Send</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center" *ngIf="!isIncidentTeamLoaded || !isOrgenizationTeamLoaded">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>






<!--
<div class="py-50 pt_sm_0 message-form">
  <div class="container">
    <div class="accordion tableaccordian" id="newmessage">
      <div class="accordion-item">
        <h2 class="accordion-header" id="newheading1">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#newaccord1"
            aria-expanded="true" aria-controls="newaccord1">
            Incident message
          </button>
        </h2>
        <div id="newaccord1" class="accordion-collapse collapse show" aria-labelledby="newheading11"
          data-bs-parent="#newmessage">
          <div class="accordion-body">
            <div class="form-style">
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Email</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Sent</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Jan 21, 2022, 11:38:35 AM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>From</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="team-member-name">
                        <img src="./assets/images/rd-icon.png" alt="rd-icon">
                        <span>Peter Dickenson</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>(pen)</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>To</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-382">
                    <div class="col-12 col-sm-6 mb-4 mb-sm-0">
                      <div class="team-member-group">
                        <select name="AllTeamMembers" id="teammembers">
                          <option value=""></option>
                          <option value="Peter Dickenson">All team members</option>
                          <option value="Joanne Smith">All incident team members</option>
                        </select>
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="team-member-group">
                        <select name="AllincidentTeamMembers" id="incidentTeamMembers">
                          <option value=""></option>
                          <option value="Mackenzie Thornton">Mackenzie Thornton</option>
                          <option value="Shania Barker">Shania Barker</option>
                          <option value="Romeo Foley">Romeo Foley</option>
                          <option value="Carl Salazar">Carl Salazar</option>
                          <option value="Marnie Schmidt">Marnie Schmidt</option>
                          <option value="Mitchell Lee">Mitchell Lee</option>
                          <option value="Sameer Carrillo">Sameer Carrillo</option>
                          <option value="Anish Hamilton">Anish Hamilton</option>
                          <option value="Karina Odom">Karina Odom</option>
                          <option value="Crystal Lindsay">Crystal Lindsay</option>
                          <option value="Arman Weaver">Arman Weaver</option>
                        </select>
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mackenzie Thornton</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Shania Barker</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Romeo Foley</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Carl Salazar</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Marnie Schmidt</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset border-bottom-0">
                <div class="form-label">
                  <label for="sendmail"><strong>Subject</strong></label>
                </div>
                <div class="hs-input msg-subject">
                  <div class="row w-490">
                    <div class="col-12">
                      <textarea class="form-control" id="messagesubject" placeholder="Lorem." required=""></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset border-bottom-0">
                <div class="form-label">
                  <label for="sendmail"><strong>Message</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-490">
                    <div class="col-12">
                      <textarea class="form-control" id="message" placeholder="Lorem." required=""></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset hs-submit">
                <div class="form-label">
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 text-end">
                      <button type="button" class="primary-btn">Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

-->
