import { Auth } from 'aws-amplify';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { User } from '../Models/Authentication/User';
import { UserProfileService } from '../core/services/user-profile.service';
import { StringHelperService } from '../core/services/string-helper.service';
import { UserRole } from '../Enum/UserRole.enum';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  // private poolData = {
  //   UserPoolId: environment.cognito.userPoolId,
  //   ClientId: environment.cognito.userPoolWebClientId
  // };

  //private userPool = new AmazonCognitoIdentity.CognitoUserPool(this.poolData);

  constructor(
    private _userProfileService: UserProfileService,
    private _stringHelperService: StringHelperService
  ) {
    
    // Amplify.configure({
    //   Auth : environment.cognito
    // });

  }

  public SignIn(user: User): Promise<any> {
    return Auth.signIn(user.email, user.password);
  }

  public confirmSignUp(user: User) : Promise<any>{
    return Auth.confirmSignUp(user.email, user.verificationCode);
  }

  public async SignOut(): Promise<boolean> {
    // return Auth.signOut({ global: true })
    return Auth.signOut()
    .then(() => {
      this._userProfileService.RemoveAuthenticatedUserGroups();
      this.RedirectToLogin();
      return true;
    },
    err => { 
      console.error(err);
      return false; 
    });
  }

  public async GetUser(): Promise<any> {
    try{
      return Auth.currentAuthenticatedUser();
    }
    catch{
      await this.SignOut();
    }
    
    //return Auth.currentUserInfo();
  }
  
  public GetCurrentSession(){
    return Auth.currentSession();
  }

  public async isAuthenticated() : Promise<boolean>{
    return this.GetUser()
    .then(async (user) => {
      try {
        if (!user || this._stringHelperService.IsNullOrWhiteSpace(user.username)){
          await this.SignOut();
          return false;
        }
        else{
          return true;
        }

      } catch (error) {
        await this.SignOut()
        return false;
      }
    })
    .catch(async () => {
      await this.SignOut();
      return false;
    });
  }

  public async IsAuthenticatedForLa() : Promise<boolean>{
    return this.GetUser()
    .then(async (user) => {
      try {
        if (!user || this._stringHelperService.IsNullOrWhiteSpace(user.username)){
          await this.SignOut();
          return false;
        }

        const roleId = user.attributes['custom:roles']
        if (!roleId){
          await this.SignOut();
          return false;
        }

        if (this.IsLaUser(roleId)){
          return true; 
        }
        else {
          await this.SignOut();
          return false;
        }

      } catch (error) {
        await this.SignOut()
        return false;
      }
    })
    .catch(async () => {
      await this.SignOut();
      return false;
    });
  }

  public async IsAuthenticatedForBCU() : Promise<any>{
    return this.GetUser().then(async (user) => {
      try {
        if (!user || this._stringHelperService.IsNullOrWhiteSpace(user.username)){
          await this.SignOut();
          return false;
        }

        const roleId = user.attributes['custom:roles']
        if (!roleId){
          await this.SignOut();
          return false;
        }

        if (this.IsBCUUser(roleId)){
          return true; 
        }
        else {
          await this.SignOut();
          return false;
        }

      } catch (error) {
        await this.SignOut()
        return false;
      }
    })
    .catch(async () => {
      await this.SignOut();
      return false;
    });
  }

  public SignUp(username: string, password: string, attributes: { email: string, given_name: string, family_name: string, phone_number: string}) : Promise<any> {
    return Auth.signUp({
      username: username,
      password: password,
      attributes: {
        email: attributes.email,
        given_name: attributes.given_name,
        family_name: attributes.family_name,
        phone_number: attributes.phone_number
      }
    });
  }


  public ConfirmSignUp(email: string, confirmationCode: string) : Promise<any> {
    return Auth.confirmSignUp(email, confirmationCode);
  }

  public ResendSignUp(username: string) : Promise<any> {
    return Auth.resendSignUp(username);
  }

  public async ChangePassword(currentUser: any, oldPassword: any, newPassword: any){
    return Auth.changePassword(currentUser, oldPassword, newPassword);
  }

  public IsLaUser(roleId){
    if (["1", "2", "3", "4", "5"].includes(roleId) && Object.values(UserRole).map(Number).includes(parseInt(roleId))){
      return true;
    }
    else{
      return false;
    }
  }

  public IsBCUUser(roleId){
    if (["6"].includes(roleId) && Object.values(UserRole).map(Number).includes(parseInt(roleId))){
      return true;
    }
    else{
      return false;
    }
  }

  public RedirectToLogin(){
    window.location.assign(environment.cognito.awsLoginUrl);
  }

  // async isAuthenticatedForBCU() : Promise<boolean>{
  //   return this.GetUser()
  //   .then(async (user) => {
  //     try {
  //       if (!user || this._stringHelperService.IsNullOrWhiteSpace(user.username)){
  //         await this.SignOut();
  //         return false;
  //       }

  //       const roleId = user.attributes['custom:roles']
  //       if (!roleId){
  //         await this.SignOut();
  //         return false;
  //       }

  //       if (["6"].includes(roleId) && Object.values(UserRole).map(Number).includes(parseInt(roleId))){
  //         return true; 
  //       }
  //       else{
  //         await this.SignOut();
  //         return false;
  //       }

  //     } catch (error) {
  //       await this.SignOut()
  //       return false;
  //     }
  //   })
  //   .catch(async () => {
  //     await this.SignOut();
  //     return false;
  //   });
  // }


  // public signIn(user: User): Promise<any> {
  //   return Auth.signIn(user.email, user.password)
  //   .then(() => {
  //     this.authenticationSubject.next(true);
  //   });
  // }

  // public signIn(user: User) {
  //   const authenticationData = {
  //     Username: user.email,
  //     Password: user.password
  //   };
  //   const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

  //   const userToBEAuthenticated = new AmazonCognitoIdentity.CognitoUser({
  //     Username: user.email,
  //     Pool: this.userPool
  //   });

  //   return new Promise((resolve, reject) => {
  //     userToBEAuthenticated.authenticateUser(authenticationDetails, {
  //       onSuccess: (result) => {
  //         resolve(result);
  //       },
  //       onFailure: (err) => {
  //         reject(err);
  //       }
  //     });
  //   });
  // }

  // public signUp(user : User) : Promise<any> {
  //   return Auth.signUp({
  //     username : user.email,
  //     password : user.password,
  //     attributes : {
  //       email : user.email,
  //       given_name : user.givenName,
  //       family_name : user.familyName,
  //       phone_number : user.phoneNumber
  //     }
  //   });
  // }

  // public signUp(user: User) {
  //   const attributeList = [
  //     new AmazonCognitoIdentity.CognitoUserAttribute({
  //       Name: user.givenName,
  //       Value: user.email
  //     })
  //   ];

  //   return new Promise((resolve, reject) => {
  //     this.userPool.signUp(user.email, user.password, attributeList, null, (err, result) => {
  //       if (err) {
  //         reject(err);
  //       } else {
  //         resolve(result.user);
  //       }
  //     });
  //   });
  // }


  // public isAuthenticated(): Promise<boolean> {
  //   if (this.authenticationSubject.value) {
  //     return Promise.resolve(true);
  //   } else {
  //     return this.GetUser()
  //     .then((user: any) => {
  //       if (user) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }).catch(() => {
  //       return false;
  //     });
  //   }
  // }
}
