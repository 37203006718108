import { AddStakeholderComponent } from './../add-stakeholder/add-stakeholder.component';
import { StakeholderMembersService } from './stakeholder-members.service';
import { ToastrService } from "ngx-toastr";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { StakeholderProfileCreateDTO } from 'src/app/Models/Stakeholder/Stakeholder';
import { MatDialog } from '@angular/material/dialog';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';
import { Subscription } from 'rxjs';
import { AddStakeholderService } from '../add-stakeholder/add-stakeholder.service';

@Component({
  selector: "app-stakeholder-members",
  templateUrl: "./stakeholder-members.component.html",
  styleUrls: ["./stakeholder-members.component.css"],
})
export class StakeholderMembersComponent implements OnInit, OnDestroy {
  stakeHolderMemberForm: FormGroup;
  isSubmitted: boolean;

  incidentId: string;
  isTeamMembersLoaded = false;
  stakeholders: StakeholderProfileCreateDTO[] = [];
  stakeHolderSubjectSubscription: Subscription;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _toastr: ToastrService,
    private _stakeholderMembersService: StakeholderMembersService,
    private _dialog: MatDialog,
    private _apiErrorService: ApiErrorService,
    private _addStakeholderService: AddStakeholderService,
    public _urlHelperService: UrlHelperService
  ) {}
 
  ngOnInit(): void {
    
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    //this.InitForm();

    this._activatedRoute.params.subscribe((param) => {
      this.incidentId = param["id"];
    });

    // this.emergencyPlanningService.GetTeamMembersForIncident(this.incidentId).subscribe(response => {
    //   if (response && response.data && response.statusCode == 200){
    //     this.isTeamMembersLoaded = true;
    //     this.teamMemebers = response.data;
    //     //console.log(response.data);
    //   }
    // }, err => {
    //   console.log("Error:" + JSON.stringify(err));
    //   this.toastr.error(err?.error?.message, "Error");
    // });

    this.stakeHolderSubjectSubscription =
    this._addStakeholderService.StakeholderCommand.subscribe((stakeholder) => {
      let newStakeholder = new StakeholderProfileCreateDTO();
      newStakeholder.employer = stakeholder[0].employer;
      newStakeholder.familyName = stakeholder[0].familyName;
      newStakeholder.avatar = stakeholder[0].avatar;
      newStakeholder.email = stakeholder[0].email;
      newStakeholder.firstName = stakeholder[0].firstName;
      newStakeholder.id = stakeholder[0].id;
      newStakeholder.jobTitle = stakeholder[0].jobTitle;
      newStakeholder.organisationName = stakeholder[0].organisationName;
      newStakeholder.partnerMessage = stakeholder[0].partnerMessage;
      newStakeholder.phoneNo = stakeholder[0].phoneNo;
      newStakeholder.memberCategoryId = stakeholder[0].memberCategoryId;
      newStakeholder.groups = stakeholder[0].groups;
      
      if (stakeholder[1]) {
        this.stakeholders.map((item, index) => {
          if (item.id === newStakeholder.id) {
            this.stakeholders.splice(index, 1, newStakeholder);
          }
        });
      } else {
        this.stakeholders.push(newStakeholder);
      }
    });



    this._stakeholderMembersService.GetStakeHolderMembers().subscribe(response => {
      if (response && response.data && response.statusCode == 200){
        this.isTeamMembersLoaded = true;
        this.stakeholders = response.data;
      }
    }, 
    (error) => {
      this._apiErrorService.ShowError(error);
    });

  }

  stackHolderEditClicked(memberProfile: StakeholderProfileCreateDTO) {
    this._dialog.open(AddStakeholderComponent, {
      data: { memberProfile: memberProfile },
    });
  }

  // InitForm() {
  //   this.stakeHolderMemberForm = this._fb.group({
  //     firstName: [null, Validators.required],
  //     givenName: [null, Validators.required],
  //     role: [null, Validators.required],
  //     employer: [null, Validators.required],
  //     phone: [
  //       null,
  //       [Validators.required, Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")],
  //     ],
  //     email: [null, [Validators.required, Validators.email]],
  //     alertPreferences: [null, Validators.required],
  //   });
  // }

  // onSubmit() {
  //   this.isSubmitted = true;
  // }


  ngOnDestroy(): void {
    if (this.stakeHolderSubjectSubscription){
      this.stakeHolderSubjectSubscription.unsubscribe();
    }
  }

}
