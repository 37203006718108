import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringHelperService {

  IsNullOrWhiteSpace(str): boolean {
    return str === null || str === undefined || str.trim() === '';
  }

  IsAnyNullOrWhiteSpace(...str: string[]): boolean {
    return str.some(x => this.IsNullOrWhiteSpace(x));
  }

  IsValidGuid(guid: string) {
    if (this.IsNullOrWhiteSpace(guid) || guid === "00000000-0000-0000-0000-000000000000"){
      return false;
    }
    let regex = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);
    return regex.test(guid);
  }
}
