import { IncidentNoteDTO } from "../Notes/IncidentNoteDTO";

export class LAEmergencyPlanningActions {
  incidentId : string;
  title : string;
  location : string;
  incidentStatus : string;
  responseProtocolStatus : string;
  actions : LAEmergencyPlanningActionItem[];
        
}

export class LAEmergencyPlanningActionItem {
  EmergencyTaskMemberId: number;
  EmergencyTaskId: number;
  AssigneeAvatar: string;
  AssigneeName: string;
  EmergencyTaskTitle: string;
  hasNotes: boolean;
  HasIssue: boolean;
  EmergencyTaskStatus: number;
}

export class EmergencyNoteDTO extends IncidentNoteDTO {
  emergencyTaskMemberId: number;
  createdById: string;
}
