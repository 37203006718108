import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IncidentDTO, LocationDTO, LocationSectionDTO, Section35DTO, Section60DTO } from '../Models/incident.modal';
import { IncidentService } from '../initial-incident-form/incident.service';
import { ApiErrorService } from '../core/services/api-error.service';
import { LocationColor } from '../Enum/LocationType.enum';
import { IncidentFormLocationEntity } from '../Models/IncidentFormLocationEntity';
import { IirService } from '../bcu/components/iir/iir.service';
import { DateService } from '../core/services/date.service';
import { StringHelperService } from '../core/services/string-helper.service';
import { UrlHelperService } from '../core/services/url-helper.service';
import { LoadScriptService } from '../home-dashboard/load-script.service';

@Component({
  selector: 'app-la-incident-details',
  templateUrl: './la-incident-details.component.html',
  styleUrls: ['./la-incident-details.component.css']
})
export class LAIncidentDetailsComponent implements OnInit {
  BLUE_MARKER_URL = "../../assets/images/blue-big-marker.png";

  incident: IncidentDTO = new IncidentDTO();
  lng: any;
  lat: any;
  incidentLoaded: boolean = false;
  zoom: number = 14;

  shouldIncidentMarkerVisible = true;

  CrimeSceneLocations: IncidentFormLocationEntity[] = [];
  PoliceCordonLocations: IncidentFormLocationEntity[] = [];
  RendezvousPointLocations: IncidentFormLocationEntity[] = [];
  Section60Locations: IncidentFormLocationEntity[] = [];
  Section35Locations: IncidentFormLocationEntity[] = [];

  CrimeSceneLocationsToShow: IncidentFormLocationEntity[] = [];
  PoliceCordonLocationsToShow: IncidentFormLocationEntity[] = [];
  RendezvousPointLocationsToShow: IncidentFormLocationEntity[] = [];
  Section60LocationsToShow: IncidentFormLocationEntity[] = [];
  Section35LocationsToShow: IncidentFormLocationEntity[] = [];

  MainLocationPolygon: { id: string, path: any; color: string, name: string }[] = [];
  MainLocationPolygonToShow: { id: string, path: any; color: string, name: string }[] = [];

  MainLocationRVPMarker: { id: string, path: any; color: string, name: string, icon: string, label: string, lat: number, lng: number }[] = [];
  MainLocationRVPMarkerToShow: { id: string, path: any; color: string, name: string, icon: string, label: string, lat: number, lng: number }[] = [];

  shouldMapShow: boolean = true;

constructor(
  private _incidentService: IncidentService, 
  private _iirService: IirService, 
  private _activatedRoute: ActivatedRoute, 
  private _stringHelperService: StringHelperService, 
  private _toastr: ToastrService, 
  private _titleService: Title,
  private _loadScriptService: LoadScriptService,
  private _router: Router,
  private _apiErrorService: ApiErrorService,
  private _dateService: DateService,
  public _urlHelperService: UrlHelperService
  ) { }

ngOnInit(): void {
  this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
  this._loadScriptService.LoadScript('incident-DetailsScriptTag');
  
  this.InitilizeIncident();
}

InitilizeIncident(){
  const params = this._activatedRoute.snapshot.paramMap;
  if (!params || !params.get('id') || !this._stringHelperService.IsValidGuid(params.get('id'))){
    this._apiErrorService.ShowErrorModal("Error", "Something went wrong");
  }

  this.incident.incidentid = params.get('id');
  this.incident.id = params.get('id');

  this.GetIncident(this.incident.incidentid);

}

GetIncident(id: string){
  this._iirService.GetIncidentDetails(id).subscribe(response => {
    if (!response || response.statusCode != 200 || !response.data){
      this._apiErrorService.ShowErrorModal("Error", "Something went wrong");
    }

    this._loadScriptService.LoadScript('jquerySliderScriptTag', 'jquerySlider.js');

    console.log(response.data);
    this.incident = response.data;
    this.incident.incidentDate = this._dateService.RetrieveDate(this.incident.incidentDate);
    if (!this._stringHelperService.IsNullOrWhiteSpace(response.data.incidentDescription)){
      try {
        this.incident.incidentDescription = JSON.parse(response.data.incidentDescription)
      }
      catch{
        this.incident.incidentDescription = response.data.incidentDescription
      }
    }

    if (this.incident.latitude != null && this.incident.longitude){
      this.lat = +this.incident.latitude;
      this.lng = +this.incident.longitude;
    }

    if (this.incident.bcuLocationData && this.incident.bcuLocationData.id) {
      this.InitializeMainLocations(this.incident.bcuLocationData);
      //this.GetAllLocationDetails(this.incident.bcuLocationData.id);
    }

    if (this.incident.section60) {
      this.InitilizeSection60Locations(this.incident.section60);
    } 

    if (this.incident.section35) {
      this.InitilizeSection35Locations(this.incident.section35);
    }

    this.SetIncidentAsViewed();

    this.incidentLoaded = true;

  }, 
  (error => {
    this.incident.incidentid = '0'
    this.incident = new IncidentDTO();

    this._apiErrorService.ShowError(error);
  }));
}


InitializeMainLocations(bcuLocation: LocationSectionDTO){
  if (!bcuLocation || !bcuLocation.locations || bcuLocation.locations.length <= 0){
    return;
  }

  for (const location of bcuLocation.locations) {
    let entity = this._iirService.InstanciateIncidentFormLocationEntity(location, location.locationPoints, true, true, this.lat, this.lng, this.BLUE_MARKER_URL);
    entity.locationDTO = location as LocationDTO;
    
    if (location.type == 0){
      if (this.PoliceCordonLocations.findIndex(x => x.id == location.id) == -1){
        this.PoliceCordonLocations.push(entity);
        this.PoliceCordonLocationsToShow.push(entity);
        this.MainLocationPolygon.push({ id: location.id, path: location.locationPoints, color: LocationColor.PoliceCordonLocation, name: location.name });
        this.MainLocationPolygonToShow.push({ id: location.id, path: location.locationPoints, color: LocationColor.PoliceCordonLocation, name: location.name });
      }
    }
    else if (location.type == 2){
      if (this.CrimeSceneLocations.findIndex(x => x.id == location.id) == -1){
        this.CrimeSceneLocations.push(entity);
        this.CrimeSceneLocationsToShow.push(entity);
        this.MainLocationPolygon.push({ id: location.id, path: location.locationPoints, color: LocationColor.CrimeSceneLocation, name: location.name });
        this.MainLocationPolygonToShow.push({ id: location.id, path: location.locationPoints, color: LocationColor.CrimeSceneLocation, name: location.name });
      }
    }
    else if (location.type == 1){
      if (this.MainLocationRVPMarker.findIndex(x => x.id == location.id) == -1){

        entity.icon = this.BLUE_MARKER_URL;
        entity.lat = location.locationPoints[0].lat;
        entity.lng = location.locationPoints[0].lng;
        this.RendezvousPointLocations.push(entity);
        this.RendezvousPointLocationsToShow.push(entity);
        this.MainLocationRVPMarker.push(
          { 
            id: location.id, 
            path: location.locationPoints, 
            color: LocationColor.RendezvousPointLocation, 
            name: location.name, 
            icon: this.BLUE_MARKER_URL,
            label: '',
            lat: location.locationPoints[0].lat,
            lng: location.locationPoints[0].lng
          }
        );
        this.MainLocationRVPMarkerToShow.push({ 
          id: location.id, 
          path: location.locationPoints, 
          color: LocationColor.RendezvousPointLocation, 
          name: location.name, 
          icon: this.BLUE_MARKER_URL,
          label: '',
          lat: location.locationPoints[0].lat,
          lng: location.locationPoints[0].lng
        });
      }
    }
  }
}


InitilizeSection60Locations(section60: Section60DTO){
  if (this._stringHelperService.IsNullOrWhiteSpace(section60.id)){
    return;
  }

  if (!section60.locality || section60.locality.length <= 0 || this.MainLocationRVPMarker.findIndex(x => x.id == section60.id) != -1){
    return;
  }

  if (section60.locality && section60.locality.length > 0
    ) {
      // patch location checkbox
      section60.localitySelect = true;
      section60.localityPosition = [];
      let position = [];
      position.push(section60.locality);
      //i itialize section 60 locations in section60 map
      section60.localityPosition = position;
    }

  let entity = 
    this._iirService.InstanciateIncidentFormLocationEntity(section60, section60.localityPosition[0], true, true, this.lat, this.lng, this.BLUE_MARKER_URL);
  
  this.Section60Locations.push(entity);
  this.Section60LocationsToShow.push(entity);
  this.MainLocationPolygon.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
  this.MainLocationPolygonToShow.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
}

InitilizeSection35Locations(section35: Section35DTO){
  if (this._stringHelperService.IsNullOrWhiteSpace(section35.id)){
    return;
  }

  if (!section35.locality || section35.locality.length <= 0 || this.MainLocationRVPMarker.findIndex(x => x.id == section35.id) != -1){
    return;
  }

  if (section35.locality && section35.locality.length > 0
    ) {
      // patch location checkbox
      section35.localitySelect = true;
      section35.localityPosition = [];
      let position = [];
      position.push(section35.locality);
      //i itialize section 35 locations in section35 map
      section35.localityPosition = position;
    }

  let entity = 
    this._iirService.InstanciateIncidentFormLocationEntity(section35, section35.localityPosition[0], true, true, this.lat, this.lng, this.BLUE_MARKER_URL);
  
  this.Section35Locations.push(entity);
  this.Section35LocationsToShow.push(entity);
  this.MainLocationPolygon.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
  this.MainLocationPolygonToShow.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
}

SetIncidentAsViewed(){
  this._incidentService.SetIncidentAsViewed(this.incident.id).subscribe(response => {
    
  }, (error => {
    this.incident.incidentid = '0'
    this.incident = new IncidentDTO();

    this._apiErrorService.ShowError(error);
  }));
}


//#region locations checkbox changes


ChkCrimeSceneChnaged(event: any, crimeScene: IncidentFormLocationEntity, index: number) {
  const ckecked = (event.target as HTMLInputElement).checked;

  const CrimeSceneLocationIndex = this.CrimeSceneLocations.findIndex(x => x.id === crimeScene.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === crimeScene.id);
  
  if (!ckecked) {
    const deletedCrimeScene = this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
    document.getElementById('crimeScene' + index).style.display = 'none';
  } else {
    let existingCrimescene = this.CrimeSceneLocations[CrimeSceneLocationIndex]
    this.CrimeSceneLocationsToShow[CrimeSceneLocationIndex] = existingCrimescene

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon;
    document.getElementById('crimeScene' + index).style.display = 'unset';
  }
}

ChkPoliceCordonChnaged(event: any, policeCordon: IncidentFormLocationEntity, index: number) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const PoliceCordonLocationIndex = this.PoliceCordonLocations.findIndex(x => x.id === policeCordon.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === policeCordon.id);
  if (!ckecked) {
    const deletedCrimeScene = this.PoliceCordonLocationsToShow.splice(PoliceCordonLocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
    document.getElementById('policeCordon' + index).style.display = 'none';
  } else {
    let existingCrimescene = this.PoliceCordonLocations[PoliceCordonLocationIndex]
    this.PoliceCordonLocationsToShow[PoliceCordonLocationIndex] = existingCrimescene
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon

    document.getElementById('policeCordon' + index).style.display = 'unset';
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

ChkRendezvousPointChnaged(event: any, RendezvousPoint: IncidentFormLocationEntity, index: number) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const RendezvousPointLocationIndex = this.RendezvousPointLocations.findIndex(x => x.id === RendezvousPoint.id);
  const MainLocationRVPMarkerIndex = this.MainLocationRVPMarker.findIndex(x => x.id === RendezvousPoint.id);
  if (!ckecked) {
    const deletedRendezvousPoint = this.RendezvousPointLocationsToShow.splice(RendezvousPointLocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationRVPMarkerToShow.splice(MainLocationRVPMarkerIndex, 1, null);
    document.getElementById('rendezvousPoint' + index).style.display = 'none';
  } else {
    let existingCrimescene = this.PoliceCordonLocations[RendezvousPointLocationIndex]
    this.RendezvousPointLocationsToShow[RendezvousPointLocationIndex] = existingCrimescene;
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationRVPMarker[MainLocationRVPMarkerIndex]
    this.MainLocationRVPMarkerToShow[MainLocationRVPMarkerIndex] = existingMainLocationPolygon;

    document.getElementById('rendezvousPoint' + index).style.display = 'unset';
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

ChkSection60Chnaged(event: any, Section60: IncidentFormLocationEntity) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const Section60LocationIndex = this.Section60Locations.findIndex(x => x.id === Section60.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === Section60.id);
  if (!ckecked) {
    const deletedSection60 = this.Section60LocationsToShow.splice(Section60LocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
  } else {
    let existingSection60 = this.Section60Locations[Section60LocationIndex]
    this.Section60LocationsToShow[Section60LocationIndex] = Section60
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

ChkSection35Chnaged(event: any, Section35: IncidentFormLocationEntity) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const Section35LocationIndex = this.Section35Locations.findIndex(x => x.id === Section35.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === Section35.id);
  if (!ckecked) {
    const deletedSection35 = this.Section35LocationsToShow.splice(Section35LocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
  } else {
    let existingSection35 = this.Section35Locations[Section35LocationIndex]
    this.Section35LocationsToShow[Section35LocationIndex] = Section35
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

//#endregion


LaunchCSTProtocolClicked(){
  this._router.navigate([`/cst-protocol-launch/${this.incident.id}`]);
}


ImmediateTagClicked(){
  this._router.navigate(['immediate-response', this.incident.id]);
}

CSTTagClicked(){
  this._router.navigate(['cst-protocol', this.incident.id]);
}


  // #region LoadScript
  
  loadJquerySlider(){
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/js/jquerySlider.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    script.id = 'jquerySliderScriptTag'
  }

  ngOnDestroy(): void {
    this._loadScriptService.RemoveScript('incident-DetailsScriptTag');
    this._loadScriptService.RemoveScript('jquerySliderScriptTag');
  }
  //#endregion
}
