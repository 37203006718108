export enum IncidentStatusEnum {
  Open=0,

  Active =1,

  ScaledDown =2,

  Archived =3,

  Declined = 4
}