import { IncidentStatusEnum } from '../Enum/IncidentStatus.enum';
import { ProtocolStatus } from '../Enum/ProtocolStatus.enum';
import { ResponseStatus } from '../Enum/ResponseStatus.enum';
import { MessageDeliveryChannelEnum } from './../Enum/MessageDeliveryChannelEnum.enum';
import { MessageGroupEnum } from './la-Team-messages/LATeamMessagingDashboard';
import { MessageTemplateDTO } from "./Messages/MessageTemplateDTO";

export class IncidentDTO {
    id: any;
    incidentid: string = '0';
    title: string;
    incidentDate: any;
    lastUpdatedAt: any;
    address: string;
    postcode: string;
    longitude: string;
    latitude: string;
    spotDetails: string;
    incidentDescription: string;
    status: any;
    group: any;
    bcuregionid: any;
    cadnumber: any
    peopledisplaced: boolean = false;
    offencegroup: any;
    subgroup: any;
    partnermessagesent: boolean = false;
    bcuLocationData: LocationSectionDTO;
    section35: Section35DTO;
    section60: Section60DTO;
    select:boolean=false;
    protocolStatus: number;
    responseStatus: number;
    partnerMessages: MessageTemplateDTO[];
}

export class IncidentStatus {
    id: Number;
    name: string;
}

export class LocationSectionDTO {
    id: string;
    locationid: string = '0';
    incidentId: string;
    locations: LocationDTO[];
    isSectionComplete: boolean;
}

export class LocationDTO {
    id?:any;
    type: any;
    name: string;
    LocationDTOItem: LocationDTOItem[] = []
    locationPoints: any;
    description: string;
    closed: boolean;
}
export class LocationDTOMarker {
    id?:any;
    type: any;
    name: string;
    markers:Markers[]=[]
}
export class LocationDTOItem {
    id: any;
    title: string;
    locationPoints: any;
    description: string;
    mapShow: boolean = false;
    selected: boolean = false
    reinitialMap: boolean = false;
    markers:Markers[]=[]
}

export class LocationInfo extends LocationDTOItem {
    name : string
  }

export class LocationType {
    id: Number;
    name: string;
}
export class Section35DTO {
    id: string;
    section35Id: string = '0'

    postcode: string;
    cadNumbers: string;
    start: any;
    starttime:any;
    sday: string = "";
    smonth: string = "";
    syear: string = "";

    end: any;
    endtime:any;
    eday: string = "";
    emonth: string = "";
    eyear: string = "";

    locality: any[] = [];
    localityPosition: any[] = [];
    localitySelect: boolean = false;
    localityMapShow: boolean = false;
    localityMapReinitial: boolean = false;

    grounds: string;
    completedById: string;
    authorisingOfficersId: string;
    approvedStatus: boolean;
    share:boolean;
    download:boolean;
    sectionComplete: boolean;
    incidentId: string;
    status:string;
    closed:boolean;
}
export class Section60DTO {
    id: string;
    section60Id: string = '0'
    postcode: string;
    cadNumber: string;
    start: any;
    starttime:any;
    sday: string = "";
    smonth: string = "";
    syear: string = "";

    end: any;
    endtime:any;
    eday: string = "";
    emonth: string = "";
    eyear: string = "";

    locality: any[] = [];
    localityPosition: any[] = [];
    localitySelect: boolean = false;
    localityMapShow: boolean = false;
    localityMapReinitial: boolean = false;

    grounds: string;
    tacticalGrounds: string;
    humanRights: string;
    completedById: string;
    authorisingOfficersId: string;
    approvedStatus: boolean;
    share:boolean;
    download:boolean;
    sectionComplete: boolean;
    incidentId: string;
    uploadedMap: string = "";
    uploadedMapSelect: boolean = false;
    status:string;
    closed:boolean;
}
export class CreateIncidentDTO {
    Title: string;
    Time: string;
    Day: string = "";
    Month: string = "";
    Year: string = "";
    InitialLocation: string = "";
    Postcode: string = "";
    LocalAuthority: string = "";
    CADNumber: string = "";
    IncidentDetails: any = "";
    OffenceGroup: string = ""
    SubGroup: string = ""
    PeopleDisplaced: boolean = false;
}
export class Accordion {
    StartIncidentTab: boolean = false;
    LocationsTab: boolean = false;
    Section60RequestTab: boolean = false;
    Section35RequestTab: boolean = false;
    PartnerMessageTab: boolean = false;
}
export class CommonTypeForSection {
    id: any;
    name: string;
    occupation: string;
}
export class CommonType {
    id: any;
    name: string
}
export class OrganizationGroup {
    id: any;
    name: string;
}
export class Markers {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
    visible: boolean;
    opacity: number;
    icon?: string;
    ispolygon?:boolean=false;
}

export class CreateMessageDTO{
    subject:string
    partnerMessage:string;
    selectAll:boolean=false;
}
export class MessageDTO {
    activeDateTime: string;
    from: string;
    to: string[];
    subject: string;
    message: string;
    relatedIncidentId: string;
    messageGroup: MessageGroupEnum;
    deliveryChannel: MessageDeliveryChannelEnum;
    groupName: string;
    logoUrl: string;
}

export class MessageQueueDTO extends MessageDTO {
    toDeliveryAddresses: string[];
    orgGroupsSelected: number[] = [];
}

export class PartnerMessageQueueDTO extends MessageQueueDTO{
    options: PartnerMessageOptions = new PartnerMessageOptions();
}

export class PartnerMessageOptions{
    sendToSLT: boolean;
    sendToTeamMembers: boolean;
    sendToPartners: boolean;
}

export class MessageDeliveryChannel {
    id: any;
    name: string
}
export class MessageGroup {
    name: string
}
export class Distribution{
    id:any;
    name:string;
    select:boolean=false;
}
export class MarkersForIncidentDetails {
    lat?: number;
    lng?: number;
    label?: string;
    visible: boolean;
    opacity: number;
    icon?: string;
    ispolygon?:boolean=false;
    path:any;
    color?:string;
}

export class SimpleIncidentDTO {
    id: string;
    title: string;
    location: string;
    date: Date;
    status: IncidentStatusEnum;
    protocolStatus: ResponseStatus;
    responseStatus: ResponseStatus;
    organisationGroupName: string;
}