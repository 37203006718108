<app-home-nav-menu></app-home-nav-menu>

<div class="container cst-protocol Team_protocol_action local-auth-3 pt-15 pb-50">
  <h2 class="sec-title">All Incidents</h2>

  <div class="back-to-dashboard">
    <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png" alt="left-angle-primary"> Back to dashboard</a>
  </div>
  
  <div class="w100" *ngIf="isIncidentsLoaded">
    <div class="accordion tableaccordian" id="contactaccordian">
      <div class="teamprotocol-heading">
        <div class="t_h2 main-dropdown-js">

          <h1>

            <span class="rel each-dropdown-js" rel="t_dropdown2">
              {{selectedincedenceStatus === 'All' ? ' All status’s' : (' ' + selectedincedenceStatus)}}
            </span> 
            Incidents

            <!-- <span class="rel each-dropdown-js" rel="t_dropdown2">
              {{selectedincedenceStatus === 'All' ? ' All status’s' : (' ' + selectedincedenceStatus)}}
            </span> 
            actions for
            <span class="rel each-dropdown-js" rel="t_dropdown">
              {{selectedTeamMember === 'All' ? ' all team members' : (' ' + selectedTeamMember)}}
            </span>
            for
            <span class="rel each-dropdown-js" rel="t_dropdown3">
              {{selectedincedence === 'All' ? ' all incidents' : (' ' + selectedincedence)}}
            </span> -->
          </h1>

          <!-- <ul class="t-dropdown each-dropdown-content-js" id="t_dropdown">
            <li *ngFor="let teamMember of teamMembers">
              <button type="button" class="link" 
                (click)="teamMemberClicked(teamMember)">{{teamMember}}</button>
            </li>
          </ul> -->

          <ul class="t-dropdown each-dropdown-content-js" id="t_dropdown2">
                <li *ngFor="let incedenceStatus of incedenceStatuses">
                  <button type="button" class="link"
                    (click)="incedenceStatusClicked(incedenceStatus)">{{incedenceStatus}}</button></li>
          </ul>

          <!-- <ul class="t-dropdown each-dropdown-content-js" id="t_dropdown3">
            <li *ngFor="let incedence of incedencesSet">
              <button type="button" class="link"
                (click)="incedenceClicked(incedence)">{{incedence}}</button></li>
          </ul> -->
          
        </div>
      </div>


      <div class="accordion-item">
        
        <div>
          <div class="accordion-bodyForEmergency position-relative">
            <div class="table-responsive local-action-table accord-table">
              <table class="table table-hover lsttd-gry">
                <thead>
                  <tr>
                    <th>Incident Name</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Local Authority</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let incident of incidentsDeepCopy; trackBy: trackByFn; index as i">
                    <td>{{incident.title}}</td>
                    <td>{{incident.date | date:'HH:mm EEE d MMMM'}}</td>
                    <td>{{incident.location}}</td>
                    <td>{{incident.organisationGroupName}}</td>
                    <td>{{GetIncidentStatusString(incident.status)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="divloader" *ngIf="!isIncidentsLoaded">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

