import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CstTemplateService } from './cst-template.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoadScriptService } from 'src/app/home-dashboard/load-script.service';
import { TaskGroupWithTasksDetails } from 'src/app/Models/cst-template/TaskGroupWithTasksDetails';
import { ApiErrorService } from 'src/app/core/services/api-error.service';

@Component({
  selector: 'app-cst-template',
  templateUrl: './cst-template.component.html',
  styleUrls: ['./cst-template.component.css']
})
export class CstTemplateComponent implements OnInit, OnDestroy {

  templateData: TaskGroupWithTasksDetails;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _cstTemplateService: CstTemplateService,
    private _loadScriptSerivice: LoadScriptService, 
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService
    ) { }

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    this._loadScriptSerivice.LoadDropdownScript("cst-templateScriptTag");
    
    this._cstTemplateService.getTemplateData().subscribe(data => {
      if (data.statusCode == 200){
        this.templateData = data.data;
        console.log(this.templateData);
      }
    }, 
    (error) => {
      this._apiErrorService.ShowError(error);
    });

    // this._cstTemplateService.GetCSTTemplates().subscribe(data => {
    //   if (data.statusCode == 200){
    //     console.log(data);
    //     //this.templateData = data.data;
    //     //console.log(this.templateData);
    //   }
    // }, (error) => {
    //   console.log("Error:" + JSON.stringify(error));
    //   this._toastr.error(error?.error?.message, "Error");
    // });
  }

  GetTimeFram(index: number): string{
    let result = "";
    switch (index) {
      case 1:
        result = "24 HR";
        break;
      case 2:
        result = "1 WEEK";
        break;
      case 3:
        result = "1 MONTH";
        break;
      case 4:
        result = "On Going";
        break;
      default:
        result = "";
    }
    return result;
  }

  ngOnDestroy(): void {
    this._loadScriptSerivice.RemoveScript("cst-templateScriptTag");
  }

}
