<app-home-nav-menu></app-home-nav-menu>

<div class="container cst-protocol Team_protocol_action local-auth-3 pt-15 pb-50">
  <h2 class="sec-title">Immediate Response Actions</h2>

  <div class="back-to-dashboard">
    <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png" alt="left-angle-primary"> Back to dashboard</a>
  </div>
  
  <div class="w100" *ngIf="isDataLoaded">
    <div class="accordion tableaccordian" id="contactaccordian">
      <div class="teamprotocol-heading">
        <div class="t_h2 main-dropdown-js">

          <h1>

            <span class="rel each-dropdown-js" rel="t_dropdown2">
              {{selectedincedenceStatus === 'All' ? ' All status’s' : (' ' + selectedincedenceStatus)}}
            </span> 
            actions for
            <span class="rel each-dropdown-js" rel="t_dropdown">
              {{selectedTeamMember === 'All' ? ' all team members' : (' ' + selectedTeamMember)}}
            </span>
            for
            <span class="rel each-dropdown-js" rel="t_dropdown3">
              {{selectedincedence === 'All' ? ' all incidents' : (' ' + selectedincedence)}}
            </span>
            <!-- Immediate response actions for 
            <span class="rel each-dropdown-js" rel="t_dropdown">
              {{selectedTeamMember === 'All' ? ' all team members' : (' ' + selectedTeamMember)}}
            </span>
            on 
            <span class="rel each-dropdown-js" rel="t_dropdown2">
              {{selectedincedenceStatus === 'All' ? ' all status’s' : (' ' + selectedincedenceStatus)}}
            </span> 
            for 
            <span class="rel each-dropdown-js" rel="t_dropdown3">
              {{selectedincedence === 'All' ? ' all incidents' : (' ' + selectedincedence)}}
            </span> -->
          </h1>

          <ul class="t-dropdown each-dropdown-content-js" id="t_dropdown">
            <li *ngFor="let teamMember of teamMembers">
              <button type="button" class="link" 
                (click)="teamMemberClicked(teamMember)">{{teamMember}}</button>
            </li>
          </ul>

          <ul class="t-dropdown each-dropdown-content-js" id="t_dropdown2">
            
                <li *ngFor="let incedenceStatus of incedenceStatuses">
                  <button type="button" class="link"
                    (click)="incedenceStatusClicked(incedenceStatus)">{{incedenceStatus}}</button></li>
          </ul>

          <ul class="t-dropdown each-dropdown-content-js" id="t_dropdown3">
            <li *ngFor="let incedence of incedences">
              <button type="button" class="link"
                (click)="incedenceClicked(incedence)">{{incedence}}</button></li>
          </ul>
          
        </div>
      </div>


      <div class="accordion-item" *ngFor="let incidentItem of incidentsDataDeepCopy;
      trackBy: trackByFn; index as i">
        <h2 class="accordion-header" [id]="'contactheading' + i">
          <button class="accordion-button bg_blue-63 with-status" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#contactaccord' + i" [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'contactaccord' + i">
            {{incidentItem.title}} <span>{{incidentItem.location}}</span>
            <a class="status-btn white-btn">{{getResponseStatusName(incidentItem.EmergencyTaskStatus)}}</a>
          </button>
        </h2>
        <div [id]="'contactaccord' + i" class="accordion-collapse collapse" [class]="i === 0 ? 'show' : ''" [attr.aria-labelledby]="'contactheading' + i"
          data-bs-parent="#contactaccordian">
          <div class="accordion-bodyForEmergency position-relative">


            <div *ngIf="incidentItem.responseStatus == 3">
            <span class="incidentpopup-overlay"></span>
              <div class="incident-popup">
                <div class="in">
                  <div class="left">
                    <h3>Acknowledge incident</h3>
                  </div>
                  <div class="right">
                    <a (click)="incidentpopupClicked('Reject', incidentItem)" class="inct-btn borderbtn">Reject</a>
                    <a (click)="incidentpopupClicked('Acknowledge', incidentItem)" class="inct-btn">Acknowledge</a>
                  </div>
                </div>
              </div>
            </div>


            <div class="table-responsive local-action-table accord-table" *ngIf="incidentItem.responseStatus !== 3">
              <table class="table table-hover lsttd-gry">
                <thead>
                  <tr>
                    <th>Person</th>
                    <th>Action</th>
                    <th>Notes</th>
                    <th>Issue</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody *ngIf="incidentItem.id">
                  <!-- <tr>
                    <td class="red-Acknowledge">
                      <img src="assets/images/profile-blank-icon.png" alt="user-profile" class="user-profile">
                    </td>
                    <td colspan="3" class="red-Acknowledge ">Acknowledge incident</td>

                    <td class="p-0"><button type="button" class="table-btn">Pending</button></td>
                  </tr> -->
                  <tr *ngFor="let action of incidentItem.actions; index as ii">
                    <td>
                      <div class="dropdown">

                        <div class="d-flex align-items-center justify-content-center" >
                          <mat-spinner [id]="i + 'update-assigned-member-spinner' + ii" style="display: none;" diameter="20"></mat-spinner>
                        </div>

                        <div [id]="i + 'assigned-member-div' + ii" style="display: block;">
                          <button class="btn dropdown-toggle" type="button" id="incidentdropdown" 
                          (click)="personClicked(incidentItem.organisationTeamForIncident, i, ii, action, incidentItem)"> 
                            <img [src]="(action.AssigneeAvatar != null && action.AssigneeAvatar != undefined) ? action.AssigneeAvatar : 'assets/images/profile-blank-icon.png'"
                              alt="user-profile" class="user-profile">
                            <span>{{action.AssigneeName}}</span>
                          </button>
                        </div>
                        <!-- <button class="btn dropdown-toggle" type="button" id="incidentdropdown" 
                        (click)="personClicked(incidentItem.organisationTeamForIncident, i, ii, action, incidentItem)"
                          data-bs-toggle="dropdown" aria-expanded="false"> <img [src]="(action.AssigneeAvatar != null && action.AssigneeAvatar != undefined) ? action.AssigneeAvatar : 'assets/images/profile-blank-icon.png'"
                            alt="user-profile" class="user-profile">
                          <span>{{action.AssigneeName}}</span>
                        </button> -->
                        <!-- <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
                          <li class="showHand" (click)="AssignTeamMember(organisationTeam, i, ii, action, incidentItem)"
                          *ngFor="let organisationTeam of incidentItem.organisationTeamForIncident">
                            <span>
                              {{organisationTeam.firstName + ' ' + organisationTeam.familyName}}
                            </span>
                          </li>
                        </ul> -->
                      </div>

                    </td>
                    <td>{{action.EmergencyTaskTitle}}</td>
                    <td class="msg-icon">
                      <img *ngIf="action.hasNotes" (click)="NotesClicked(action, incidentItem, i, ii)" src="../assets/images/Local_Authority_message_unread.png" alt="">
                      <img *ngIf="!action.hasNotes" (click)="NotesClicked(action, incidentItem, i, ii)" src="../assets/images/Local_Authority_message_grey_dot.png" alt="">
                    </td>
                    <td (click)="IssueClicked(action, incidentItem, i, ii)">
                      <span [ngClass]="action.HasIssue === false ? 'border-circle' : 'red-circle'"></span>
                    </td>
                    <td class="p-0" [id]="i + 'status-td' + ii" >
                      <!-- <div class="dropdown local-action-btn action-final r-btn"> -->

                        <div class="d-flex align-items-center justify-content-center" >
                          <mat-spinner [id]="i + 'update-status-spinner' + ii" style="display: none;" diameter="20"></mat-spinner>
                        </div>

                        <div class="dropdown local-action-btn_no_arrow action-final r-btn">
                      <button [id]="i + 'btnTaskStatus' + ii" type="button" style="display: block;"
                        [ngClass]="!action.AssigneeName ? 'notRequired' : getStatusData(action.EmergencyTaskStatus).classname"
                        class="table-btn"  data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {{!action.AssigneeName ? '' : getStatusData(action.EmergencyTaskStatus).statusName}}
                      </button>
                      <!-- <button [id]="i + 'btnTaskStatus' + ii" type="button"
                            [ngClass]="getStatusData(task.status).classname"
                            class="table-btn">
                            {{getStatusData(task.status).statusName}}</button> -->
                      <ul class="dropdown-menu"
                            [attr.aria-labelledby]="i + 'btnTaskStatus' + ii">

                            <!-- <li><button type="button" class="table-btn
                              ddmdarkGreen"
                              (click)="IndividualTaskStatusClicked(GetStatusNumber('Done'), i,
                              ii, action, incidentItem)">Done</button></li>
                          <li><button type="button" class="table-btn
                              ddmGreen"
                              (click)="IndividualTaskStatusClicked(GetStatusNumber('Ongoing'),
                              i, ii, action, incidentItem)">Ongoing</button></li>
                          <li><button type="button" class="table-btn
                              ddmorange"
                              (click)="IndividualTaskStatusClicked(GetStatusNumber('Active'),
                              i, ii, action, incidentItem)">Active</button></li>
                          <li><button type="button" class="table-btn
                              ddmGrey"
                              (click)="IndividualTaskStatusClicked(GetStatusNumber('Pending'),
                              i, ii, action, incidentItem)">Pending</button></li> -->


                            <li><button type="button" class="table-btn
                                ddmWhite"
                                (click)="IndividualTaskStatusClicked(GetStatusNumber('notRequired'), i,
                                ii, action, incidentItem)">Not Required</button>
                            </li>
                            <li><button type="button" class="table-btn
                              ddmGrey"
                              (click)="IndividualTaskStatusClicked(GetStatusNumber('Pending'),
                              i, ii, action, incidentItem)">Pending</button></li>
                              <li><button type="button" class="table-btn
                                ddmorange"
                                (click)="IndividualTaskStatusClicked(GetStatusNumber('Active'),
                                i, ii, action, incidentItem)">Active</button></li>
                            <li><button type="button" class="table-btn
                              ddmdarkGreen"
                                (click)="IndividualTaskStatusClicked(GetStatusNumber('Complete'),
                                i, ii, action, incidentItem)">Complete</button></li>
                            
                            
                          </ul>
                      <!-- <button type="button" class="table-btn">Pending</button> -->
                    </div>
                    </td>
                    
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="divloader" *ngIf="!isDataLoaded">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

