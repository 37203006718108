<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="d-flex justify-content-end"><img class="btnMapClose showHand" (click)="closeMapClicked()" src="../../../assets/images/close-black-icon.png"></div>
      
      <!-- <div class="modal-header">
        <button type="button" class="btn-close"></button>
      </div> -->
      <form #mapForm="ngForm" (ngSubmit)="onSubmit(mapForm.value)">
        <div class="modal-body d-flex align-items-center">
          <div class="body-contain">
            <div class="location-tab-input">
              <div class="form-label ">
                <label for="cordonone-check">{{HeadingText}}</label>
              </div>
              <div class="hs-input">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <input class="form-control" type="text" id="post_code"
                      name="Name" [(ngModel)]="mapData.Name" #Name="ngModel"
                      placeholder="" required (ngModelChange)="nameChange($event)">
                  </div>
                  <span class="text-danger"
                    *ngIf="(Name.touched || Name.submitted) &&
                    Name.errors?.required">
                    Name is required
                  </span>
                  <span class="text-danger"
                    *ngIf="isDuplicateName">
                    Name is already exist
                  </span>
                </div>
              </div>
              <div class="map-iframe" *ngIf="isMapReady">
                <div class="map-iframe" style="width: 500px;">

                </div>
                <agm-map [zoom]="InitialZoom" *ngIf="drawingType !== RendezvousPointLocation"
                  [latitude]="InitialLatitude" [longitude]="InitialLongitude"
                  (mapReady)="onMapReadyForCrimeSceneOnlyShowMap($event)"
                  [fullscreenControl]='true'
                  [mapTypeControl]='true'
                  >
                  <agm-marker
                    [iconUrl]="{url: '../../../assets/images/red-marker.png'}"
                    [latitude]="InitialLatitude" [longitude]="InitialLongitude"
                  ></agm-marker>
                  <agm-polygon
                    [paths]="pointList">
                  </agm-polygon>
                </agm-map>
                
                <agm-map *ngIf="drawingType === RendezvousPointLocation"  [latitude]="InitialLatitude" [longitude]="InitialLongitude"
                    [zoom]="InitialZoom" [zoomControl]="true"
                    (mapClick)="mapClicked($event)"
                    [fullscreenControl]='true' [mapTypeControl]='true'>
                    <agm-marker
                      [iconUrl]="{url: '../../../assets/images/red-marker.png'}"
                      [latitude]="InitialLatitude" [longitude]="InitialLongitude">
                    </agm-marker>
                    <agm-marker 
                      [latitude]="marker.lat" [longitude]="marker.lng"
                      [visible]="marker.visible"
                      [label]="marker.label" [markerDraggable]="marker.draggable"
                      (dragEnd)="markerDragEnd(marker, $event)"
                      [iconUrl]="{url: marker.icon}">
                    </agm-marker>
                </agm-map>
                <button type="button" class="btn btn-danger mt-1"
                              *ngIf="isEdit && drawingType !== 'RendezvousPoint'"
                              (click)="RemoveLocations(sub)"> Reset</button>

              </div>
              <div>
                <span class="text-danger"
                  *ngIf="isInvalidMap">
                  Map is invalid
                </span>
              </div>

              <div class="location-disc-input">
                <div class="form-label ">
                  <label for="initialLocation">Description</label>
                </div>
                <div class="hs-input">
                  <div class="row mb-0 mb-md-2">
                    <div class="col-12 col-md-9">
                      <textarea class="form-control" id="incidentDetails"
                        name="Description" [(ngModel)]="mapData.Description"
                        #Description="ngModel" placeholder=""
                        ></textarea>
                    </div>
                    <div class="col-12 col-md-3 text-end d-flex align-items-end
                      justify-content-end">
                      <div class="disc-btn">

                        <div class="d-flex align-items-center justify-content-center" >
                          <mat-spinner id="save-spinner" style="display: none;" diameter="20"></mat-spinner>
                        </div>

                        <button id="btn-save" style="display: block;" type="submit" class="primary-btn" [disabled]="mapForm.invalid">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>