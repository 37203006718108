export class LATeamMessagingDashboard {
  public incidentId: string;
  public title: string;
  public location: string;
  public dateTime: Date;
  public messages: LATeamMessage[];
}

export class LATeamMessage {
  public messageId: string;
  public type: string;
  public content: string;
  public recipientDescription: string;
  public subject: string;
  public from: string;
  public to: ToInformation[];
  public sent: Date;
}

export class ToInformation
{
    to: string
    avatar: string
}

// export class MessageQueueDTO {
//   public activeDateTime: Date;
//   public from: string;
//   public to: string[];
//   public subject: string;
//   public message: string;
//   public relatedIncidentId: string;
//   public groupName: string;
//   public logoUrl: string;
//   public toDeliveryAddresses: string[];
//   public messageGroup: MessageGroupEnum;
//   public deliveryChannel: MessageDeliveryChannelEnum;
// }

export enum MessageGroupEnum {
  Heads_Of_Service_Update = 0,
  Team = 1,
  Stakeholder = 2,
}

export enum MessageDeliveryChannelEnum {
  None = 0,

  Email = 1,

  SMS = 2,

  //[Display(Name = "WhatsApp")]
  //WhatsApp = 3,

  //[Display(Name = "Telegram")]
  //Telegram = 4,

  //[Display(Name = "Push Notification")]
  //PushNotification = 5
}
