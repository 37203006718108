import { BCURegionDTO } from "../BCURegionDTO.Model";

export class AuthenticatedUserForGrouping {
  firstName: string;
  familyName: string;
  id: string;
  region: BCURegionDTO;
  // region: UserRegions[] = [];

  // groups: {
  //   id: number;
  //   groupName: string;
  //   organizationId: number;
  //   organizationName: string;
  //   regionId: string;
  //   regionName: string;
  //   rigionLocationDescription: string;
  //   selected:boolean;
  // }[] = [];
}

export class UserRegions{
  regionId: string;
  regionName: string;
  regionDescription: string;
  selected:boolean;
  groups: UserGroups[] =[];
}

export class UserGroups{
  id: number;
  groupName: string;
  organizationId: number;
  organizationName: string;
}
