import { Injectable } from '@angular/core';
import { ApiService } from '../core/interceptors/api.service';
import { map } from 'rxjs/operators';
import { SimpleIncidentDTO } from '../Models/incident.modal';
import { IncidentStatusEnum } from '../Enum/IncidentStatus.enum';

@Injectable({
  providedIn: 'root'
})
export class IncidentsListService {

  constructor(private _apiService: ApiService) {}

  GetAllIncident(){
    const url = `/La/Incidents/Get`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  SortIncidentsBasedOnDateTime(incedents: SimpleIncidentDTO[]) {
    return incedents.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return bDate.getTime() - aDate.getTime();
    });
  }

  GetIncidentStatusString(status: IncidentStatusEnum){
    switch (status) {
      case IncidentStatusEnum.Active:
        return "Active";
        break;

      case IncidentStatusEnum.Declined:
        return "Declined";
        break;

      case IncidentStatusEnum.Archived:
        return "Archived";
        break;
        
      case IncidentStatusEnum.Open:
        return "Active";
        break;

      case IncidentStatusEnum.ScaledDown:
        return "Scaled Down";
        break;
    
      default:
        break;
    }
  }
}
