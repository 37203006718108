import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CognitoService } from '../Authentication/cognito.service';


/**
 * This is used to logout the user, when the server responds with an unathorized status code.
 * Especially when the session token expires.
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private _cognitoService: CognitoService
        ) { }


    /**
     * Intercepter intercepts the responses, and then process based on the recieved status code
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof ErrorInterceptor
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {

            if (error.status === 401) {
                // auto logout if 401 response returned from api
                (async () => await this._cognitoService.SignOut())();
            }

            // err.error is not null, if the ResponsenEntity contains an Exception
            // err.error.message will give the custom message send from the server
            return throwError(error);

        }));
    }
}