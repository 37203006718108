<div class="py-50 pt_sm_0 message-form">
  <div class="container">
    <div class="accordion tableaccordian" id="aprovemessage">
      <div class="accordion-item">
        <h2 class="accordion-header" id="aproveheading1">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aproveaccord1"
            aria-expanded="true" aria-controls="aproveaccord1">
            Incident message
          </button>
        </h2>
        <div id="aproveaccord1" class="accordion-collapse collapse show" aria-labelledby="aproveheading11"
          data-bs-parent="#aprovemessage">
          <div class="accordion-body">
            <div class="form-style">
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Email</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Sent</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Jan 21, 2022, 11:38:35 AM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>From</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="team-member-name">
                        <img src="./assets/images/rd-icon.png" alt="rd-icon">
                        <span>Peter Dickenson</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>(pen)</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>To</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-382">

                    <!-- <div class="row">
                      <div
                        class="map-checkbox-item col-lg-6 col-sm-12 col-md-6"
                        *ngFor="
                          let item of LocationMainmarkers;
                          let i = index
                        "
                      >
                        <label [for]="i">{{ item.label }}</label>
                        <input
                          type="checkbox"
                          (change)="item.visible = !item.visible"
                          class="form-control form-check-input"
                          [id]="i"
                          [checked]="item.visible"
                        />
                        <img [src]="item.icon" />
                      </div>
                    </div> -->

                    <!-- <div class="col-12 col-sm-6 mb-4 mb-sm-0"> -->
                    <div class="col-lg-6 col-sm-12 col-md-6 mb-4 mb-sm-0">
                      <div class="team-member-group">
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                          </div>

                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="team-member-group">
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Subject</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Attempted Murder/firearms discharge, <br> 51-89 Rochdale Rd, London E17 8JW, UK</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset border-bottom-0">
                <div class="form-label">
                  <label for="sendmail"><strong>Message</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-490">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                          euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                          minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis.</p>
                          <p>Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                            nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                            enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl
                            ut aliquip ex ea commodo consequat.</p>
                          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                            minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                            aliquip ex ea commodo consequat. Duis</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset hs-submit mb-3">
                <div class="form-label">
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 text-end">
                      <button type="button" class="primary-btn">Approve</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>