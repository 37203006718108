import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-assign-member-popup',
  templateUrl: './assign-member-popup.component.html',
  styleUrls: ['./assign-member-popup.component.css']
})
export class AssignMemberPopupComponent implements OnInit {

  members: {name: string, avatar: string}[] = [];

  membersLoaded = false;

  constructor(
    private _dialogRef: MatDialogRef<AssignMemberPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
  }

  ngOnInit(): void {
    const members = this._data.members as {name: string, avatar: string}[];
    if (members == null || members == undefined || members.length <= 0){
      this._dialogRef.close(null);
    }

    this.members.push(...members);
    this.membersLoaded = true;
  }

  memberClicked(selectedMember: {name: string, avatar: string}){
    this._dialogRef.close(selectedMember.name);
  }

  closeMapClicked() {
    this._dialogRef.close(null);
  }

}
