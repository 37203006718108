<div class="container">
  <div class="accordion tableaccordian incident-accord overlay-location"
    id="local-authority-overlay-location">
    <div class="accordion-item">
      <h2 class="accordion-header with-link" id="incidentheading1">
        <button class="accordion-button" type="button" data-bs-toggle="collapse"
          success=""
          data-bs-target="#incidentaccord1" aria-expanded="true"
          aria-controls="incidentaccord1">
          Locations
        </button>
        <a class="redirect-page" [routerLink]="['/la-dashboard']"></a>
      </h2>
      <div id="incidentaccord1" class="accordion-collapse collapse show"
        aria-labelledby="incidentheading1"
        data-bs-parent="#local-authority-overlay-location">
        <div class="accordion-body" *ngIf="!loadIncident">
          <div class="form-style">
            <div class="map-modal-style location-map show">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="body-contain">
                      <div class="form-label ">
                        <label for="mapcorderdon1"><strong>Fatal stabbing,</strong>
                          High Rd, E11 1HQ.</label>
                      </div>
                      <div class="map-iframe">
                        <agm-map [latitude]="lat" [longitude]="lng"
                          [zoom]="zoom" [zoomControl]="true"
                          [fullscreenControl]='true' [mapTypeControl]='true'>
                          <div *ngFor="let m of LocationMainmarkers; let i=
                            index">
                            <agm-marker *ngIf="m.visible && !m.ispolygon"
                              [iconUrl]="{
                              url: m.icon
                              }" [latitude]="m.lat" [longitude]="m.lng">
                            </agm-marker>
                            <agm-polygon [strokeColor]="m.color"
                              *ngIf="m.visible && m.ispolygon" [paths]="m.path">
                            </agm-polygon>
                          </div>
                        </agm-map>
                      </div>
                      <div class="row map-checkboxs">
                        <div class="col-12">
                          <div class="map-checkbox-items">
                            <div class="row">
                              <div class="map-checkbox-item col-lg-6 col-sm-12 col-md-6" 
                              *ngFor="let item of LocationMainmarkers;let i=index">
                                <label [for]="i">{{item.label}}</label>
                                <input type="checkbox"
                                  (change)="item.visible=!item.visible"
                                  class="form-control form-check-input" [id]="i"
                                  [checked]="item.visible">
                                <img [src]="item.icon">
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-sm-6">
                          <ul class="map-checkbox-items">
                            <li class="map-checkbox-item"  *ngFor="let item of LocationMainmarkersSecond;let i=index">
                              <label [for]="i">{{item.label}}</label>
                              <input type="checkbox" (change)="item.visible=!item.visible"
                                class="form-control form-check-input" [id]="i" [checked]="item.visible">
                              <img [src]="item.icon">
                            </li>
                          </ul>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="divloader" *ngIf="loadIncident">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
        
      </div>
    </div>

  </div>
</div>