<app-home-nav-menu [componentName]="'team-messaging-dashboard'"></app-home-nav-menu>



<div class="team-protocol-section pt-15 local-auth-9">
  <div class="container cst-protocol Team_protocol_action">

    <h2 class="sec-title">Team Messges</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>

    <div class="w-80" *ngIf="!isLoading">
      <div class="row">
        <div class="msg-indicat">
          <span class="Team-Messges-dot"> Team Messges</span>
          <span class="Partner-Messages-dot"> Partner Messages</span>
          <span class="IIR-Messages-dot"> IIR Messagess</span>
        </div>
      </div>
      <div class="accordion tableaccordian  " id="contactaccordian">


        <div class="accordion-item" *ngFor="let teamMessagingIncident of teamMessagesIncedents; index as i">
          <h2 class="accordion-header" [id]="'contactheading' + i">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#contactaccord' + i" [attr.aria-expanded]="i === 0 ? true : false" [attr.aria-controls]="'contactaccord' + i">
              {{teamMessagingIncident.title}} <span>{{teamMessagingIncident.location}}, {{ teamMessagingIncident.dateTime | date: 'HH:mm, MMMM d' }}</span>
              <div class="message-icon">
                <!-- <a href=""><img (click)="mailClicked()" src="assets/images/blue-mail-icon.png" alt=""> </a> -->
                <span (click)="SendMessageClicked('Mail', teamMessagingIncident.incidentId)">
                  <img src="assets/images/blue-mail-icon.png" alt=""> 
                </span>
                <span (click)="SendMessageClicked('SMS', teamMessagingIncident.incidentId)">
                  <img src="assets/images/Green-mail-icon.png" alt=""> 
                </span>
              </div>
            </button>
          </h2>
          <div [id]="'contactaccord' + i" class="accordion-collapse collapse" [class]="i === 0 ? 'show' : ''"
          [attr.aria-labelledby]="'contactheading' + i"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover team-messeging-dashboard-table">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <!-- <th>To</th> -->
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="noMessagesTr" *ngIf="teamMessagingIncident.messages.length === 0; else elseBlock">
                      <i>No messsages sent yet.</i>
                    </tr>
                    <ng-template #elseBlock>
                      <tr *ngFor="let message of teamMessagingIncident.messages" class="IIR-Messages showHand" (click)="IncidentMessageClicked(message)">
                        <td class="messageContent"><strong>{{message.type === "1" ? "Mail": "SMS"}}</strong> &nbsp; <span>{{message.content | sorterString}}</span></td>
                        <td>{{message.sent | date: 'HH:mm, MMMM d'}}</td>
                      </tr>
                    </ng-template>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="divloader" *ngIf="isLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>