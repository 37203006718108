export enum LocationType {
  CrimeSceneLocation = "CrimeSceneLocation",
  PoliceCordonLocation = "PoliceCordonLocation",
  RendezvousPointLocation = "RendezvousPointLocation",
  Section60Location = "Section60Location",
  Section35Location = "Section35Location"
}

export enum LocationColor{
  MainLocationPointLocation = "#ff0000",
  CrimeSceneLocation = "#b086ac",
  PoliceCordonLocation = "#9fc6e7",
  RendezvousPointLocation = "#9fc6e7",
  Section60Location = "#ff0000",
  Section35Location = "#ff0000"
}
