<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'show': isOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" (click)="onCancel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ message }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">Confirm</button>
      </div>
    </div>
  </div>
</div>