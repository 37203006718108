import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class TeamMembersService {

  constructor(
    private _apiService: ApiService
  ) { }

  GetTeamMembersDashboard() {
    const url = `/la/teammember/dashboard`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
