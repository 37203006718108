<div class="container cst-protocol">
    <div class="w-c-80">
      <div class="accordion tableaccordian" id="contactaccordian">
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactaccord1"
              aria-expanded="true" aria-controls="contactaccord1">
              Local Authority contacts
            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="blue-active">
                      <th colspan="4">Action</th>
                      <th>24hr</th>
                      <th>week 1</th>
                      <th>month 1</th>
                      <th>month 3</th>
                      <th>Action</th>
                    </tr>
                    <tr class="active">
                      <th colspan="9">Communications</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Share partner information (police, VRP etc)</td>

                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Refer to Daily Risk Management meetin
                        Mult-agency Safeguarding Hub.</td>

                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Refer to Gold group/ critical incident meeting</td>

                      <td><span class="orange-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Confirm on social media channels</td>

                      <td><span class="orange-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Monitor social media</td>

                      <td><span class="orange-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Liaise with Police Media team
                        & Issue joint updates </td>

                      <td><span class="grey-circle"></span></td>
                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Share support services and materials.</td>

                      <td><span class="grey-circle"></span></td>
                      <td><span class="orange-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Monitor community tension</td>

                      <td><span class="grey-circle"></span></td>
                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Promote support services</td>

                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="orange-circle"></span></td>
                      <td><span class="orange-circle"></span></td>

                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Milestones and anniversaries -
                        use SIM anniversary manager.</td>

                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="orange-circle"></span></td>
                      <td><span class="orange-circle"></span></td>


                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr class="active">
                      <th colspan="9">Safeguarding and support</th>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Gather safeguarding information</td>
                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Prepare referral for DRM meeting.</td>
                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Safeguarding and support.</td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Promote how to access resources
                        messaging on comms channels</td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Check capacity of support organisations</td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1">
                      </td>
                      <td colspan="3">Work with safeguarding practitioner to map
                        associates and those at risk</td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="green-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr class="active">
                      <th colspan="9">Hospital</th>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Gather safeguarding information</td>
                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> ?</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr class="active">
                      <th colspan="9">Community</th>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Gather safeguarding information</td>
                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr class="active">
                      <th colspan="9">Partnership work </th>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Gather safeguarding information</td>
                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr class="active">
                      <th colspan="9">Education settings</th>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Gather safeguarding information</td>
                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr class="active">
                      <th colspan="9">Deploy Resource</th>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Gather safeguarding information</td>
                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown1">
                            <li><a><img src="../../assets/images/decline.png" alt=""> Decline</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> Manage related
                                incidents</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> Scaled down</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr class="active">
                      <th colspan="9">Family</th>
                    </tr>

                    <tr>
                      <td colspan="1"> <img src="../../assets/images/user-profile.svg" alt="user-profile"
                          class="user-profile">
                        <span>Hydra Group</span>
                      </td>
                      <td colspan="3">Gather safeguarding information</td>
                      <td><span class="red-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td><span class="grey-circle"></span></td>
                      <td>
                        <div class="dropdown actions position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown01"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu" aria-labelledby="actiondropdown01">
                            <li><a><img src="../../assets/images/decline.png" alt=""> ?</a></li>
                            <li><a href="#"><img src="../../assets/images/manage-icon.png" alt=""> ?</a>
                            </li>
                            <li><a href="#"><img src="../../assets/images/scale-down.png" alt=""> ?</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>