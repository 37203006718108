import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-incident-message',
  templateUrl: './incident-message.component.html',
  styleUrls: ['./incident-message.component.css']
})
export class IncidentMessageComponent implements OnInit {

  constructor(
    public _dialogRef: MatDialogRef<IncidentMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public _messageInfo: any
    ) { }

  ngOnInit(): void {
    //console.log(this._messageInfo);
  }

}
