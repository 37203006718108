import { ProtocolStatus } from "src/app/Enum/ProtocolStatus.enum";
import { ResponseStatus } from "src/app/Enum/ResponseStatus.enum";
import { IncidentStatus } from "../incident.modal";
import { LAIncidentDashboardModel } from "../LAIncedenceDashboard/LAIncidentDashboardModel";
import { MemberProfile } from "../Members/MemberProfile";
import { LAEmergencyPlanningActionItem, LAEmergencyPlanningActions } from "./LAEmergencyPlanningActions";
import { Member } from "../Members/Member";

export class LAEmergencyIncident {
  datetime : Date;
  id: string;
  location: string;
  incidentStatus: IncidentStatus;
  protocolStatus: ProtocolStatus;
  responseStatus: ResponseStatus;
  title: string;

  public responseProtocolStatus: string;
  public actions : LAEmergencyPlanningActionItem[] = [];

  public organisationTeamForIncident : MemberProfile[] = [];
}

export class LAEmergencyIncidentForImmediate {
  datetime : Date;
  id: string;
  location: string;
  incidentStatus: IncidentStatus;
  protocolStatus: ProtocolStatus;
  responseStatus: ResponseStatus;
  title: string;

  public responseProtocolStatus: string;
  public actions : LAEmergencyPlanningActionItem[] = [];

  public organisationTeamForIncident : Member[] = [];
}