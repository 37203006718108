import { AddStakeholderComponent } from './../../Stakeholder/add-stakeholder/add-stakeholder.component';
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UrlHelperService } from 'src/app/core/services/url-helper.service';
import { AddTeamMemberComponent } from "src/app/team/add-team-member/add-team-member.component";

@Component({
  selector: "app-home-nav-menu",
  templateUrl: "./home-nav-menu.component.html",
  styleUrls: ["./home-nav-menu.component.css"],
})
export class HomeNavMenuComponent implements OnInit, OnChanges {

  homeDashboardLoaded = true;
  teamMessagingDashboardLoaded = true;
  teamMemberLoaded = true;

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _toastr: ToastrService,
    public _urlHelperService: UrlHelperService
  ) {}

  @Input('componentName') componentName = "";

  ngOnInit(): void {
    
  }

  teamMessagingClicked() {
    this._router.navigate(["/team-messaging-dashboard"]);
  }

  ngOnChanges(){
    if (this.componentName == ""){
      this.homeDashboardLoaded = true;
      this.teamMessagingDashboardLoaded = false;
      this.teamMemberLoaded = false;
    }
    else if (this.componentName == "team-messaging-dashboard"){
      this.teamMessagingDashboardLoaded = true;
      this.homeDashboardLoaded = false;
      this.teamMemberLoaded = false;
    }
    else if (this.componentName == "team-members"){
      this.teamMessagingDashboardLoaded = false;
      this.homeDashboardLoaded = false;
      this.teamMemberLoaded = true;
    }
  }


  AddTeamClicked(){
    this._dialog.open(AddTeamMemberComponent, {
      data: { memberProfile: null }
    });

  }

  AddStakeholderClicked(){
    this._dialog.open(AddStakeholderComponent, {
      data: { memberProfile: null }
    });
  }

}
