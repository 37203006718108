import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationModelService } from './confirmation-model.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(
    private _confirmationModelService: ConfirmationModelService
    ){}

  ngOnInit(): void {
    
  }

  @Input() message: string;
  @Input() isOpen: boolean;
 

  onCancel(){
    this._confirmationModelService.onCancelEvent.emit(false);
  }

  onConfirm(){
    this._confirmationModelService.onConfirmEvent.emit(true);
  }

}
