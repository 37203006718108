import { UserProfileService } from '../core/services/user-profile.service';
import { Marker, MouseEvent } from "@agm/core";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CommonType,
  IncidentDTO,
  Markers,
} from "../Models/incident.modal";
import { IncidentService } from '../initial-incident-form/incident.service'
import { ToastrService } from "ngx-toastr";
import { ApiErrorService } from "../core/services/api-error.service";
import { AuthenticatedUserForGrouping } from '../Models/Members/AuthenticatedUserGroups';
import { UrlHelperService } from '../core/services/url-helper.service';
import { DateService } from '../core/services/date.service';
import { GooglemapService } from '../core/services/googlemap.service';
import { IirService } from '../bcu/components/iir/iir.service';
import { StringHelperService } from '../core/services/string-helper.service';

@Component({
  selector: "app-la-new-incident",
  templateUrl: "./la-new-incident.component.html",
  styleUrls: ["./la-new-incident.component.css"],
})
export class LANewIncidentComponent implements OnInit {

  INVALID_DATE_ERRORMESSAGE = "Invalid Date";

  newIncidentForm: FormGroup;
  isFormLoaded = false;
  isSubmitted = false;
  markers: Markers[] = [];
  // CreateIncidentDTO: CreateIncidentDTO = new CreateIncidentDTO();

  Days: CommonType[] = [];
  Months: CommonType[] = [];
  Years: CommonType[] = [];
  dateNotValidErrorMessage : string = "";

  //#region map related fields
  lat: any;
  lng: any;
  zoom = 14;
  postCodeForSaving: string;
  //#endregion

  authenticatedUserForGrouping: AuthenticatedUserForGrouping;
  isBCURegionLoaded = false;

  @ViewChild("search")
  public searchElementRef: ElementRef<HTMLInputElement>;

  constructor(
    private _fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _stringHelperService: StringHelperService,
    private _iirService: IirService,
    private _router: Router,
    private _titleService: Title,
    private _incidentService: IncidentService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _userProfileService: UserProfileService,
    public _urlHelperService: UrlHelperService,
    private _dateService: DateService,
    private _googleMapService: GooglemapService
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    this.InitForm();
    this.InitilizeAuthenticatedUserGroups();
    this.InitializeDateTime();
    this.GetLocation();
    this.isFormLoaded = true;
  }

  // applyRequiredValidation() {
  //   this.searchElementRef.nativeElement.required = true;
  // }

  ngAfterViewInit() {

    let timerId = setInterval(() => {
      if (this.searchElementRef){

        this._googleMapService.AutocompleteInitialize(this.searchElementRef.nativeElement, (place) => {
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();

          this.markers[0].lat = this.lat;
          this.markers[0].lng = this.lng;
        }, 
        (addresses, place) => {
          if (addresses && addresses[0]) {
            const address = this._googleMapService.GetPostCodeAndFormattedAddress(addresses);
            
            this.newIncidentForm.patchValue({
              location: place.formatted_address,
            });

            this.postCodeForSaving = address.postCode;
          } else {
            console.log("Not found");
          }
        });
        clearInterval(timerId);
      }
    }, 1000);
    
  }

  InitForm() {
    this.newIncidentForm = this._fb.group({
      title: [null, Validators.required],
      time: [null, Validators.required],
      day: [null, Validators.required],
      month: [null, Validators.required],
      year: [null, Validators.required],
      location: [null, Validators.required],
      incidentDetail: [null, Validators.required],
      peopleDisplaced: [null],
      OffenceCategory: [null, Validators.required],
      method: [null, Validators.required],
      localAuthority: [null, Validators.required],
      //postCode: [null, Validators.required],
    });
  }

  InitilizeAuthenticatedUserGroups(){
    let timerId = setInterval(() => {
      const userWithRegion = this._userProfileService.GetAuthenticatedUserGroups();

      if (userWithRegion){
        this.authenticatedUserForGrouping = userWithRegion;

        if (this.authenticatedUserForGrouping && this.authenticatedUserForGrouping.region){
          const userGroupName = this.authenticatedUserForGrouping.region.groups.find(x => x.isAuthenticatedUserGroup)?.organizationGroupName;
          if (!this._stringHelperService.IsNullOrWhiteSpace(userGroupName)){
            this.newIncidentForm.patchValue({
              localAuthority: userGroupName,
            });
          }
          else{
            this.newIncidentForm.patchValue({
              localAuthority:
              this.authenticatedUserForGrouping.region.groups[0].organizationGroupName,
            });
          }
          
          this.isBCURegionLoaded = true;

          clearInterval(timerId);
        }
      }
    }, 100);
  }

  //#region getters
  get title() {
    return this.newIncidentForm.get("title") as FormControl;
  }

  get day() {
    return this.newIncidentForm.get("day") as FormControl;
  }

  get month() {
    return this.newIncidentForm.get("month") as FormControl;
  }

  get year() {
    return this.newIncidentForm.get("year") as FormControl;
  }

  get time() {
    return this.newIncidentForm.get("time") as FormControl;
  }

  get location() {
    return this.newIncidentForm.get("location") as FormControl;
  }

  get incidentDetail() {
    return this.newIncidentForm.get("incidentDetail") as FormControl;
  }

  get peopleDisplaced() {
    return this.newIncidentForm.get("peopleDisplaced") as FormControl;
  }

  get OffenceCategory() {
    return this.newIncidentForm.get("OffenceCategory") as FormControl;
  }

  get method() {
    return this.newIncidentForm.get("method") as FormControl;
  }

  get localAuthority() {
    return this.newIncidentForm.get("localAuthority") as FormControl;
  }
  //#endregion


  DateChangedHandler(){
    this.IsDateValid();
  }

  MonthChangedHandler(){
    if (this._stringHelperService.IsAnyNullOrWhiteSpace(this.month.value, this.year.value)){
      this.dateNotValidErrorMessage = this.INVALID_DATE_ERRORMESSAGE;
      return;
    }
    this.ReInitializeDays(+this.month.value, +this.year.value);
    this.IsDateValid();
  }

  YearChangedHandler(){
    if (this._stringHelperService.IsAnyNullOrWhiteSpace(this.month.value, this.year.value)){
      this.dateNotValidErrorMessage = this.INVALID_DATE_ERRORMESSAGE;
      return;
    }

    this.ReInitializeDays(+this.month.value, +this.year.value);
    this.IsDateValid();
  }

  ReInitializeDays(month: number, year: number){
    this.Days = this._dateService.GetDatesInMonth(month, year);
  }

  IsDateValid(){
    if (this._dateService.IsValidDate(+this.day.value, +this.month.value, +this.year.value)){
      this.dateNotValidErrorMessage = "";
    }
    else{
      this.dateNotValidErrorMessage = this.INVALID_DATE_ERRORMESSAGE;
    }
  }

  DayTrackBy(day) {
    return day.id;
  }


  InitializeDateTime() {
    const today = new Date();
    this.Days = this._dateService.GetDatesInMonth((today.getMonth() + 1), today.getFullYear());
    this.Months = this._dateService.GetMonths();
    this.Years = this._dateService.GetYears();

    const dateTime = this._dateService.GetDateTimeForIncident(today);
    this.newIncidentForm.patchValue({
      time: dateTime.time,
      day: dateTime.day,
      month: dateTime.month,
      year: dateTime.year,
    });
  }

  GetLocation() {
    this._googleMapService.GetCurrentLocation((position: any) => {
      if (!this.lat || !this.lng){
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        const marker = this._iirService.InstanciateMainRedMarker(this.lat, this.lng, true);
        this.markers[0] = marker;
      }
    }, (addresses: any) => {
      if (addresses && addresses[0]) {
        const address = this._googleMapService.GetPostCodeAndFormattedAddress(addresses);
       
        this.newIncidentForm.patchValue({
          location: address.formattedAddress,
        });

        this.postCodeForSaving = address.postCode;
      } else {
        console.log("Not found");
      }
    });
  }

  mapClicked($event: MouseEvent) {
    // this.lat = $event.coords.lat;
    // this.lng = $event.coords.lng;
    //this._googleMapService.MapClicked($event, (addresses) => {
      // if (results[0]) {
      //   this.currentLocation = results[0].formatted_address;
      //   console.log(this.currentLocation);
      // } else {
      //   console.log("Not found");
      // }
    //});
  }

  MarkerDragEnd(m: Marker, $event: MouseEvent) {
    this._googleMapService.MarkerDragEnd(m, $event, () => {
      this.lat = $event.coords.lat;
      this.lng = $event.coords.lng;
    }, 
    (addresses) => {
      const address = this._googleMapService.GetPostCodeAndFormattedAddress(addresses);

      this.newIncidentForm.patchValue({
        location: address.formattedAddress
      });

      this.postCodeForSaving = address.postCode;
    });
  }

  IsIncidentDateTimeNotValid(){
    return this._stringHelperService.IsAnyNullOrWhiteSpace(this.time.value, this.day.value, this.month.value, this.year.value)
      || !this._dateService.IsValidDate(+this.day.value, +this.month.value, +this.year.value)
  }


  onSubmit() {
    this.isSubmitted = true;
    if (this.newIncidentForm.invalid) {
      return;
    }

    if (this.IsIncidentDateTimeNotValid()){
      this.dateNotValidErrorMessage = this.INVALID_DATE_ERRORMESSAGE;
      return;
    }
    else{
      this.dateNotValidErrorMessage = "";
    }

    let incident = new IncidentDTO();

    incident.title = this.title.value;

    const incidentDate = this._dateService.CreateDateTimeInstance(this.time.value, this.day.value, this.month.value, this.year.value);
    incident.incidentDate = this._dateService.ConvertDateToSend(incidentDate);

    incident.incidentDescription = this.incidentDetail.value;
    incident.address = this.location.value;
    incident.postcode = this.postCodeForSaving;
    incident.status = 0;
    incident.latitude = this.lat.toString();
    incident.longitude = this.lng.toString();

    incident.bcuregionid = this.authenticatedUserForGrouping.region.id;;
    incident.spotDetails = this.localAuthority.value;

    const group = this.authenticatedUserForGrouping.region.groups.find(x => x.organizationGroupName === this.localAuthority.value);
    if (group){
      incident.group = group.organizationGroupId;
    }
    else{
      incident.group = this.authenticatedUserForGrouping.region.groups[0].organizationGroupId;
    }

    // incident.cadnumber = this.CADNumber.value;
    // incident.spotDetails = this.CreateIncidentDTO.LocalAuthority;
    incident.offencegroup = this.OffenceCategory.value;
    incident.subgroup = this.method.value;
    incident.peopledisplaced = this.peopleDisplaced.value ?? false;
    incident.id = undefined;

    debugger;

    this._incidentService.saveUpdateIncident(incident).subscribe(
      (response) => {
        if (response && response.data && response.statusCode == 200) {
          this._toastr.success(response.message, "Success");
          this._router.navigate(["home-dashboard"]);
        }
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      }
    );
  }

}
