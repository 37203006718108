
<div class="container">
  <h2>Change Password</h2>

  <form [formGroup]="changePasswordForm" (submit)="onSubmit()">
    <div class="form-group">
      <label for="oldPassword">Old Password</label>
      <input type="password" class="form-control" id="oldPassword" formControlName="oldPassword">

      <div *ngIf="(isSubmitted ||
      changePasswordForm.controls.oldPassword.touched)">
          <div class="text-danger"
            *ngIf="changePasswordForm.controls.oldPassword.hasError('required')">
            Old Password is required
          </div>
      </div>

    </div>
    <div class="form-group">
      <label for="newPassword">New Password</label>
      <input type="password" class="form-control" id="newPassword" formControlName="newPassword">

      <div *ngIf="(isSubmitted ||
      changePasswordForm.controls.newPassword.touched)">
          <div class="text-danger"
            *ngIf="changePasswordForm.controls.newPassword.hasError('required')">
            New Password is required
          </div>

          <div class="text-danger"
            *ngIf="changePasswordForm.controls.newPassword.hasError('minlength')">
            Password length atleast 8 charactor long
          </div>

          <div class="text-danger"
              *ngIf="changePasswordForm.controls.newPassword.hasError('containsSpaces')">
              Password must not contain leading or trailing spaces.
          </div>
      </div>

    </div>
    <div class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</div>

    <button type="submit" class="btn btn-color my-2" [disabled]="!changePasswordForm.valid">Change Password</button>
  </form>
</div>
    