import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { EmergencyNoteDTO } from 'src/app/Models/EmergencyPlanningModels/LAEmergencyPlanningActions';
import { IncidentNoteCreateDTO } from 'src/app/Models/Notes/IncidentNoteCreateDTO';

@Injectable({
  providedIn: 'root'
})
export class NotesOverlayService {

  constructor(private _apiService: ApiService) { }

  getMessageLog() {
    const url = "/messagequeue/logs";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AddEmergencyTaskNote(emergencyNoteDTO: EmergencyNoteDTO){
    const url = `/La/EmergencyPlanning/Notes/Add`;
    return this._apiService.post(url, emergencyNoteDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }

  AddCSTTaskNote(incidentNoteCreateDTO: IncidentNoteCreateDTO){
    const url = `/La/Notes/Add`;
    return this._apiService.post(url, incidentNoteCreateDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
